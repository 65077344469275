import {Paper, Step, StepLabel, Stepper} from "@mui/material";
import {DoneAll, Search, Start, SystemUpdate, SystemUpdateAlt} from "@mui/icons-material";


export function CraftingStepMainMenu() {

    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", height: "calc(100vh - 58px)"}}>

            {/*<div>Poste de <strong>vernissage</strong></div>*/}
            <Stepper activeStep={0} alternativeLabel style={{marginTop: 20}}>
                <Step>
                    <StepLabel>Choix de l'action</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Scan du bon de suivi</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Enregistrement de l'avancement</StepLabel>
                </Step>
            </Stepper>


            <Paper elevation={3} style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "row", margin: 20}}>
                <div style={{margin: 10, marginTop: 15, marginLeft: 15}}>
                    <SystemUpdateAlt/>
                </div>
                <div style={{display: "flex", flexDirection: "Column"}}>
                    <div style={{fontSize: 20, fontWeight: "bold", margin: 5, marginTop:15}}>Réceptionner des pièces</div>
                    <div style={{margin: 10}}>Touchez pour réceptionner de nouvelles pièces en <strong>polissage</strong></div>
                </div>
            </Paper>

            <Paper elevation={3} style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "row", margin: 20}}>
                <div style={{margin: 10, marginTop: 15, marginLeft: 15}}>
                    <DoneAll/>
                </div>
                <div style={{display: "flex", flexDirection: "Column"}}>
                    <div style={{fontSize: 20, fontWeight: "bold", margin: 5, marginTop:15}}>Livrer des pièces</div>
                    <div style={{margin: 10}}>Touchez pour notifier la fin du traitement de pièces</div>
                </div>
            </Paper>

            <Paper elevation={3} style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "row", margin: 20}}>
                <div style={{margin: 10, marginTop: 15, marginLeft: 15}}>
                    <Search/>
                </div>
                <div style={{display: "flex", flexDirection: "Column"}}>
                    <div style={{fontSize: 20, fontWeight: "bold", margin: 5, marginTop:15}}>Vérifier des articles</div>
                    <div style={{margin: 10}}>Touchez pour vérifier le statut de pièces à partir du bon de suivi</div>
                </div>
            </Paper>

        </div>
    )



}
