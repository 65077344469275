import {APIget, APIpost, APIdelete, APIpatch} from "../../../services/API";

export const getDevelopedRules = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/loi/developpe/get/all`)
            .then((rules) => {
                resolve(rules)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getThicknessRules = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/loi/epaisseur/get/all`)
            .then((rules) => {
                resolve(rules)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getLengthRules = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/loi/longueur/get/all`)
            .then((rules) => {
                resolve(rules)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteDevelopedRule = (developedRuleId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/loi/developpe/${developedRuleId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteThicknessRule = (developedRuleId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/loi/epaisseur/${developedRuleId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteLengthRule = (lengthRuleId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/loi/longueur/${lengthRuleId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createDevelopedRule = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/loi/developpe/create`, payload)
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createLengthRule = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/loi/longueur/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createThicknessRule = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/loi/epaisseur/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export const createDimension = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/dimension/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editDimension = (payload, dimensionId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/dimension/${dimensionId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editPointSeuil = (payload, pointSeuilId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/point/seuil/${pointSeuilId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editDevelopedRule = (payload, developedRuleId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/loi/developpe/${developedRuleId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editThicknessRule = (payload, thicknessRuleId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/loi/epaisseur/${thicknessRuleId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editLengthRule = (payload, lengthRuleId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/loi/longueur/${lengthRuleId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}



