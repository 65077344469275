import React, {useEffect, useState} from 'react'
import "../style/Home.scss"
import KPICard from "./KPICard";
import Notes from "./Notes"
import MonthlyTurnover from "./MonthlyTurnover";
import TurnoverChart from "./TurnoverChart";
import {getTurnoverOrderedByFinishGroup, getTurnoverInvoicedByFinishGroup, getMainCustomersByTurnover, getHomeStatistics} from "../api/HomeAPI";
import {numberWithSpaces} from "../../../shared/utils/Utils";

const Home = () => {

    const [kpisData, setKpisData] = useState(null)
    const [turnoverOrderedByFinishGroup, setTurnoverOrderedByFinishGroup] = useState(null)
    const [turnoverInvoicedByFinishGroup, setTurnoverInvoicedByFinishGroup] = useState(null)
    const [mainCustomersByTurnover, setMainCustomersByTurnover] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getChartsData()
    }, [])

    useEffect(async () => {
        handleGetKpisData()
    }, [])

    const getChartsData = async () => {
        await Promise.all([
            getTurnoverOrderedByFinishGroup().then((res) => setTurnoverOrderedByFinishGroup(res)),
            getTurnoverInvoicedByFinishGroup().then((res) => setTurnoverInvoicedByFinishGroup(res)),
            getMainCustomersByTurnover().then((res) => setMainCustomersByTurnover(res))
        ]);
        setIsLoading(false)
    }

    const handleGetKpisData = async () => {
        let kpis = []
        const data = await getHomeStatistics()
        setKpisData(data)
    }

    return (
        <div className={'home-wrapper'}>
            <div className={'home-wrapper-kpis'}>
                {
                    kpisData && kpisData.general.map((kpi) => {
                        if (kpi.statut === "commande" || kpi.statut === "devis" || kpi.statut === "livraison") {
                            const label = kpi.stattut === "commande" ? "Commandes en production" : kpi.statut === "devis" ? "Devis en attente" : kpi.statut === "livraison" ? "Commande en livraison" : ""
                            return (
                                <KPICard icon={kpi.statut === "commande" ? "build" : kpi.statut === "devis" ? "content_paste" : kpi.statut === "livraison" ? "local_shipping" : kpi.statut === "facture" ? "euro" : ""} title={label} count={kpi.cnt} subtitle={`soit ${numberWithSpaces(kpi.total)} €`}/>
                            )
                        }

                    })
                }
                <KPICard icon={"euro"} title={"Facturé depuis le début du mois"} count={kpisData && (kpisData.totalOfMonth[0].total || "0") + " €"} subtitle={""}/>
            </div>
            <div className={'home-wrapper-notes-turnover'}>
                <Notes/>
                <MonthlyTurnover/>
            </div>
            <div className={'home-wrapper-charts'}>
                <TurnoverChart title={"CA en commande par groupe de finitions"} stats={turnoverOrderedByFinishGroup} isLoading={isLoading}/>
                <TurnoverChart title={"CA facturé par groupe de finitions sur les 3 derniers mois"} stats={turnoverInvoicedByFinishGroup} isLoading={isLoading}/>
                <TurnoverChart title={"Répartition du CA sur les 3 derniers mois (10 principaux clients)"} stats={mainCustomersByTurnover} isLoading={isLoading}/>
            </div>
        </div>
    )
}

export default Home
