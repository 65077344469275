import React, {useState} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent, TextField, Box, Select, MenuItem} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {deleteProvider} from "../api/ProvidersAPI";
import toast from "react-hot-toast"

const ProvidersDeleteConfirmationModal = ({providerId, isOpen, handleClose, handleSetTriggerRefetch}) => {


    const handleDeleteProvider = async () => {
        try {
            await deleteProvider(providerId)
            handleClose()
            handleSetTriggerRefetch()
            toast.success("Le fournisseur a été supprimé avec succès")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la suppression du fournisseur")
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Confirmation de la suppression du fournisseur</DialogTitle>
            <DialogContent>
                <p>Souhaitez-vous vraiment supprimer ce fournisseur ?</p>
                <p>Cette modification est irréversible</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleDeleteProvider()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default ProvidersDeleteConfirmationModal