import React from "react"
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserProvider from "./contexts/UserContext"
import ConfModalProvider from "./contexts/ModalContext";
import {ThemeProvider, createTheme} from "@mui/material"
require('dotenv').config();

const theme = createTheme({
    palette: {
        primary: {
            main: "#1d6bfc",
        },
        secondary: {
            main: "#90b8cc",
        }
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <UserProvider>
                <ConfModalProvider>
                    <App/>
                </ConfModalProvider>
            </UserProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
