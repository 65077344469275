import React, {useState, useEffect, useContext} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Checkbox,
    DialogContent,
    FormControlLabel,
    TextField,
    FormControl,
    FormLabel,
    FormGroup,
    InputLabel,
    Box,
    ButtonGroup,
    ToggleButtonGroup,
    ToggleButton, Icon
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import toast from "react-hot-toast"
import "./Email.scss"
import {getContacts} from "./api/EmailAPI";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import {UserContext} from "../contexts/UserContext";
import {sendEmail} from "./api/EmailAPI";
import {attachPDF} from "./api/EmailAPI";
import EmailModalDocuments from "./EmailModalDocuments"
import {getOperationDetail} from "../modules/operations/api/OperationsAPI";
import Loader from "../shared/loader/Loader";

const EmailModal = ({handleClose, isOpen, operation, mode, operationsCommande, operationsQuotes, operationsInvoiced, operationsDelivery}) => {

    const [contactsList, setContactsList] = useState([])
    const [selectedContacts, setSelectedContacts] = useState([])
    const [additionalEmails, setAdditionalEmails] = useState("")
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")
    const [isSignatureIncluded, setIsSignatureIncluded] = useState(true)
    const [isProformaAttached, setIsProformaAttached] = useState(false)
    const [isQuoteAttached, setIsQuoteAttached] = useState(false)
    const [isDeliveryReceiptAttached, setIsDeliveryReceiptAttached] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [isSending, setIsSending] = useState(false)
    const [cc, setCC] = useState([])
    const [bcc, setBCC] = useState([])

    // used when user can choose between a bunch of documents to send (when the email is sent from a client page)
    const [selectedDocumentsType, setSelectedDocumentsType] = useState(operationsQuotes.length > 0 ? "quote" : operationsCommande.length > 0 ? "orders" : operationsDelivery.length > 0 ? "delivery" : operationsInvoiced.length > 0 ? "invoices" : null)
    const [selectedDocuments, setSelectedDocuments] = useState({
            quotes: [],
            orders: [],
            delivery: [],
            simpleInvoices: [],
            detailedInvoices: []
        }
    )

    const {jwt} = useContext(UserContext)

    useEffect(() => {
        handleGetContacts()
    }, [operation])

    useEffect(() => {
        if (selectedTemplate && mode !== "client") {
            handleManageSelectedTemplate()
        }
    }, [selectedTemplate])

    const handleManageSelectedTemplate = () => {
        if (selectedTemplate === "quote") {
            handleSetIsProformaAttached(false)
            handleSetIsDeliveryReceiptAttached(false)
            handleSetIsQuoteAttached(true)
            handleSetSubject(`Devis`)
            handleSetBody("Bonjour,\n" +
                "\n" + "Veuillez trouver ci-joint le devis correspondant à votre demande." +
                "\n" + "Je reste à votre disposition si vous avez des questions,\n" +
                "\n" + "Cordialement,\n" +
                "\n" + "Cyril"
            )
        }
        if (selectedTemplate === "proforma") {
            handleSetIsProformaAttached(true)
            handleSetIsDeliveryReceiptAttached(false)
            handleSetIsQuoteAttached(false)
            handleSetSubject(`Devis-commande référence ${operation.operation.numeroCommandeClient}`)
            handleSetBody("Bonjour,\n" +
                "\n" + `Veuillez trouver ci-joint la proforma correspondante à la commande référence ${operation.operation.numeroCommandeClient}.` +
                "\n" + "Nous attendons votre retour sur celui-ci afin de pouvoir lancer le traitement, \n" +
                "\n" + "Je reste à votre disposition si vous avez des questions,\n" +
                "\n" + "Cordialement,\n" +
                "\n" + "Cyril \n" +
                "\n" + "NOTE : Ce message est envoyé automatiquement suite à la prise en compte d’une commande sans devis préalable"
            )
        }
        if (selectedTemplate === "deliveryReceipt") {
            handleSetIsProformaAttached(false)
            handleSetIsDeliveryReceiptAttached(true)
            handleSetIsQuoteAttached(false)
            handleSetSubject(`Votre bon de livraison`)
            handleSetBody(
                "Bonjour,\n" +
                "\n" + `Veuillez trouver ci-joint le bon de livraison référence ${operation.operation.numeroCommandeClient} que vous nous avez demandé,` +
                "\n" + "Je reste à votre disposition si vous avez des questions,\n" +
                "\n" + "Cordialement,\n" +
                "\n" + "L'équipe Renovachrome \n"
            )
        }
    }


    const handleGetContacts = async () => {
        if (operation && mode !== "client") {
            const contacts = await getContacts(operation.operation.client_id)
            setContactsList(contacts)
        } else if (operation && mode === "client") {
            setContactsList(operation.contacts)
        }

    }

    const handleSetSelectedContacts = (value) => {
        setSelectedContacts(value)
    }

    const handleSetCC = (value) => {
        setCC(value)
    }

    const handleSetSubject = (value) => {
        setSubject(value)
    }

    const handleSetBody = (value) => {
        setBody(value)
    }

    const handleSetBCC = (value) => {
        setBCC(value)
    }

    const handleSetSelectedTemplate = (e) => {
        const value = e.target.value
        if (value === "quote") {
            setSelectedTemplate("quote")
        }
        if (value === "proforma") {
            setSelectedTemplate("proforma")
        }
        if (value === "deliveryReceipt") {
            setSelectedTemplate("deliveryReceipt")
        }
    }

    const handleSetIsQuoteAttached = (value) => {
        setIsQuoteAttached(value)
    }

    const handleSetAdditionalEmails = (value) => {
        setAdditionalEmails(value)
    }

    const handleSetIsProformaAttached = (value) => {
        setIsProformaAttached(value)
    }

    const handleSetIsSending = (value) => {
        setIsSending(value)
    }

    const handleSetIsDeliveryReceiptAttached = (value) => {
        setIsDeliveryReceiptAttached(value)
    }

    const groupArticles = (orderData) => {
        orderData.articlesToDisplay = [...orderData.articleCommandes, ...orderData.articleUniqueCommandes, ...orderData.ecritures, ...orderData.notes].sort((a, b) => a.ordre - b.ordre)
        return orderData
    }

    const handleSendEmail = async () => {
        handleSetIsSending(true)
        let proforma = null
        let quote = null
        let deliveryReceipt = null
        let simpleInvoice = null
        let detailedInvoice = null
        let additionalArrayEmails = []

        if (mode !== "client") {
            if (isQuoteAttached) {
                if (operation && operation.operation) {
                    const groupedOperationData = groupArticles(operation)
                    quote = await attachPDF(groupedOperationData, "quote")
                }
            }
            if (isDeliveryReceiptAttached) {
                if (operation && operation.operation) {
                    const groupedOperationData = groupArticles(operation)
                    deliveryReceipt = await attachPDF(groupedOperationData, "deliveryReceipt")
                }
            }
            if (isProformaAttached) {
                if (operation && operation.operation) {
                    const groupedOperationData = groupArticles(operation)
                    proforma = await attachPDF(groupedOperationData, "proforma")
                }
            }
        } else {
            if (selectedDocuments.quotes.length > 0) {
                const operationData = await getOperationDetail(selectedDocuments.quotes[0].id)
                const groupedOperationData = groupArticles(operationData)
                groupedOperationData.operation = groupedOperationData.operation[0]
                quote = await attachPDF(groupedOperationData, "quote")
            }
            if (selectedDocuments.orders.length > 0) {
                const operationData = await getOperationDetail(selectedDocuments.orders[0].id)
                const groupedOperationData = groupArticles(operationData)
                groupedOperationData.operation = groupedOperationData.operation[0]
                proforma = await attachPDF(groupedOperationData, "proforma")
            }
            if (selectedDocuments.delivery.length > 0) {
                const operationData = await getOperationDetail(selectedDocuments.delivery[0].id)
                const groupedOperationData = groupArticles(operationData)
                groupedOperationData.operation = groupedOperationData.operation[0]
                deliveryReceipt = await attachPDF(groupedOperationData, "deliveryReceipt")
            }
            if (selectedDocuments.simpleInvoices.length > 0) {
                simpleInvoice = await attachPDF(selectedDocuments.simpleInvoices[0], "basicBill")
            }
            if (selectedDocuments.detailedInvoices.length > 0) {
                selectedDocuments.detailedInvoices[0].operationsDetails = []
                for (let operation of selectedDocuments.detailedInvoices[0].operations) {
                    selectedDocuments.detailedInvoices[0].operationsDetails.push(groupArticles(await getOperationDetail(operation.id)))
                }
                for (let op of selectedDocuments.detailedInvoices[0].operationsDetails) {
                    op.operation = op.operation[0]
                }
                detailedInvoice = await attachPDF(selectedDocuments.detailedInvoices[0], "detailedBill")
            }
        }
        if (additionalEmails.length > 0) {
            additionalArrayEmails = additionalEmails.replace(" ", "").split(",")
            const emailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")
            for (let email of additionalArrayEmails) {
                if (!emailRegex.test(email)) {
                    toast.error("L'une des adresses mail saisie est invalide")
                    return
                }
            }
        }
        const payload = {
            body: body,
            subject: subject,
            contacts: [...selectedContacts, ...additionalArrayEmails],
            isDeliveryReceiptAttached: isDeliveryReceiptAttached,
            isSignatureIncluded: isSignatureIncluded,
            isQuoteAttached: isQuoteAttached,
            isProformaAttached: isProformaAttached,
            cc: cc,
            bcc: bcc,
        }
        try {
            await sendEmail(payload, jwt, proforma, quote, deliveryReceipt, simpleInvoice, detailedInvoice)
            handleSetIsSending(false)
            handleClose()
            toast.success("Votre email a été envoyé avec succès")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant l'envoi de votre email'")
            handleSetIsSending(false)
        }
    }

    const handleSetDocumentType = (e, value) => {
        setSelectedDocumentsType(value)
    }

    const handleSetSelectedDocuments = (type, document) => {
        let selectedDocumentsCopy = JSON.parse(JSON.stringify(selectedDocuments))
        if (type === "quotes") {
            if (selectedDocumentsCopy.quotes.find((doc) => doc.id === document.id)) {
                selectedDocumentsCopy.quotes = selectedDocumentsCopy.quotes.filter((doc) => doc.id !== document.id)
            } else {
                if (selectedDocumentsCopy.quotes.length > 0) {
                    toast.error("Vous ne pouvez joindre qu'un devis par envoi")
                    return
                } else {
                    selectedDocumentsCopy.quotes.push(document)
                }

            }
        }
        if (type === "orders") {
            if (selectedDocumentsCopy.orders.find((doc) => doc.id === document.id)) {
                selectedDocumentsCopy.orders = selectedDocumentsCopy.orders.filter((doc) => doc.id !== document.id)
            } else {
                if (selectedDocumentsCopy.orders.length > 0) {
                    toast.error("Vous ne pouvez joindre qu'une proforma par envoi")
                    return
                } else {
                    selectedDocumentsCopy.orders.push(document)
                }
            }
        }
        if (type === "delivery") {
            if (selectedDocumentsCopy.delivery.find((doc) => doc.id === document.id)) {
                selectedDocumentsCopy.delivery = selectedDocumentsCopy.delivery.filter((doc) => doc.id !== document.id)
            } else {
                if (selectedDocumentsCopy.delivery.length > 0) {
                    toast.error("Vous ne pouvez joindre qu'un bon de livraison par envoi")
                    return
                } else {
                    selectedDocumentsCopy.delivery.push(document)
                }
            }
        }
        if (type === "simpleInvoice") {
            if (selectedDocumentsCopy.simpleInvoices.find((doc) => doc.factureNumber === document.factureNumber)) {
                selectedDocumentsCopy.simpleInvoices = selectedDocumentsCopy.simpleInvoices.filter((doc) => doc.factureNumber !== document.factureNumber)
            } else {
                if (selectedDocumentsCopy.simpleInvoices.length > 0) {
                    toast.error("Vous ne pouvez joindre qu'une facture simple par envoi")
                    return
                } else {
                    selectedDocumentsCopy.simpleInvoices.push(document)
                }
            }
        }
        if (type === "detailedInvoice") {
            if (selectedDocumentsCopy.detailedInvoices.find((doc) => doc.factureNumber === document.factureNumber)) {
                selectedDocumentsCopy.detailedInvoices = selectedDocumentsCopy.detailedInvoices.filter((doc) => doc.factureNumber !== document.factureNumber)
            } else {
                if (selectedDocumentsCopy.detailedInvoices.length > 0) {
                    toast.error("Vous ne pouvez joindre qu'une facture détaillée par envoi")
                    return
                } else {
                    selectedDocumentsCopy.detailedInvoices.push(document)
                }
            }
        }
        setSelectedDocuments(selectedDocumentsCopy)
    }
    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"md"} fullWidth={true}>
            {
                mode !== "client" ?
                    <DialogTitle>{`Envoyer un mail au client ${operation.operation.client_nom} relatif à la commande ${operation.operation.numero}`}</DialogTitle>
                    :
                    <DialogTitle>{`Envoyer un mail au client ${operation.nom}`}</DialogTitle>
            }
            <DialogContent>

                <div className={"email-modal-wrapper"}>
                    <div style={{border: "solid 1px #CCCCCC", borderRadius: 4, padding: 5, marginBottom: "10px"}}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 16}}>
                            <div style={{fontSize: 16}}>Destinataire(s)</div>
                            <div style={{flex: 1}}/>
                        </div>
                        <FormControl className={"email-modal-wrapper-field"}>
                            <InputLabel size={"small"} id={'contact'}>Choisissez un ou plusieurs mail(s) parmi les contacts client</InputLabel>
                            <Select
                                size={"small"}
                                labelId="contact"
                                label="Choisissez un ou plusieurs mail(s) parmi les contacts client"
                                multiple
                                value={selectedContacts}
                                onChange={(e) => handleSetSelectedContacts(e.target.value)}
                                MenuProps={{
                                    style: {
                                        maxHeight: "200px",
                                    },
                                }}
                                renderValue={(selected) => (
                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                        {selected.map((value) => {
                                            return (
                                                <Chip key={value} label={contactsList && contactsList.find((contact) => contact.email === value).email}/>
                                            )
                                        })
                                        }
                                    </Box>
                                )}
                            >
                                {
                                    contactsList && contactsList.map((contact) => {
                                            return (
                                                <MenuItem key={contact.id} value={contact.email}>{contact.email}</MenuItem>
                                            )
                                        }
                                    )
                                }
                            </Select>
                        </ FormControl>
                        <TextField
                            size={"small"}
                            className={"email-modal-wrapper-field"}
                            value={additionalEmails}
                            onChange={(e) => handleSetAdditionalEmails(e.target.value)}
                            variant="outlined"
                            label={"Saisissez éventuellement un ou plusieurs mail(s) supplémentaire(s) séparé(s) par une virgule"}
                        />
                    </div>
                    <FormControl className={"email-modal-wrapper-field"}>
                        <InputLabel size={"small"} id={'contact'}>CC</InputLabel>
                        <Select
                            size={"small"}
                            labelId="contact"
                            label="CC"
                            multiple
                            value={cc}
                            onChange={(e) => handleSetCC(e.target.value)}
                            MenuProps={{
                                style: {
                                    maxHeight: "200px",
                                },
                            }}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {selected.map((value) => {
                                        return (
                                            <Chip key={value} label={contactsList && contactsList.find((contact) => contact.email === value).email}/>
                                        )
                                    })
                                    }
                                </Box>
                            )}
                        >
                            {
                                contactsList && contactsList.map((contact) => {
                                        return (
                                            <MenuItem key={contact.id} value={contact.email}>{contact.email}</MenuItem>
                                        )
                                    }
                                )
                            }
                        </Select>
                    </ FormControl>
                    <FormControl className={"email-modal-wrapper-field"}>
                        <InputLabel size={"small"} id={'contact'}>CCI</InputLabel>
                        <Select
                            size={"small"}
                            labelId="contact"
                            label="CCI"
                            multiple
                            value={bcc}
                            onChange={(e) => handleSetBCC(e.target.value)}
                            MenuProps={{
                                style: {
                                    maxHeight: "200px",
                                },
                            }}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {selected.map((value) => {
                                        return (
                                            <Chip key={value} label={contactsList && contactsList.find((contact) => contact.email === value).email}/>
                                        )
                                    })
                                    }
                                </Box>
                            )}
                        >
                            {
                                contactsList && contactsList.map((contact) => {
                                        return (
                                            <MenuItem key={contact.id} value={contact.email}>{contact.email}</MenuItem>
                                        )
                                    }
                                )
                            }
                        </Select>
                    </ FormControl>
                    <TextField
                        size={"small"}
                        className={"email-modal-wrapper-field"}
                        value={subject}
                        onChange={(e) => handleSetSubject(e.target.value)}
                        variant="outlined"
                        label={"Sujet"}
                    />
                    {
                        mode !== "client" &&
                        <FormControl component="fieldset" variant="standard" className={"email-modal-wrapper-field"}>
                            <FormLabel component="legend">Pièce(s) jointe(s)</FormLabel>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={isQuoteAttached} onChange={() => handleSetIsQuoteAttached(!isQuoteAttached)} name="quote"/>
                                    }
                                    label="Devis"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={isProformaAttached} onChange={() => handleSetIsProformaAttached(!isProformaAttached)} name="proforma"/>
                                    }
                                    label="Proforma"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={isDeliveryReceiptAttached} onChange={() => handleSetIsDeliveryReceiptAttached(!isDeliveryReceiptAttached)}
                                                  name="deliveryReceipt"/>
                                    }
                                    label="Bon de livraison"
                                />
                            </FormGroup>
                        </FormControl>
                    }
                    {
                        mode !== "client" &&
                        <div>
                            <FormLabel style={{marginBottom: "10px"}} component="legend">Utiliser un modèle</FormLabel>
                            <ToggleButtonGroup
                                size={"small"}
                                className={"email-modal-wrapper-field"}
                                color="primary"
                                value={selectedTemplate}
                                exclusive
                                onChange={handleSetSelectedTemplate}
                            >
                                <ToggleButton style={{width: "33.33%"}} value="quote">Devis</ToggleButton>
                                <ToggleButton style={{width: "33.33%"}} value="proforma">Proforma</ToggleButton>
                                <ToggleButton style={{width: "33.33%"}} value="deliveryReceipt">Bon de livraison</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    }
                    {
                        mode === "client" &&
                        <EmailModalDocuments type={selectedDocumentsType} setType={handleSetDocumentType} operationsCommande={operationsCommande}
                                             operationsQuotes={operationsQuotes} operationsDelivery={operationsDelivery} operationsInvoiced={operationsInvoiced}
                                             selectedDocuments={selectedDocuments} setSelectedDocuments={handleSetSelectedDocuments}
                        />

                    }
                    <TextField
                        multiline={true}
                        rows={15}
                        className={"email-modal-wrapper-field"}
                        value={body}
                        onChange={(e) => handleSetBody(e.target.value)}
                        variant="outlined"
                        label={"Message"}
                    />
                    {isSending &&
                        <>
                            <div style={{position:"absolute", top: 0, bottom:0, left:0, right:0, backgroundColor: "#FFFFFF", opacity:"0.7", zIndex:10}} />
                            <div className={"email-modal-wrapper-loader"}>
                                <Loader/>
                            </div>
                        </>
                    }
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    disabled={isSending}
                    onClick={() => handleSendEmail()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EmailModal