import React, {useState} from "react"
import {
    Pagination,
    Paper,
    Table,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import ProductsAddFinishModal from "./ProductsAddFinishModal";
import {duplicateMatiere} from "../api/ProductsAPI";
import toast from "react-hot-toast"
import {Link} from 'react-router-dom'

const ProductsAllFinishList = ({
                                   handleFilterInputValueChange,
                                   filterInputValue,
                                   data,
                                   triggerRefetch
                               }) => {

    const [pageNumber, setPageNumber] = useState(1)
    const [isCreateFinishModalOpen, setIsCreateFinishModalOpen] = useState(false)
    const [selectedFinishToDuplicate, setSelectedFinishToDuplicate] = useState(null)

    const handleCreateFinishModalOpening = (finish) => {
        if (finish) {
            setSelectedFinishToDuplicate(finish)
        }
        if (isCreateFinishModalOpen) {
            setIsCreateFinishModalOpen(false)
            setSelectedFinishToDuplicate(null)
        } else {
            setIsCreateFinishModalOpen(true)
        }
    }

    const handleDuplicateMatiere = async (matiereId) => {
        try {
            await duplicateMatiere(matiereId)
            toast.success("La finition a été dupliquée avec succès")
            triggerRefetch()
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la duplication de la finition")
        }
    }

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const categories = [
        {
            title: "Libellé",
            key: ""
        },
        {
            title: "Petit développé (ml)",
            key: ""
        },
        {
            title: "Grand développé (ml)",
            key: ""
        },
        {
            title: "Surface (m²)",
            key: ""
        },
    ]

    const startRange = (pageNumber - 1) * 5
    const endRange = pageNumber * 5

    return (
        <div className={"products-general-all-products-wrapper"}>

            <>
                <div className={"products-general-all-products-wrapper-header"}>
                    <div className={"products-general-all-products-wrapper-header-title"}>Liste des {data.length} {data.length > 1 ? "finitions" : "finitions"} présentes en
                        base
                    </div>
                    <div style={{flex: 1}}/>
                    <div className={"products-general-all-products-wrapper-header-filter-sort"}>

                        <Button variant={"contained"} style={{backgroundColor: "#1079FC", color: "#FFFFFF"}}
                                onClick={() => handleCreateFinishModalOpening()}
                        >Ajouter une finition</Button>
                        {/*TODO : implement the link to the page*/}
                        {/*<Button variant={"contained"} color={'primary'}>Gérer les groupes de finition</Button>*/}
                        <TextField className={"products-general-all-products-wrapper-header-filter-input"} size="small"
                                   label={"Rechercher"}
                                   variant="outlined" onChange={handleFilterInputValueChange}
                                   value={filterInputValue}/>
                    </div>

                </div>
                <div className={"products-general-all-products-wrapper-content"}>
                    <TableContainer style={{height: "502px"}} component={Paper} className={"products-general-all-products-wrapper-content-table"}>
                        <Table size={"small"} style={{border: "1px solid #EFEFEF"}}>
                            <TableHead>
                                <TableRow>
                                    {categories.map((category) => {
                                        return (
                                            <TableCell style={{backgroundColor: "#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.slice(startRange, endRange).map((finish) => (
                                    <TableRow
                                        key={finish.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>
                                            <div className={"products-general-all-products-wrapper-content-table-finish-buttons"}>
                                                <Link to={`/articles/finish/${finish.matiere_id}`}>
                                                    <span className='link-without-decoration'>{finish.matiere_libelle}</span>
                                                </Link>

                                                <Button size={'small'} variant={"outlined"} color={'primary'}
                                                        onClick={() => handleDuplicateMatiere(finish.matiere_id)}>Dupliquer</Button>
                                            </div>
                                        </TableCell>
                                        <TableCell>{finish.petit_developpe && finish.petit_developpe.length > 0 &&
                                        finish.petit_developpe.map((price, i) => {
                                            return (
                                                <div key={i}><strong>{price.tarif_libelle}</strong> : {parseFloat(price.tarif_prix).toFixed(2)} €</div>
                                            )
                                        })
                                        }</TableCell>
                                        <TableCell>{finish.grand_developpe && finish.grand_developpe.length > 0 &&
                                        finish.grand_developpe.map((price, i) => {
                                            return (
                                                <div key={i}><strong>{price.tarif_libelle}</strong> : {parseFloat(price.tarif_prix).toFixed(2)} €</div>
                                            )
                                        })
                                        }</TableCell>
                                        <TableCell>{finish.grand_developpe && finish.grand_developpe.length > 0 &&
                                        finish.grand_developpe.map((price, i) => {
                                            return (
                                                <div key={i}><strong>{price.tarif_libelle}</strong> : {parseFloat(price.tarif_prix).toFixed(2)} €</div>
                                            )
                                        })
                                        }</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={"products-general-all-products-wrapper-content-pagination"}>
                        <Pagination count={Math.ceil(data.length / 5)} onChange={handlePageChange} page={pageNumber}/>
                    </div>
                    {isCreateFinishModalOpen &&
                    <ProductsAddFinishModal finishToDuplicate={selectedFinishToDuplicate} isOpen={isCreateFinishModalOpen} handleClose={handleCreateFinishModalOpening}
                                            triggerRefetch={triggerRefetch}/>}
                </div>
            </>
        </div>
    )
}

export default ProductsAllFinishList
