import React, {useState, useEffect} from "react"
import {withRouter} from "react-router-dom";
import {deleteArticle, deleteFinish, getArticleDetails} from "../api/ProductsAPI";
import {Button, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import "./ArticleDetails.scss"
import {removeDuplicateItems} from "../../../shared/utils/Utils";
import toast from "react-hot-toast";
import ProductsAddArticleModal from "../generalCatalogue/ProductsAddArticleModal";
import ArticleLengthRules from "./ArticleLengthRule"
import ArticleThicknessRule from "./ArticleThicknessRule";
import {Line} from "react-chartjs-2";
import ArticleDevelopedRangesList from "./ArticleDevelopedRangesList";

const ArticleDetails = (props) => {

    const [articleDetails, setArticleDetails] = useState(null)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [triggerRefetch, setTriggerRefetch] = useState(false)

    useEffect(() => {
        handleGetArticleDetails()
    }, [triggerRefetch])

    // useEffect(() => {
    //     if (selectedPdv) {
    //         handleToggleModalOpen()
    //     }
    // }, [selectedPdv])

    const handleGetArticleDetails = async () => {
        const articleId = props.match.params.articleId
        const result = await getArticleDetails(articleId)
        matchFinishesAndDevelopedRanges(result)
    }

    const matchFinishesAndDevelopedRanges = (articleDetails) => {
        for (let finish of articleDetails.finitions) {
            finish.bornes = []
            for (let borne of articleDetails.plageDeveloppe) {
                for (let pdv of articleDetails.plageDeValidite) {
                    if (pdv.id === borne.plageDeValidite_id) {
                        borne.pdv_libelle = pdv.libelle
                        borne.matiere_id = pdv.matiere_id
                    }
                }
                if (borne.matiere_id === finish.matiere_id) {
                    finish.bornes.push(borne)
                }
            }

        }
        setArticleDetails(articleDetails)
    }

    const handleSetTriggerRefetch = () => {
        setTriggerRefetch(!triggerRefetch)
    }

    const handleToggleModalOpen = () => {
        setIsEditModalOpen(!isEditModalOpen)
    }

    const handleDeleteArticle = async () => {
        try{
            const articleId = props.match.params.articleId
            await deleteArticle(articleId)
            props.history.goBack()
            toast.success("L'article a été supprimé avec succès")
        } catch(err){
            console.log(err)
            toast.error(err?.response?.data?.catched_exception ? err?.response?.data?.catched_exception : "Erreur durant la suppression de l'article")
        }

    }


    return (
        articleDetails &&
        <div className={"article-details-wrapper"}>
            <div className={"article-details-wrapper-header"}>
                <div className={"article-details-wrapper-header"}>
                                <span>
                                   {articleDetails?.article[0]?.article_libelle}
                                </span>
                </div>
                <div className={"article-details-wrapper-header-buttons"}>
                    <Button variant={"contained"}
                            disabled
                            onClick={() => handleToggleModalOpen()}
                            style={{marginRight: "10px"}}
                    >
                        Modifier
                    </Button>
                    <Button variant={"outlined"}
                        onClick={() => handleDeleteArticle()}
                            style={{borderColor: "#EE3E3B", color: "#EE3E3B", marginLeft: "10px"}}
                    >
                        Supprimer
                    </Button>
                </div>
            </div>
            <div className={'article-rules-graphs-wrapper'}>
                <ArticleLengthRules data={articleDetails.pointSeuils.filter((ps) => ps.loiLongueur_id)} label={articleDetails.article[0].loi_longueur_libelle}/>
                <ArticleThicknessRule data={articleDetails.pointSeuils.filter((ps) => ps.loiEpaisseur_id)} label={articleDetails.article[0].loi_epaisseur_libelle}/>
                <Paper className={"article-rule-graph-wrapper"} style={{width: "calc(25% - 5px"}}>
                    <div className={"article-rule-graph-wrapper-header"}>
                        <div className={"article-rule-graph-wrapper-header-title"}>
                            Loi de développé : {articleDetails.article[0].loi_developpe_libelle}
                        </div>
                    </div>
                    <div className={"article-rule-graph-wrapper-graph-container"}>

                        <div className={"article-rule-graph-wrapper-graph-container-developed-rule"}>
                            <div>
                                Formule de calcul
                            </div>
                            <div>
                                {articleDetails.article[0].loi_developpe_calcul}
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
            <ArticleDevelopedRangesList data={articleDetails.finitions} articleLabel={articleDetails.article[0].article_libelle}/>
            {isEditModalOpen && <ProductsAddArticleModal article={articleDetails} handleClose={handleToggleModalOpen} triggerRefetch={handleSetTriggerRefetch}/>}
        </div>


    )
}

export default withRouter(ArticleDetails)
