import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Checkbox, DialogContent, FormControlLabel, TextField, Paper, IconButton, Icon} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import dayjs from "dayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import toast from "react-hot-toast"
import {Add} from "@mui/icons-material";
import {createBonDeCommande, editBC} from "../api/ProvidersAPI";

const ProvidersCreateEditBC = ({isOpen, providerId, handleClose, handleSetTriggerRefetch, selectedOrder}) => {

    const [designation, setDesignation] = useState(selectedOrder ? selectedOrder.designation : "")
    const [creationDate, setCreationDate] = useState(selectedOrder ? selectedOrder.dateEnAttente : null)
    const [externalRef, setExternalRef] = useState(selectedOrder ? selectedOrder.referenceExterne : "")
    const [renovaRef, setRenovaRef] = useState(selectedOrder ? selectedOrder.referenceRenova : "")
    const [customField, setCustomField] = useState(selectedOrder ? selectedOrder.customLibelleCustomField : null)
    const [ecritures, setEcritures] = useState(selectedOrder ?
        selectedOrder.entries :
        [
            {
                designation: "",
                prixHT: "",
                totalHT: "",
                quantite: 1,
                reference: "",
                customField: null,
            }
        ])

    const handleSetCreationDate = (value) => {
        setCreationDate(value)
    }

    const handleSetDesignation = (value) => {
        setDesignation(value)
    }

    const handleSetExternalRef = (value) => {
        setExternalRef(value)
    }

    const handleSetRenovaRef = (value) => {
        setRenovaRef(value)
    }

    const handleSetCustomField = (value) => {
        if(value.length === 0){
            const ecrituresCopy = ecritures.slice()
            for(let ecriture of ecritures){
                ecriture.customField = null
            }
        setEcritures(ecrituresCopy)
        }
        setCustomField(value)
    }

    const handleCreateBC = async () => {
        if (ecritures.length === 0) {
            toast.error("Merci de saisir au moins une écriture avant de valider")
            return
        }
        if (selectedOrder) {
            const payload = {
                customLibelleCustomField: customField && customField.length > 0 ? customField : null,
                entreesBonDeCommande: ecritures
            }
            try {
                await editBC(selectedOrder.id, payload)
                handleClose()
                handleSetTriggerRefetch()
                toast.success("Le bon de commande à été mis à jours avec succès")
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la mise à jour du bon de commande")
            }
        } else if (!selectedOrder) {
            const payload = {
                fournisseurId: providerId,
                designation: designation,
                dateEnAttente: dayjs(creationDate).format("YYYY-MM-DDT00:00:00+00:00"),
                referenceExterne: externalRef,
                referenceRenova: renovaRef,
                customField: customField,
                entreesBonDeCommande: ecritures
            }
            try {
                await createBonDeCommande(payload)
                handleClose()
                handleSetTriggerRefetch()
                toast.success("Le bon de commande a été créé avec succès")
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la création du bon de commande")
            }
        }
    }

    const handleEditEcritures = (value, index, type) => {
        const ecrituresCopy = ecritures.slice()
        ecrituresCopy[index][type] = value
        ecrituresCopy[index].totalHT = ecrituresCopy[index].prixHT * ecrituresCopy[index].quantite
        setEcritures(ecrituresCopy)
    }

    const handleAddEcriture = () => {
        const ecrituresCopy = ecritures.slice()
        ecrituresCopy.push({
                designation: "",
                prixHT: "",
                quantite: 1,
                reference: "",
                customField: null,
                totalHT: "",
            }
        )
        setEcritures(ecrituresCopy)
    }

    const handleFilterEcritures = (index) => {
        const ecrituresCopy = ecritures.slice()
        ecrituresCopy.splice(index, 1)
        setEcritures(ecrituresCopy)
    }


    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"xl"}>
            <DialogTitle>{selectedOrder ? "Modifier un bon de commande" : "Créer un bon de commande"}</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"} style={{width: "1200px"}}>
                    <TextField
                        disabled={selectedOrder}
                        style={{marginBottom: "12px"}}
                        value={designation}
                        onChange={(e) => handleSetDesignation(e.target.value)}
                        variant="outlined"
                        label={"Désignation"}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            disabled={selectedOrder}
                            label="Date d'enregistrement"
                            inputFormat="dd/MM/yyyy"
                            value={creationDate}
                            onChange={(newValue) => handleSetCreationDate(dayjs(newValue).format("YYYY-MM-DD"))}
                            renderInput={(params) => <TextField {...params} style={{marginBottom: "12px"}}/>}
                        />
                    </LocalizationProvider>
                    <TextField
                        disabled={selectedOrder}
                        style={{marginBottom: "12px"}}
                        value={externalRef}
                        onChange={(e) => handleSetExternalRef(e.target.value)}
                        variant="outlined"
                        label={"Référence externe"}
                    />
                    <TextField
                        disabled={selectedOrder}
                        style={{marginBottom: "12px"}}
                        value={renovaRef}
                        onChange={(e) => handleSetRenovaRef(e.target.value)}
                        variant="outlined"
                        label={"Référence Renova Chrome"}
                    />
                    <div style={{border: "solid 1px #CCCCCC", borderRadius: 4, padding: 10}}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 16}}>
                            <div style={{fontSize: 16}}>Ecritures</div>
                            <div style={{flex: 1}}/>
                            <TextField
                                size={"small"}
                                value={customField}
                                onChange={(e) => handleSetCustomField(e.target.value)}
                                variant="outlined"
                                label={"Champ personnalisé"}
                            />
                            <Button variant={"contained"} size="small" style={{marginLeft: 10}} onClick={() => handleAddEcriture()} startIcon={<Add/>}>
                                Ajouter une entrée
                            </Button>
                        </div>
                        {ecritures.map((ecriture, i) => {
                            return (
                                <div className={"ecriture-wrapper"}>
                                    <TextField
                                        style={{width: "20%"}}
                                        value={ecriture.reference}
                                        onChange={(e) => handleEditEcritures(e.target.value, i, "reference")}
                                        variant="outlined"
                                        label={"Référence"}
                                    />
                                    <TextField
                                        style={{width: "25%"}}
                                        value={ecriture.designation}
                                        onChange={(e) => handleEditEcritures(e.target.value, i, "designation")}
                                        variant="outlined"
                                        label={"Désignation"}
                                    />
                                    {
                                        customField &&
                                        <TextField
                                            style={{width: "25%"}}
                                            value={ecriture.customField}
                                            onChange={(e) => handleEditEcritures(e.target.value, i, "customField")}
                                            variant="outlined"
                                            label={customField}
                                        />
                                    }
                                    <TextField
                                        type={"number"}
                                        style={{width: "8%"}}
                                        value={ecriture.quantite}
                                        onChange={(e) => handleEditEcritures(e.target.value, i, "quantite")}
                                        variant="outlined"
                                        label={"Quantité"}
                                    />
                                    <TextField
                                        style={{width: "8%"}}
                                        value={ecriture.prixHT}
                                        onChange={(e) => handleEditEcritures(e.target.value, i, "prixHT")}
                                        variant="outlined"
                                        label={"PU HT"}
                                    />
                                    <TextField
                                        style={{width: "8%"}}
                                        value={ecriture.prixHT * ecriture.quantite + "€"}
                                        variant="outlined"
                                        label={"PU HT"}
                                    />
                                    <IconButton>
                                        <Icon color={"primary"} onClick={() => handleFilterEcritures(i)}>{"delete"}</Icon>
                                    </IconButton>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleCreateBC()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProvidersCreateEditBC