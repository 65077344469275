import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Checkbox, DialogContent, FormControlLabel, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Select from '@mui/material/Select';
import {getAllFinish, patchUniqueArticle} from "../api/ProductsAPI";
import MenuItem from '@mui/material/MenuItem';
import {removeDuplicateItems} from "../../../shared/utils/Utils";

const ProductsEditUniqueProductModal = ({article, handleClose, triggerRefetch, setTriggerRefetch}) => {

    const [libelle, setLibelle] = useState(article.article_unique_libelle)
    const [prixHT, setPrixHT] = useState(article.prixHT)
    const [isAvailable, setIsAvailable] = useState((!(article.disponiblePourTous === "0" || !article.disponiblePourTous)))
    const [finishId, setFinishId] = useState(article.matiere_id)
    const [finishList, setFinishList] = useState(null)

    useEffect(() => {
        handleGetAllFinish()
    }, [])

    const handleGetAllFinish = async () => {
        const allFinish = manageFinishData(await getAllFinish())
        setFinishList(allFinish)
    }

    const handleSetLibelle = (value) => {
        setLibelle(value)
    }

    const handleSetPrixHt = (value) => {
        setPrixHT(value)
    }

    const handleSetFinish = (value) => {
        setFinishId(value)
    }

    const handleToggleIsAvailable = () => {
        setIsAvailable(!isAvailable)
    }

    const manageFinishData = (data) => {
        const finalFinish = removeDuplicateItems(data, "matiere_id").map((finish) => {
            return (
                {
                    "matiere_libelle": finish.matiere_libelle,
                    "matiere_id": finish.matiere_id,
                    "petit_developpe": [],
                    "grand_developpe": [],
                    "surface": []
                }
            )
        })
        return finalFinish
    }

    const handleUpdateArticle = async () => {
        const payload = {
            libelle: libelle,
            prixHT: prixHT,
            disponiblePourTous: isAvailable,
            matiereId: parseInt(finishId)
        }
        try {
            await patchUniqueArticle(payload, article.id)
            setTriggerRefetch(!triggerRefetch)
            handleClose(null)
        } catch (err) {
            console.log(err)
        }

    }

    return (
        <Dialog open={article} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Edition d'un article</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={libelle}
                        onChange={(e) => handleSetLibelle(e.target.value)}
                        variant="outlined"
                        label={"Libellé"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        type={'number'}
                        variant="outlined"
                        onChange={(e) => handleSetPrixHt(e.target.value)}
                        label={"Tarif H.T."}
                        value={prixHT}
                    />
                    <Select
                        value={finishId}
                        label="Finition"
                        onChange={(e) => handleSetFinish(e.target.value)}
                    >
                        {
                            finishList && finishList.map((finish) => {
                                    return (
                                        <MenuItem value={finish.matiere_id}>{finish.matiere_libelle}</MenuItem>
                                    )
                                }
                            )
                        }
                    </Select>
                    <FormControlLabel control={<Checkbox onChange={() => handleToggleIsAvailable()} value={isAvailable}/>} label="Article disponible pour tous les clients ?"/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(null)}>Annuler</Button>
                <Button onClick={() => handleUpdateArticle()}>
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProductsEditUniqueProductModal