import React, {useState, useEffect, useContext} from "react"
import {withRouter} from "react-router-dom";
import {Button, Icon, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Alert} from "@mui/material";
import dayjs from "dayjs";
import {deleteOrder, getAllOrders} from "../api/ProvidersAPI";
import ProvidersCreateEditBC from "../providers/ProvidersCreateEditBC";
import ProvidersEditStatusModal from "../providers/ProvidersEditStatusModal";
import {downloadOperationPDF} from "../../operations/api/OperationsAPI";
import "./ProvidersOrdersList.scss"
import {filterData} from "../../../shared/utils/Utils";
import toast from "react-hot-toast";
import {getSalesSummary} from "../../stats/api/StatsAPI"
import {getSpreadsheetOrder} from "../api/ProvidersAPI";
import {UserContext} from "../../../contexts/UserContext";

const ProvidersOrdersList = () => {

    const [allOrders, setAllOrders] = useState(null)
    const [initialAllOrders, setInitialAllOrders] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [mode, setMode] = useState(null)
    const [isCreateBCModalOpen, setIsCreateBcModalOpen] = useState(false)
    const [isEditStatusModalOpen, setIsEditStatusModalOpen] = useState(false)
    const [filterInputValue, setFilterInputValue] = useState("")
    const [countsByType, setCountsByType] = useState(null)

    const {jwt} = useContext(UserContext)

    useEffect(() => {
        handleGetAllOrders()
    }, [])

    useEffect(() => {
        if (selectedOrder && mode) {
            if (mode === "edit") {
                handleToggleCreateBCModalOpen()
            } else if (mode === "editStatus") {
                handleToggleEditStatusModalOpen()
            }

        }
    }, [selectedOrder])

    useEffect(() => {
        if (initialAllOrders) {
            const filteredData = filterData(initialAllOrders, filterInputValue)
            setAllOrders(filteredData)
        }
    }, [filterInputValue])

    const handleToggleCreateBCModalOpen = () => {
        if (isCreateBCModalOpen) {
            setIsCreateBcModalOpen(false)
            setSelectedOrder(null)
            setMode(null)
        } else if (!isCreateBCModalOpen) {
            setIsCreateBcModalOpen(true)
        }
    }

    const handleToggleEditStatusModalOpen = () => {
        if (isEditStatusModalOpen) {
            setIsEditStatusModalOpen(false)
            setSelectedOrder(null)
            setMode(null)
        } else if (!isEditStatusModalOpen) {
            setIsEditStatusModalOpen(true)
        }
    }

    const handleFilterInputValueChange = (e) => {
        setFilterInputValue(e.target.value)
    }

    const handleGetAllOrders = async () => {
        const result = await getAllOrders()
        let counts = {
            payeCount: 0,
            factureCount: 0,
            receptionneCount: 0,
            enCoursCount: 0,
            enAttenteCount: 0,
        }
        for (let bc of result.bonDeCommandes) {
            if (bc.date_paye) {
                bc.statut = `Payé le ${dayjs(bc.date_paye).format("DD/MM/YYYY")}`
                counts.payeCount ++
            } else if (bc.date_facture) {
                bc.statut = `Facturé le ${dayjs(bc.date_facture).format("DD/MM/YYYY")}`
                counts.factureCount++
            } else if (bc.date_reception) {
                bc.statut = `Réceptionné le ${dayjs(bc.date_reception).format("DD/MM/YYYY")}`
                counts.receptionneCount++
            } else if (bc.date_en_cours) {
                bc.statut = `En cours le ${dayjs(bc.date_en_cours).format("DD/MM/YYYY")}`
                counts.enCoursCount++
            } else if (bc.date_en_attente) {
                bc.statut = `En attente le ${dayjs(bc.date_en_attente).format("DD/MM/YYYY")}`
                counts.enAttenteCount++
            }
            bc.entries = []
            for (let entry of result.entrees) {
                if (entry.bonDeCommande_id === bc.id) {
                    bc.entries.push(entry)
                }
            }
        }
        setCountsByType(counts)
        setInitialAllOrders(result.bonDeCommandes)
        setAllOrders(result.bonDeCommandes)
    }

    const categories = [
        {
            title: "Ref. Renova",
            key: "renovaRef"
        },
        {
            title: "Ref. fournisseur",
            key: "providerRef"
        },
        {
            title: "Fournisseur",
            key: "provider"
        },
        {
            title: "Statut",
            key: "status"
        },
        {
            title: "Dates",
            key: "dates"
        },
        {
            title: "Montant HT",
            key: "totalHT"
        },
        {
            title: "Actions",
            key: "actions"
        },
    ]

    const startRange = (pageNumber - 1) * 8
    const endRange = pageNumber * 8

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }
    const handleSelectOrder = (order) => {
        setSelectedOrder(order)
    }

    const handleClickOnPDF = async (order) => {
        downloadOperationPDF({order}, "providerOrder")
    }

    const handleClickOnSpreadsheet = async (order) => {
        try {
            const spreadsheet = await getSpreadsheetOrder(order.id, jwt)
            const data = await spreadsheet.arrayBuffer()
            const file = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"});
            // process to auto download it
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `Récap_commande_${order.renova_reference}.xlsx`;
            link.click();
        } catch (err) {
            console.log(err)
        }
    }

    const handleDeleteOrder = async (orderId) => {
        try{
            await deleteOrder(orderId)
            toast.success("Commande supprimée avec succès")
            handleGetAllOrders()
        }catch(err){
            console.log(err)
            toast.error("Erreur durant la suppression de la commande")
        }
    }

    return (
        allOrders &&
        <div className={"provider-all-orders-wrapper"}>
            <div className={"provider-all-orders-wrapper-header"}>
                <div className={"provider-all-orders-wrapper-header"}>
                                <span><strong style={{
                                    color: "#1079FC",
                                    borderRadius: 4,
                                    backgroundColor: "#D9EDF7",
                                    display: "inline-block",
                                    paddingLeft: 5,
                                    paddingRight: 5
                                }}>{initialAllOrders.length}</strong> {initialAllOrders.length > 1 ? "commandes répertoriées" : "commande répertoriée"}</span>
                </div>
                <div className={"provider-all-orders-wrapper-header-filter"}>
                    <Alert icon={<Icon>hourglass_bottom</Icon>} severity="error" variant={"outlined"} style={{marginRight:"10px", paddingTop:0, paddingBottom: 0}}>
                        En attente : {countsByType.enAttenteCount}
                    </Alert>
                    <Alert icon={<Icon>autorenew</Icon>} severity="warning" variant={"outlined"} style={{marginRight:"10px", paddingTop:0, paddingBottom: 0}}>
                        En cours : {countsByType.enCoursCount}
                    </Alert>
                    <Alert icon={<Icon>local_shipping</Icon>} severity="info" variant={"outlined"} style={{marginRight:"10px", paddingTop:0, paddingBottom: 0}}>
                        Réceptionnées : {countsByType.receptionneCount}
                    </Alert>
                    <Alert icon={<Icon>credit_score</Icon>} severity="success" variant={"outlined"} style={{marginRight:"10px", paddingTop:0, paddingBottom: 0}}>
                        Facturées : {countsByType.factureCount}
                    </Alert>
                    <TextField className={"provider-all-orders-wrapper-header-filter-input"} size="small"
                               label={"Rechercher un fournisseur"}
                               variant="outlined" onChange={handleFilterInputValueChange}
                               value={filterInputValue}/>
                </div>
            </div>
            <TableContainer component={Paper} className={"provider-all-orders-wrapper-content-table"}>
                <Table stickyHeader={true} style={{border: "1px solid #EFEFEF"}}>
                    <TableHead>
                        <TableRow>
                            {categories.map((category) => {
                                return (
                                    <TableCell style={{backgroundColor: "#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allOrders.slice(startRange, endRange).map((order) => (
                            <TableRow
                                key={order.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>
                                    <div
                                        className={'provider-all-orders-wrapper-content-table-operation-ref-wrapper'}>
                                        <div>
                                            {order.renova_reference}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>{order.externe_reference}</TableCell>
                                <TableCell>{order.nom}</TableCell>
                                <TableCell>{order.statut}</TableCell>
                                <TableCell>
                                    <div>
                                        {order.date_en_attente && `Création : ${dayjs(order.date_en_attente).format("DD/MM/YYYY")}`}
                                    </div>

                                    <div>
                                        {order.date_reception && `Réception : ${dayjs(order.date_reception).format("DD/MM/YYYY")}`}
                                    </div>
                                    <div>
                                        {order.date_facture && `Facturé : ${dayjs(order.date_facture).format("DD/MM/YYYY")}`}
                                    </div>

                                </TableCell>
                                <TableCell>{order.totalHT} €</TableCell>
                                <TableCell className={"provider-all-orders-wrapper-content-table-actions-buttons"}>
                                    <Tooltip title={'Editer la commande'}>
                                        <IconButton
                                            size={"small"}
                                            onClick={() => {
                                                setMode("edit")
                                                handleSelectOrder(order)
                                            }}
                                            style={{}}>
                                            <Icon color={"#444444"}>{"edit"}</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Editer le statut'}>
                                        <IconButton
                                            size={"small"}
                                            onClick={() => {
                                                setMode("editStatus")
                                                handleSelectOrder(order)
                                            }}
                                            style={{}}>
                                            <Icon color={"#444444"}>{"autorenew"}</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Export PDF'}>
                                        <IconButton
                                            size={"small"}
                                            onClick={() => handleClickOnPDF(order)}
                                            style={{}}>
                                            <Icon color={"#444444"}>{"picture_as_pdf"}</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Export Excel'}>
                                        <IconButton
                                            onClick={() => handleClickOnSpreadsheet(order)}
                                            size={"small"}
                                            style={{}}>
                                            <Icon color={"#444444"}>{"insert_drive_file"}</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Supprimer la commande'}>
                                        <IconButton
                                            size={"small"}
                                            onClick={() => handleDeleteOrder(order.id)}
                                            style={{}}>
                                            <Icon color={"#444444"}>{"delete"}</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={"provider-all-orders-wrapper-content-pagination"}>
                <Pagination count={Math.ceil(allOrders.length / 8)} onChange={handlePageChange} page={pageNumber}/>
            </div>
            {
                isCreateBCModalOpen &&
                <ProvidersCreateEditBC handleClose={handleToggleCreateBCModalOpen} isOpen={isCreateBCModalOpen} handleSetTriggerRefetch={handleGetAllOrders}
                                       selectedOrder={selectedOrder}/>
            }
            {
                isEditStatusModalOpen &&
                <ProvidersEditStatusModal handleClose={handleToggleEditStatusModalOpen} isOpen={isEditStatusModalOpen} handleSetTriggerRefetch={handleGetAllOrders}
                                          selectedOrder={selectedOrder}/>
            }
        </div>
    )

}

export default withRouter(ProvidersOrdersList)