import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent, TextField, Box, Select, MenuItem, InputLabel, FormControl} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createArticleUniqueCommande} from "../api/OperationsAPI";
import toast from "react-hot-toast";

const OperationDetailsAddUniqueArticle = ({operationId, ordre, isOpen, handleClose, uniqueArticlesCatalogue, triggerRefetch}) => {

    const [articlesCatalogue, setArticlesCatalogue] = useState(uniqueArticlesCatalogue)
    const [selectedArticle, setSelectedArticle] = useState(uniqueArticlesCatalogue && uniqueArticlesCatalogue[0])
    const [prixHT, setPrixHT] = useState(uniqueArticlesCatalogue && uniqueArticlesCatalogue[0].prixHT)
    const [libelle, setLibelle] = useState("")
    const [quantite, setQuantite] = useState(1)

    useEffect(() => {

    }, [])

    const handleSetLibelle = (value) => {
        setLibelle(value)
    }

    const handleSetPrixHT = (value) => {
        setPrixHT(value)
    }

    const handleSetQuantite = (value) => {
        setQuantite(value)
    }

    const handleSelectArticle = (e) => {
        const selectedArticle = articlesCatalogue.find((article) => article.id === e.target.value)
        setSelectedArticle(selectedArticle)
        setPrixHT(selectedArticle.prixHT)
        setLibelle(selectedArticle.libelle)
    }

    const handleAddUniqueArticle = async () => {
        const payload = {
            libelle: libelle,
            quantite: quantite,
            prixHT: prixHT,
            operationId: operationId,
            articleUniqueId: selectedArticle.id,
            ordre:ordre,
        }
        try {
            await createArticleUniqueCommande(payload)
            triggerRefetch()
            toast.success("L'article unique a été ajouté avec succès")
            handleClose(null)
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la création de l'article unique")
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Ajout d'un article unique</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <FormControl sx={{minWidth: 350}}>
                        <InputLabel>Choisssez un article unique</InputLabel>
                        <Select
                            style={{marginBottom: "12px"}}
                            label={'Articles uniques disponibles'}
                            className={""}
                            onChange={handleSelectArticle}
                        >
                            {articlesCatalogue && articlesCatalogue.map((article) => {
                                return (
                                    <MenuItem key={article.id} value={article.id}
                                    >{article.libelle}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={libelle}
                        onChange={(e) => handleSetLibelle(e.target.value)}
                        variant="outlined"
                        label={"Libellé"}
                    />
                    <div style={{display: "flex", marginBottom: "12px"}}>
                        <TextField
                            inputProps={{min: 0}}
                            type={'number'}
                            style={{width: "100%", marginRight: "5px"}}
                            value={quantite}
                            onChange={(e) => handleSetQuantite(e.target.value)}
                            variant="outlined"
                            label={"Quantité"}
                        />
                        <TextField
                            value={prixHT}
                            onChange={(e) => handleSetPrixHT(e.target.value)}
                            variant="outlined"
                            label={"Prix HT en €"}
                        />
                    </div>

                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleAddUniqueArticle ()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default OperationDetailsAddUniqueArticle