import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Checkbox, DialogContent, FormControlLabel, TextField, Paper, IconButton, Icon, Alert} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import dayjs from "dayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {editBCStatus} from "../api/ProvidersAPI";
import toast from "react-hot-toast"

const ProvidersEditStatusModal = ({isOpen, handleClose, handleSetTriggerRefetch, selectedOrder}) => {

    const [dateEnAttente, setDateEnAttente] = useState(null)
    const [dateEnCours, setDateEnCours] = useState(null)
    const [dateEnReceptionne, setDateEnReceptionne] = useState(null)
    const [dateFacture, setDateFacture] = useState(null)
    const [datePaye, setDatePaye] = useState(null)


    useEffect(() => {
        handleInitialDates()
    }, [selectedOrder])

    const handleSetDateEnAttente = (value) => {
        setDateEnAttente(value)
    }

    const handleSetDateEnCours = (value) => {
        setDateEnCours(value)
    }

    const handleSetDateEnReceptionne = (value) => {
        setDateEnReceptionne(value)
    }

    const handleSetDateFacture = (value) => {
        console.log(value)
        setDateFacture(value)
    }

    const handleSetDatePaye = (value) => {
        setDatePaye(value)
    }

    const handleInitialDates = () => {
        if (selectedOrder) {
            if (selectedOrder.dateEnAttente || selectedOrder.date_en_attente) {
                setDateEnAttente(selectedOrder.dateEnAttente || selectedOrder.date_en_attente)
            }
            if (selectedOrder.dateEnCour || selectedOrder.date_en_cours) {
                setDateEnCours(selectedOrder.dateEnCour || selectedOrder.date_en_cours)
            }
            if (selectedOrder.dateEnReceptionne || selectedOrder.date_reception) {
                setDateEnReceptionne(selectedOrder.dateEnReceptionne || selectedOrder.date_reception)
            }
            if (selectedOrder.dateFacture || selectedOrder.date_facture) {
                setDateFacture(selectedOrder.dateFacture || selectedOrder.date_facture)
            }
            if (selectedOrder.datePaye || selectedOrder.date_paye) {
                setDatePaye(selectedOrder.datePaye || selectedOrder.date_paye)
            }
        }
    }

    const handleCreateBC = async () => {
        const payload = {
            date_en_attente: dateEnAttente ? dayjs(dateEnAttente).format("YYYY-MM-DDT00:00:00+00:00") : null,
            date_en_cours: dateEnCours ? dayjs(dateEnCours).format("YYYY-MM-DDT00:00:00+00:00") : null,
            date_reception: dateEnReceptionne ? dayjs(dateEnReceptionne).format("YYYY-MM-DDT00:00:00+00:00") : null,
            date_facture: dateFacture ? dayjs(dateFacture).format("YYYY-MM-DDT00:00:00+00:00") : null,
            date_paye: datePaye ? dayjs(datePaye).format("YYYY-MM-DDT00:00:00+00:00") : null,
        }
        try {
            await editBCStatus(selectedOrder.id, payload)
            handleClose()
            handleSetTriggerRefetch()
            toast.success("Les dates ont été mises à jour avec succès")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la mise à jour des dates")
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"xl"}>
            <DialogTitle>Modifier le statut du bon de commande</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"} style={{width: "800px"}}>
                    <Alert severity="info" style={{marginBottom: 18}}>Statut actuel : {selectedOrder.statusCommande || selectedOrder.statut}</Alert>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date d'enregistrement"
                            inputFormat="dd/MM/yyyy"
                            value={dateEnAttente}
                            onChange={(newValue) => handleSetDateEnAttente(newValue ? dayjs(newValue).format("YYYY-MM-DD") : null)}
                            renderInput={(params) => <TextField {...params} style={{marginBottom: "12px"}}/>}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date de passage de la commande"
                            inputFormat="dd/MM/yyyy"
                            value={dateEnCours}
                            onChange={(newValue) => handleSetDateEnCours(newValue ? dayjs(newValue).format("YYYY-MM-DD") : null)}
                            renderInput={(params) => <TextField {...params} style={{marginBottom: "12px"}}/>}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date de réception"
                            inputFormat="dd/MM/yyyy"
                            value={dateEnReceptionne}
                            onChange={(newValue) => handleSetDateEnReceptionne(newValue ? dayjs(newValue).format("YYYY-MM-DD") : null)}
                            renderInput={(params) => <TextField {...params} style={{marginBottom: "12px"}}/>}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date de facturation"
                            inputFormat="dd/MM/yyyy"
                            value={dateFacture}
                            onChange={(newValue) => handleSetDateFacture(newValue ? dayjs(newValue).format("YYYY-MM-DD") : null)}
                            renderInput={(params) => <TextField {...params} style={{marginBottom: "12px"}}/>}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date de réglement"
                            inputFormat="dd/MM/yyyy"
                            value={datePaye}
                            onChange={(newValue) => handleSetDatePaye(newValue ? dayjs(newValue).format("YYYY-MM-DD") : null)}
                            renderInput={(params) => <TextField {...params} style={{marginBottom: "12px"}}/>}
                        />
                    </LocalizationProvider>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleCreateBC()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProvidersEditStatusModal