import React, {useEffect, useState} from "react"
import {Checkbox, FormControlLabel, Paper, TextField, Button, Dialog} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import {editOperation, createOperation} from "../api/OperationsAPI";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import dayjs from 'dayjs'
import {withRouter} from "react-router-dom"
import toast from "react-hot-toast"

const OperationDetailsEditModal = ({history, isOpen, handleClose, triggerRefresh, operation, clientId}) => {

    const [renovaRef, setRenovaRef] = useState(operation ? operation.id : "")
    const [clientRef, setClientRef] = useState(operation ? operation.numeroCommandeClient : "")
    const [TVA, setTVA] = useState(operation ? operation.tva === "1" : true)
    const [wasteTreatment, setWasteTreatment] = useState(operation ? operation.depollution === "1" : true)
    const [creationDate, setCreationDate] = useState(operation ? dayjs(operation.dateCreation) : dayjs())
    const [prevEndDate, setPrevEndDate] = useState(operation ? dayjs(operation.dateRendu) : null)

    useEffect(() => {

    }, [])

    const handleRenovaRef = (value) => {
        setRenovaRef(value)
    }

    const handleClientRef = (value) => {
        setClientRef(value)
    }

    const handleTVA = (value) => {
        setTVA(value)
    }

    const handleWasteTreatment = (value) => {
        setWasteTreatment(value)
    }

    const handleCreationDate = (value) => {
        setCreationDate(value)
    }

    const handlePrevEndDate = (value) => {
        setPrevEndDate(value)
    }

    const handleSendNewOperation = async () => {
        const payload = {
            dateRendu: dayjs(prevEndDate).format("YYYY-MM-DDT00:00:00+00:00"),
            depollution: wasteTreatment,
            tva: TVA,
            numero: renovaRef,
            numeroCommandeClient: clientRef,
            dateCreation: dayjs(creationDate).format("YYYY-MM-DDT00:00:00+00:00"),
        }
        if (operation) {
            try {
                await editOperation(payload, operation.id)
                triggerRefresh()
                handleClose()
                toast.success("L'opération a été modifiée avec succès")
            } catch (err) {
                toast.error("Erreur durant la modification de l'opération")
                console.log(err)
            }
        } else {
            payload.clientId = clientId
            try {
                const result = await createOperation(payload)
                history.push(`/operations/operation/detail/${result.id}`)
                toast.success("L'opération a été créée avec succès")
                handleClose()
            } catch (err) {
                toast.error("Erreur durant la création de l'opération")
                console.log(err)
            }
        }

    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <div className={'new-operation'}>
                <Paper elevation={8} className={'new-operation-wrapper'}>
                    <div className={'new-operation-header'}>
                        {operation ? "Modification d'une opération" : "Création d'une opération"}
                        <div className={'new-operation-header-line'}/>
                    </div>
                    <div className={'new-operation-fields-wrapper'}>
                        <TextField
                            value={renovaRef}
                            className={'new-operation-fields-wrapper-field'}
                            variant="outlined"
                            onChange={(e) => handleRenovaRef(e.target.value)}
                            label={"Référence Renova"}
                        />
                        <TextField
                            value={clientRef}
                            className={'new-operation-fields-wrapper-field'}
                            variant="outlined"
                            onChange={(e) => handleClientRef(e.target.value)}
                            label={"Référence commande client"}
                        />
                        <FormControlLabel control={<Checkbox onChange={() => handleTVA(!TVA)} checked={TVA}/>} label="La TVA s'applique t-elle ?"/>
                        <FormControlLabel control={<Checkbox onChange={() => handleWasteTreatment(!wasteTreatment)} checked={wasteTreatment}/>}
                                          label="La taxe de dépollution s'applique t-elle ?"/>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Date d'enregistrement de la commande"
                                inputFormat="dd/MM/yyyy"
                                value={creationDate}
                                onChange={(newValue) => handleCreationDate(dayjs(newValue).format("YYYY-MM-DD"))}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                label="Date de fin de production prévisionnelle"
                                inputFormat="dd/MM/yyyy"
                                value={prevEndDate}
                                onChange={(newValue) => handlePrevEndDate(dayjs(newValue).format("YYYY-MM-DD"))}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                    </div>

                    <div className={"new-operation-buttons"}>
                        <div style={{flex: 1}}/>
                        <Button variant="contained" onClick={() => handleSendNewOperation()}>{operation ? "Modifier" : "Créer"}</Button>
                    </div>

                </Paper>
            </div>
        </Dialog>

    )


}

export default withRouter(OperationDetailsEditModal)
