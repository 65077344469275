import React, {useState, useContext} from "react"
import "./Login.scss"
import logo_renova from "../../assets/logo_renova.png";
import {TextField, Button, ButtonBase} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {UserContext} from "./../../contexts/UserContext"
import {login} from "./api/LoginAPI"

const Login = () => {

    const [credentialsError, setCredentialsError] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [isPasswordDisplayed, setIsPasswordDisplayed] = useState(false)
    const {setNewUser, setIsLoadingUserContext} = useContext(UserContext)

    const handleUsernameChange = (userName) => {
        setUsername(userName)
        setCredentialsError(false)
    }

    const handlePasswordChange = (pw) => {
        setPassword(pw)
        setCredentialsError(false)
    }

    const togglePasswordDisplayed = () => {
        setIsPasswordDisplayed(!isPasswordDisplayed)
    }

    const handleSubmitCredentials = () => {
        login({username, password})
            .then((res) => {
                setNewUser(res.user, res.jwt)
            } )
            .catch((err) => {
                console.log(err)
                setCredentialsError(true)
            })
    }

    return (
        <div className='login-wrapper'>
            <div className={'login-container'}>
                <img src={logo_renova} alt="renova chrome logo"/>

            </div>
            <form className='login-text-fields-wrapper'>
                 <h2>
                    Connexion
                </h2>
                <span>
                    Entrez votre identifiant personnel et votre mot de passe pour continuer.
                </span>
                <TextField
                    className={'login-text-fields'}
                    id={"username-field"}
                    placeholder={"Identifiant"}
                    helperText={credentialsError ? "Identifiants invalides" : ""}
                    inputProps={{
                        style: {height: 15, backgroundColor: "#FAFAFA", borderRadius: 30, paddingLeft: 20},
                    }}
                    value={username}
                    onChange={(event) => {
                        handleUsernameChange(event.target.value)
                    }}
                    margin="normal"
                    required={true}
                    error={credentialsError}
                    variant={"outlined"}
                />
                <div className={'login-password-container'}>
                    <TextField
                        className={'login-text-fields'}
                        id={"password-field"}
                        placeholder={"Mot de passe"}
                        helperText={credentialsError ? "Identifiants invalides" : ""}
                        inputProps={{
                            style: {height: 15, backgroundColor: "#FAFAFA", borderRadius: 30, paddingLeft: 20},
                        }}
                        value={password}
                        onChange={(event) => {
                            handlePasswordChange(event.target.value)
                        }}
                        margin="normal"
                        type={isPasswordDisplayed ? "text" : "password"}
                        required={true}
                        error={credentialsError}
                        variant={"outlined"}
                    />
                    <div className={"login-password-eye"}>
                        <ButtonBase onClick={()=>{togglePasswordDisplayed()}}>
                            {isPasswordDisplayed ? <Visibility/> : <VisibilityOff/>}
                        </ButtonBase>
                    </div>
                </div>
                <Button color="secondary" variant="contained" onClick={() => handleSubmitCredentials()}>Connexion</Button>
            </form>
        </div>
    )
}

export default Login

