import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent,TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createFacture} from "../operations/api/OperationsAPI";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import dayjs from "dayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import toast from "react-hot-toast"

const ClientsInvoiceModal = ({isOpen, clientId, operationsIdsToInvoice, handleClose, handleSetTriggerRefetch}) => {


    const [creationDate, setCreationDate] = useState(null)
    const [invoiceNumber, setInvoiceNumber] = useState("")
    const [invoiceNote, setInvoiceNote] = useState("")


    useEffect(() => {

    }, [])

    const handleSetCreationDate = (value) => {
        setCreationDate(value)
    }

    const handleSetInvoiceNumber = (value) => {
        setInvoiceNumber(value)
    }

    const handleSetInvoiceNote = (value) => {
        setInvoiceNote(value)
    }

    const handleCreateInvoice = async () => {
        const payload = {
            operationIds: operationsIdsToInvoice,
            clientId: clientId,
            note: invoiceNote,
            date: dayjs(creationDate).format("YYYY-MM-DDT00:00:00+00:00"),
            numero: invoiceNumber,
            datePaiement: null
        }
        try {
            await createFacture(payload)
            handleClose()
            handleSetTriggerRefetch()
            toast.success("La facture a été réglée avec succès")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la création de la facture")
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Facturer</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={invoiceNumber}
                        onChange={(e) => handleSetInvoiceNumber(e.target.value)}
                        variant="outlined"
                        label={"Numéro de facture"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={invoiceNote}
                        onChange={(e) => handleSetInvoiceNote(e.target.value)}
                        variant="outlined"
                        label={"Note"}
                        helperText={"Si vous souhaitez ajouter des notes à cette facture, vous pouvez les saisir dans ce champ"}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date de la facture"
                            inputFormat="dd/MM/yyyy"
                            value={creationDate}
                            onChange={(newValue) => handleSetCreationDate(dayjs(newValue).format("YYYY-MM-DD"))}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleCreateInvoice()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ClientsInvoiceModal