import React from "react"
import {Icon, Paper, TextField, Button, IconButton} from '@mui/material'
import Autocomplete from "@mui/material/Autocomplete";
import {Add, Archive, Cached, Euro, LocalShipping, Mail, PendingActions, Receipt} from "@mui/icons-material";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';

const ClientsMenu = ({
                         handleSetSelectedClient,
                         operationsCount,
                         selectedClient,
                         allClients,
                         handleSetTabNumber,
                         tabNumber,
                         handleOpenEditCreateClientModal,
                         handleOpenCloseNewOperationModal
                     }) => {
    return (
        <Paper className={"clients-menu noscrollbar"}>
            <div className={'clients-menu-autocomplete'}>
                <Autocomplete
                    size={"small"}
                    onChange={(event, newValue) => {
                        handleSetSelectedClient(newValue);
                    }}
                    disablePortal
                    value={selectedClient}
                    options={allClients && allClients}
                    sx={{width: 300}}
                    renderInput={(params) => <TextField {...params} label="Sélectionnez un client"/>}
                    renderOption={(props, option) => (
                        <div {...props} className={'clients-menu-autocomplete-fields-wrapper'}>
                            <div><strong>{option.nom}</strong></div>
                            <div>{option.adresse}</div>
                            <div>{option.code_postal} {option.ville}</div>
                            {option.telephone && <div>&#128241; {option.telephone}</div>}
                            {option.email && <div>&#128231; {option.email}</div>}
                        </div>
                    )}
                />
            </div>
            <div style={{
                background: selectedClient && tabNumber === 0 && "rgb(211, 211, 211)",
                cursor: selectedClient && "pointer",
                color: !selectedClient && '#CCCCCC'
            }}
                 className={'clients-menu-category-wrapper-button'}
                 onClick={() => selectedClient && handleSetTabNumber(0)}>
                <Icon className={'clients-menu-category-wrapper-button-icon'}>
                    info
                </Icon>
                <span>
                             Fiche client
                        </span>
                <div style={{flex: 1}}/>
                <Icon>keyboard_arrow_right_icon</Icon>
            </div>
            <div style={{
                background: selectedClient && tabNumber === 1 && "rgb(211, 211, 211)",
                cursor: selectedClient && "pointer",
                color: !selectedClient && '#CCCCCC'
            }}
                 className={'clients-menu-category-wrapper-button'}
                 onClick={() => selectedClient && handleSetTabNumber(1)}>

                <div style={{paddingRight: 20}}>
                    <Badge badgeContent={(operationsCount && selectedClient) ? operationsCount[0] : 0} color="primary" showZero>
                        <PendingActions color="primary" />
                    </Badge>
                </div>
                <span>
                    Devis en attente
                </span>
                <div style={{flex: 1}}/>
                <Icon>keyboard_arrow_right_icon</Icon>
            </div>
            <div style={{
                background: selectedClient && tabNumber === 2 && "rgb(211, 211, 211)",
                cursor: selectedClient && "pointer",
                color: !selectedClient && '#CCCCCC'
            }}
                 className={'clients-menu-category-wrapper-button'}
                 onClick={() => selectedClient && handleSetTabNumber(2)}>
                <div style={{paddingRight: 20}}>
                    <Badge badgeContent={(operationsCount && selectedClient) ? operationsCount[1] : 0} color="primary" showZero>
                        <Cached color="primary" />
                    </Badge>
                </div>
                <span>
                    En cours
                </span>
                <div style={{flex: 1}}/>
                <Icon>keyboard_arrow_right_icon</Icon>
            </div>
            <div style={{
                background: selectedClient && tabNumber === 3 && "rgb(211, 211, 211)",
                cursor: selectedClient && "pointer",
                color: !selectedClient && '#CCCCCC'
            }}
                 className={'clients-menu-category-wrapper-button'}
                 onClick={() => selectedClient && handleSetTabNumber(3)}>
                <div style={{paddingRight: 20}}>
                    <Badge badgeContent={(operationsCount && selectedClient) ? operationsCount[2] : 0} color="secondary" showZero>
                        <LocalShipping color="secondary" />
                    </Badge>
                </div>
                <span>
                    En livraison
                </span>
                <div style={{flex: 1}}/>
                <Icon>keyboard_arrow_right_icon</Icon>
            </div>
            <div style={{
                background: selectedClient && tabNumber === 4 && "rgb(211, 211, 211)",
                cursor: selectedClient && "pointer",
                color: !selectedClient && '#CCCCCC'
            }}
                 className={'clients-menu-category-wrapper-button'}
                 onClick={() => selectedClient && handleSetTabNumber(4)}>
                <div style={{paddingRight: 20}}>
                    <Badge badgeContent={(operationsCount && selectedClient) ? operationsCount[3] : 0} color="secondary" showZero>
                        <Receipt color="secondary" />
                    </Badge>
                </div>
                <span>
                    Facturé
                </span>
                <div style={{flex: 1}}/>
                <Icon>keyboard_arrow_right_icon</Icon>
            </div>
            <div style={{
                background: selectedClient && tabNumber === 5 && "rgb(211, 211, 211)",
                cursor: selectedClient && "pointer",
                color: !selectedClient && '#CCCCCC'
            }}
                 className={'clients-menu-category-wrapper-button'}
                 onClick={() => selectedClient && handleSetTabNumber(5)}>
                <div style={{paddingRight: 20}}>
                    <Badge badgeContent={(operationsCount && selectedClient) ? operationsCount[4] : 0} color="secondary" showZero>
                        <Euro color="secondary" />
                    </Badge>
                </div>
                <span>
                    Réglé
                </span>
                <div style={{flex: 1}}/>
                <Icon>keyboard_arrow_right_icon</Icon>
            </div>
            <div style={{
                background: selectedClient && tabNumber === 6 && "rgb(211, 211, 211)",
                cursor: selectedClient && "pointer",
                color: !selectedClient && '#CCCCCC'
            }}
                 className={'clients-menu-category-wrapper-button'}
                 onClick={() => selectedClient && handleSetTabNumber(6)}>
                <div style={{paddingRight: 20}}>
                    <Badge badgeContent={(operationsCount && selectedClient) ? operationsCount[5] : 0} color="secondary">
                        <Archive color="secondary" />
                    </Badge>
                </div>
                <span>
                    Archives
                </span>
                <div style={{flex: 1}}/>
                <Icon>keyboard_arrow_right_icon</Icon>
            </div>
            <div style={{
                background: selectedClient && tabNumber === 7 && "rgb(211, 211, 211)",
                cursor: selectedClient && "pointer",
                color: !selectedClient && '#CCCCCC'
            }}
                 className={'clients-menu-category-wrapper-button'}
                 onClick={() => selectedClient && handleSetTabNumber(7)}>
                <div style={{paddingRight: 20}}>
                    <Badge badgeContent={(operationsCount && selectedClient) ? operationsCount[6] : 0} color="secondary">
                        <Archive color="secondary" />
                    </Badge>
                </div>
                <span>
                    Avoirs
                </span>
                <div style={{flex: 1}}/>
                <Icon>keyboard_arrow_right_icon</Icon>
            </div>
            <div style={{
                background: selectedClient && tabNumber === 8 && "rgb(211, 211, 211)",
                cursor: selectedClient && "pointer",
                color: !selectedClient && '#CCCCCC'
            }}
                 className={'clients-menu-category-wrapper-button'}
                 onClick={() => selectedClient && handleSetTabNumber(8)}>
                <div style={{paddingRight: 20}}>
                    <Badge badgeContent={(operationsCount && selectedClient) ? operationsCount[7] : 0} color="secondary">
                        <Archive color="secondary" />
                    </Badge>
                </div>
                <span>
                   Historique
                </span>
                <div style={{flex: 1}}/>
                <Icon>keyboard_arrow_right_icon</Icon>
            </div>
            <div style={{flex: 1}}/>
            {
                selectedClient &&
                <Button variant={"contained"} onClick={() => handleOpenCloseNewOperationModal()} style={{marginBottom:8}} startIcon={<Add/>}>
                    Créer une opération
                </Button>
            }
            <Button variant={"contained"} style={{marginTop: "10px"}} onClick={() => handleOpenEditCreateClientModal()}  startIcon={<Add/>}>
                nouveau client
            </Button>
        </Paper>
    )
}

export default ClientsMenu
