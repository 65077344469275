import React, {useState} from "react"
import '../style/SuperSearch.scss'
import {Icon, TextField} from "@mui/material"
import SuperSearchModal from "./SuperSearchModal";

const SuperSearch = () => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [inputValue, setInputValue] = useState("")

    const handleTextFieldChange = (value) => {
        if(value !== "" && !isModalOpen){
            setIsModalOpen(true)
            setInputValue(value)
        } else if (value !== "" && isModalOpen){
            setInputValue(value)
        } else if(value === "" && isModalOpen){
            setIsModalOpen(false)
            setInputValue("")
        }
    }

    const handleToggleModalState = (isOpen) => {
        setIsModalOpen(isOpen)
        setInputValue("")
    }

    return(
        <div className={'super-search-wrapper'}>
            <Icon>search</Icon>
            <TextField
                value={inputValue}
                onChange={(e) => handleTextFieldChange(e.target.value)}
                className="super-search-textfield"
                size={"small"}
                label="Super recherche"
                placeholder={"Saisissez votre recherche"}
                variant="outlined" />
            {isModalOpen && <SuperSearchModal inputValue={inputValue} isOpen={isModalOpen} handleClose={handleToggleModalState} handleTextFieldChange={handleTextFieldChange}/>}
        </div>
    )
}

export default SuperSearch
