import React, {useState, useEffect} from "react"
import {Link, withRouter} from "react-router-dom";
import {
    Button,
    Checkbox, FormControlLabel,
    FormLabel,
    Icon,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    FormGroup,
    FormControl
} from "@mui/material";
import Badge from "@mui/material/Badge";
import {Cached, LocalShipping, PendingActions, Receipt} from "@mui/icons-material";

const EmailModalDocuments = ({type, setType, operationsCommande, operationsQuotes, operationsDelivery, operationsInvoiced, selectedDocuments, setSelectedDocuments}) => {

    const categories = [
        {
            title: "Reférence interne",
            key: "id"
        },
        {
            title: "Référence client",
            key: "numeroCommandeClient"
        },
        {
            title: "Sélectionner",
            key: "actions"
        },
    ]

    return (
        operationsDelivery && operationsCommande && operationsQuotes && operationsInvoiced && operationsDelivery.length && operationsCommande.length && operationsQuotes.length && operationsInvoiced.length ?
            <>
                <FormLabel component="legend">Fichiers disponibles</FormLabel>
                <ToggleButtonGroup
                    className={"email-modal-wrapper-field"}
                    color="primary"
                    value={type}
                    exclusive
                    onChange={setType}
                    style={{marginTop: "10px"}}
                >
                    <ToggleButton style={{width: "25%"}} value="quote" disabled={operationsQuotes.length === 0}>
                        <Badge badgeContent={operationsQuotes.length} color="secondary" style={{marginRight: "10px"}}>
                            <PendingActions color="secondary"/>
                        </Badge>
                        <div>Devis</div>
                    </ToggleButton>
                    <ToggleButton style={{width: "25%"}} value="orders" disabled={operationsCommande.length === 0}>
                        <Badge badgeContent={operationsCommande.length} color="secondary" style={{marginRight: "10px"}}>
                            <Cached color="secondary"/>
                        </Badge>
                        <div>Commandes</div>
                    </ToggleButton>
                    <ToggleButton style={{width: "25%"}} value="delivery" disabled={operationsDelivery.length === 0}>
                        <Badge badgeContent={operationsDelivery.length} color="secondary" style={{marginRight: "10px"}}>
                            <LocalShipping color="secondary"/>
                        </Badge>
                        <div>Livraisons</div>
                    </ToggleButton>
                    <ToggleButton style={{width: "25%"}} value="invoices" disabled={operationsInvoiced.length === 0}>
                        <Badge badgeContent={operationsInvoiced.length} color="secondary" style={{marginRight: "10px"}}>
                            <Receipt color="secondary"/>
                        </Badge>
                        <div>Facturé</div>
                    </ToggleButton>
                </ToggleButtonGroup>
                <div className={"email-modal-wrapper-table-wrapper"}>
                    <TableContainer component={Paper} className={"email-modal-wrapper-table-wrapper-table"}>
                        <Table size="small" stickyHeader={true} style={{border: "1px solid #EFEFEF"}}>
                            <TableHead>
                                <TableRow>
                                    {categories.map((category) => {
                                        return (
                                            <TableCell style={{backgroundColor: "#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    type === "quote" &&
                                    operationsQuotes.map((order) => (
                                        <TableRow
                                            key={order.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>
                                                <div
                                                    className={'email-modal-wrapper-table-wrapper-table-operation-ref-wrapper'}>
                                                    <div
                                                    >
                                                        {order.id}
                                                    </div>

                                                </div>

                                            </TableCell>
                                            <TableCell>{order.numeroCommandeClient}</TableCell>
                                            <TableCell>
                                                <FormGroup row>
                                                    <FormControlLabel control={<Checkbox
                                                        size={"small"}
                                                        onChange={() => setSelectedDocuments("quotes", order)}
                                                        checked={selectedDocuments.quotes.some((op) => op.id === order.id)}
                                                    />} label="Devis"/>
                                                </FormGroup>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {
                                    type === "orders" &&
                                    operationsCommande.map((order) => (
                                        <TableRow
                                            key={order.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>
                                                <div
                                                    className={'email-modal-wrapper-table-wrapper-table-operation-ref-wrapper'}>
                                                    <div
                                                    >
                                                        {order.id}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>{order.numeroCommandeClient}</TableCell>
                                            <TableCell>
                                                <FormControl component="fieldset" variant="standard">
                                                    <FormGroup row>
                                                        <FormControlLabel control={
                                                            <Checkbox
                                                                size={"small"}
                                                                onChange={() => setSelectedDocuments("quotes", order)}
                                                                checked={selectedDocuments.quotes.some((op) => op.id === order.id)}
                                                            />
                                                        } label="Devis"/>
                                                        <FormControlLabel control={
                                                            <Checkbox
                                                                size={"small"}
                                                                onChange={() => setSelectedDocuments("orders", order)}
                                                                checked={selectedDocuments.orders.some((op) => op.id === order.id)}
                                                            />
                                                        } label="Proforma"/>
                                                    </FormGroup>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {
                                    type === "delivery" &&
                                    operationsDelivery.map((order) => (
                                        <TableRow
                                            key={order.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>
                                                <div
                                                    className={'email-modal-wrapper-table-wrapper-table-operation-ref-wrapper'}>
                                                    <div
                                                    >
                                                        {order.id}
                                                    </div>

                                                </div>

                                            </TableCell>
                                            <TableCell>{order.numeroCommandeClient}</TableCell>
                                            <TableCell>
                                                <FormControlLabel control={<Checkbox
                                                    size={"small"}
                                                    onChange={() => setSelectedDocuments("quotes", order)}
                                                    checked={selectedDocuments.quotes.some((op) => op.id === order.id)}
                                                />} label="Devis"/>

                                                <FormControlLabel control={<Checkbox
                                                    size={"small"}
                                                    onChange={() => setSelectedDocuments("orders", order)}
                                                    checked={selectedDocuments.orders.some((op) => op.id === order.id)}
                                                />} label="Proforma"/>
                                                <FormControlLabel control={<Checkbox
                                                    size={"small"}
                                                    onChange={() => setSelectedDocuments("delivery", order)}
                                                    checked={selectedDocuments.delivery.some((op) => op.id === order.id)}
                                                />} label="BL"/>

                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {
                                    type === "invoices" &&
                                    operationsInvoiced.map((order) => (
                                        <TableRow
                                            key={order.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>
                                                <div
                                                    className={'email-modal-wrapper-table-wrapper-table-operation-ref-wrapper'}>
                                                    <div
                                                    >
                                                        {order.factureNumber}
                                                    </div>

                                                </div>

                                            </TableCell>
                                            <TableCell>
                                                <div className={'email-modal-wrapper-table-wrapper-table-multiple-operations-ref-wrapper'}>
                                                    {order.operations.map((op) => {
                                                        return (
                                                            <div className={'email-modal-wrapper-table-wrapper-table-operation-ref-wrapper'}>
                                                                {op.numero}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <FormControlLabel control={<Checkbox
                                                    size={"small"}
                                                    onChange={() => setSelectedDocuments("simpleInvoice", order)}
                                                    checked={selectedDocuments.simpleInvoices.some((op) => op.factureNumber === order.factureNumber)}
                                                />} label="Facture simple"/>

                                                <FormControlLabel control={<Checkbox
                                                    size={"small"}
                                                    onChange={() => setSelectedDocuments("detailedInvoice", order)}
                                                    checked={selectedDocuments.detailedInvoices.some((op) => op.factureNumber === order.factureNumber)}
                                                />} label="Facture détaillée"/>

                                            </TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
            :
            <div style={{color:"red"}} className={"email-modal-wrapper-field"}>Aucun fichier disponible</div>
    )

}

export default withRouter(EmailModalDocuments)