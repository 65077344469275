import React, {useState, useEffect} from "react"
import {
    Button,
    Checkbox,
    Icon,
    IconButton,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import dayjs from 'dayjs'
import Loader from "../../shared/loader/Loader";
import OperationDetailsModal from "../operations/OperationDetailsModal"
import {filterData, groupArticles} from "../../shared/utils/Utils";
import './style/Clients.scss'
import {getOperationDetail, downloadOperationPDF, deleteAvoir} from "../operations/api/OperationsAPI";
import toast from "react-hot-toast"
import {withRouter} from "react-router-dom"
import {Add} from "@mui/icons-material";
import ClientsAvoirModal from "./ClientsAvoirModal";


const ClientAvoirs = ({
                          operations,
                          handleTriggerRefetch,
                          selectedClient,
                          history
                      }) => {


    const [data, setData] = useState(null)
    const [initialData, setInitialData] = useState(null)
    const [filterInputValue, setFilterInputValue] = useState("")
    const [pageNumber, setPageNumber] = useState(1)
    const [isCreateAvoirModalOpen, setIsCreateAvoirModalOpen] = useState(false)


    useEffect(() => {
        setInitialData(operations)
        setData(operations)
    }, [operations])

    useEffect(() => {
        if (initialData) {
            const filteredData = filterData(initialData, filterInputValue)
            setData(filteredData)
        }
    }, [filterInputValue])

    const handleFilterInputValueChange = (e) => {
        setFilterInputValue(e.target.value)
    }


    const handleClickOnPDF = async (avoir) => {
        avoir.customer = selectedClient
        downloadOperationPDF(avoir, "avoir")
    }


    const handleClickOnRemoveAvoir = async (avoirId) => {
        try {
            await deleteAvoir(avoirId)
            toast.success("L'avoir a été supprimé avec succès")
            handleTriggerRefetch()
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la suppression de l'avoir")
        }
    }

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const handleToggleModalState = () => {
        if (isCreateAvoirModalOpen) {
            setIsCreateAvoirModalOpen(false)
        } else if (!isCreateAvoirModalOpen) {
            setIsCreateAvoirModalOpen(true)
        }
    }

    const startRange = (pageNumber - 1) * 10
    const endRange = pageNumber * 10

    const categories = [
        {
            title: "Numéro",
            key: "number"
        },
        {
            title: "Date de création",
            key: "paymentDate"
        },
        {
            title: "Total HT",
            key: "amount"
        },
        {
            title: "TVA",
            key: "tva"
        },
        {
            title: "Dépollution",
            key: "clientName"
        },
        {
            title: "Actions",
            key: "actions"
        }
    ]

    return (
        <Paper className={"client-operations-wrapper"}>
            {data ?
                (
                    <div className={"client-operations-wrapper-wrapper"}>
                        <div className={"client-operations-wrapper-header"} style={{height: "auto"}}>
                            <div className={"client-operations-wrapper-header-filter-sort"}>
                                <TextField className={"client-operations-wrapper-header-filter-input"} size="small"
                                           variant="outlined" onChange={handleFilterInputValueChange}
                                           label={"Recherche"}
                                           value={filterInputValue}/>
                            </div>
                            <Button variant={"contained"} style={{marginLeft: 10}} onClick={() => handleToggleModalState()} startIcon={<Add/>}>
                                Créer un avoir
                            </Button>
                        </div>
                        <div className={"client-operations-wrapper-content"}>
                            <TableContainer component={Paper} className={"client-operations-wrapper-content-table"}>
                                <Table size={"small"} style={{border: "1px solid #EFEFEF"}} stickyHeader={true}>
                                    <TableHead>
                                        <TableRow>
                                            {categories.map((category) => {
                                                return (
                                                    <TableCell style={{backgroundColor: "#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice(startRange, endRange).map((avoir) => (
                                            <TableRow
                                                key={avoir.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell style={{width: "150px"}}>
                                                    <div
                                                        className={'client-operations-wrapper-content-table-operation-ref-wrapper'}>
                                                        {dayjs(avoir.date).format("YYYYMM")}{avoir.numero}
                                                    </div>
                                                </TableCell>
                                                <TableCell>{dayjs(avoir.date).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{avoir.prixHT} €</TableCell>
                                                <TableCell>
                                                    <Checkbox checked={avoir.tva === "1"}/>
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox checked={avoir.depollution === "1"}/>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title={'Edition avoir PDF'}>
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={() => handleClickOnPDF(avoir)}
                                                            style={{}}>
                                                            <Icon color={"#555555"}>{"insert_drive_file"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Supprimer l'avoir"}>
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={() => handleClickOnRemoveAvoir(avoir.id)}
                                                            style={{}}>
                                                            <Icon color={"#555555"}>{"cancel"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={"client-operations-wrapper-content-pagination"}>
                                <Pagination count={data && Math.ceil(data.length / 10)} onChange={handlePageChange} page={pageNumber}/>
                            </div>
                        </div>
                    </div>
                )
                :
                <div className={"client-operations-wrapper-content-loader-wrapper"}>
                    <Loader/>
                </div>
            }
            {isCreateAvoirModalOpen &&
            <ClientsAvoirModal isOpen={isCreateAvoirModalOpen} handleClose={handleToggleModalState} clientId={selectedClient.id} handleSetTriggerRefetch={handleTriggerRefetch}/>}
        </Paper>
    )
}

export default withRouter(ClientAvoirs)
