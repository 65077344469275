import React, {useState} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent, TextField, Box, Select, MenuItem, FormControlLabel, Checkbox} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import toast from "react-hot-toast"
import {editProvider, createProvider} from "../api/ProvidersAPI";

const ProvidersCreateEditProvider = ({providerDetails, isOpen, handleClose, handleSetTriggerRefetch}) => {

    const [nom, setNom] = useState(providerDetails ? providerDetails.nom : "")
    const [siret, setSiret] = useState(providerDetails ? providerDetails.siret : "")
    const [adresse, setAdresse] = useState(providerDetails ? providerDetails.adresse : "")
    const [codePostal, setCodePostal] = useState(providerDetails ? providerDetails.codePostal : "")
    const [ville, setVille] = useState(providerDetails ? providerDetails.ville : "")
    const [telephone, setTelephone] = useState(providerDetails ? providerDetails.telephone : "")
    const [fax, setFax] = useState(providerDetails ? providerDetails.fax : "")
    const [tva, setTva] = useState(providerDetails ? providerDetails.tva : "")

    const handleSetNom = (value) => {
        setNom(value)
    }

    const handleSetSiret = (value) => {
        setSiret(value)
    }

    const handleSetAdresse = (value) => {
        setAdresse(value)
    }

    const handleSetCodePostal = (value) => {
        setCodePostal(value)
    }

    const handleSetVille = (value) => {
        setVille(value)
    }

    const handleSetTelephone = (value) => {
        setTelephone(value)
    }

    const handleSetFax = (value) => {
        setFax(value)
    }

    const handleSetTva = (value) => {
        setTva(!tva)
    }

    const handleCreateEditProvider = async () => {
        const payload = {
            adresse: adresse,
            codePostal: codePostal,
            nom: nom,
            siret: siret,
            ville: ville,
            telephone: telephone,
            fax: fax,
            tva: tva
        }
        try {
            if(providerDetails){
                await editProvider(providerDetails.id, payload)
                handleClose()
                handleSetTriggerRefetch()
                toast.success("Le fournisseur a été mis à jour avec succès")
            } else {
                await createProvider(payload)
                handleClose()
                handleSetTriggerRefetch()
                toast.success("Le fournisseur a été créé avec succès")
            }
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant l'opération")
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>{providerDetails ? "Modification du fournisseur" : "Création d'un fournisseur"}</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={nom}
                        onChange={(e) => handleSetNom(e.target.value)}
                        variant="outlined"
                        label={"Dénomination"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={siret}
                        onChange={(e) => handleSetSiret(e.target.value)}
                        variant="outlined"
                        label={"SIRET"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={adresse}
                        onChange={(e) => handleSetAdresse(e.target.value)}
                        variant="outlined"
                        label={"Adresse"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={codePostal}
                        onChange={(e) => handleSetCodePostal(e.target.value)}
                        variant="outlined"
                        label={"Code Postal"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={ville}
                        onChange={(e) => handleSetVille(e.target.value)}
                        variant="outlined"
                        label={"Ville"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={telephone}
                        onChange={(e) => handleSetTelephone(e.target.value)}
                        variant="outlined"
                        label={"Téléphone"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={fax}
                        onChange={(e) => handleSetFax(e.target.value)}
                        variant="outlined"
                        label={"Fax"}
                    />
                    <FormControlLabel control={<Checkbox onChange={() => handleSetTva()} checked={tva}/>} label="TVA applicable par défaut ?"/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleCreateEditProvider()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProvidersCreateEditProvider