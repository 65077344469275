import React, {useState, useEffect} from "react"
import {
    Button,
    Icon,
    IconButton,
    Paper,
} from '@mui/material'
import "./PricingRules.scss"
import {Line} from 'react-chartjs-2';
import PricingRulesNewEditThicknessLengthRuleModal from "./PricingRulesNewEditThicknessLengthRuleModal";

const PricingRulesLengthRules = ({data, handleDeleteLengthRule, triggerRefetch, setTriggerRefetch}) => {

    const [selectedRule, setSelectedRule] = useState(null)
    const [modalMode, setModalMode] = useState(null)

    const handleEditAddRule = (rule) => {
        if (rule) {
            setSelectedRule(rule)
            setModalMode("edit")
        } else {
            setSelectedRule({libelle: "", pointsSeuils: []})
            setModalMode("create")
        }
    }

    const handleCloseModal = () => {
        setModalMode(null)
        setSelectedRule(null)
    }

    return (
        data &&
        <Paper className={"pricing-rules-rules-wrapper"}>
            <div className={"pricing-rules-rules-wrapper-header"}>
                <div className={"pricing-rules-rules-wrapper-header-title"}>
                    Lois de pondération de la tarification en longueur
                </div>
                <Button variant={"contained"}
                    onClick={() => handleEditAddRule()}
                >Ajouter une loi de longueur</Button>
            </div>
            <div className={"pricing-rules-rules-wrapper-graphs-container"}>
                {data.map((rule) => {
                    const data = {
                        labels: rule.pointsSeuils.sort((a, b) => a.point - b.point).map((ps) => ps.point),
                        datasets: [
                            {
                                label: 'Tarification (en %)',
                                data: rule.pointsSeuils.sort((a, b) => a.point - b.point).map((ps) => ps.pourcentage),
                                fill: false,
                                backgroundColor: '#1E2129',
                                borderColor: '#1E2129',
                            },
                        ],
                    };

                    const options = {
                        plugins: {
                            legend: {
                                display: false
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {}
                    }
                    return (
                        <div className={"pricing-rules-rules-wrapper-graph-container"}>
                            <div className={"pricing-rules-rules-wrapper-graph-container-header"}>
                                <span>{rule.libelle}</span>
                                <div>
                                    <IconButton
                                        onClick={() => handleEditAddRule(rule)}
                                        style={{}}>
                                        <Icon color={"primary"}>{"edit"}</Icon>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDeleteLengthRule(rule.id)}
                                        style={{}}>
                                        <Icon color={"primary"}>{"delete"}</Icon>
                                    </IconButton>
                                </div>
                            </div>
                            <Line data={data} options={options}/>
                        </div>
                    )
                })}
            </div>
            {modalMode && <PricingRulesNewEditThicknessLengthRuleModal ruleType="length" mode={modalMode} rule={selectedRule} handleClose={handleCloseModal} triggerRefetch={triggerRefetch}
                                                                 setTriggerRefetch={setTriggerRefetch}/>}
        </Paper>
    )
}

export default PricingRulesLengthRules