import React, {useState, useEffect} from "react"
import {
    Icon,
    IconButton,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import dayjs from 'dayjs'
import Loader from "../../shared/loader/Loader";
import OperationDetailsModal from "../operations/OperationDetailsModal"
import {filterData, groupArticles} from "../../shared/utils/Utils";
import './style/Clients.scss'
import {getOperationDetail, downloadOperationPDF, registerPayment} from "../operations/api/OperationsAPI";
import {removeDuplicateItems} from "../../shared/utils/Utils";
import {deleteFacture} from "../operations/api/OperationsAPI"
import toast from "react-hot-toast"
import {withRouter} from "react-router-dom"
import OperationBillsRegisterPaymentModal from "../operations/bills/OperationBillsRegisterPaymentModal";


const ClientBills = ({
                         operations,
                         handleTriggerRefetch,
                         selectedClient,
                         history
                     }) => {

    const [data, setData] = useState(null)
    const [initialData, setInitialData] = useState(null)
    const [filterInputValue, setFilterInputValue] = useState("")
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const [orderIdHovered, setOrderIdHovered] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [isRegisterPaymentModalOpen, setIsRegisterPaymentModalOpen] = useState(false)
    const [selectedFactureId, setSelectedFactureId] = useState(null)


    useEffect(() => {
        // const groupedData = groupOperationsByBill(operations)
        setInitialData(operations)
        setData(operations)
    }, [operations])

    const handleOpenCloseRegisterPaymentModal = (factureId) => {
        if (isRegisterPaymentModalOpen) {
            setIsRegisterPaymentModalOpen(false)
            setSelectedFactureId(null)
        } else {
            setIsRegisterPaymentModalOpen(true)
            setSelectedFactureId(factureId)
        }
    }

    useEffect(() => {
        if (initialData) {
            const filteredData = filterData(initialData, filterInputValue)
            setData(filteredData)
        }
    }, [filterInputValue])

    const handleFilterInputValueChange = (e) => {
        setFilterInputValue(e.target.value)
    }

    const handleClickOnBasicBill = async (bill) => {
        downloadOperationPDF(bill, "basicBill")
    }

    const handleClickOnDetailedBill = async (bill) => {
        const operationsDetails = []
        for (let operation of bill.operations) {
            const operationData = await getOperationDetail(operation.id)
            if (operationData && operationData.operation && operationData.operation[0]) {
                const groupedOperationData = groupArticles(operationData)
                operationsDetails.push(groupedOperationData)
            }
        }
        bill.operationsDetails = operationsDetails
        downloadOperationPDF(bill, "detailedBill")
    }


    const handleClickOnCancelBill = async (billId) => {
        try {
            await deleteFacture(billId)
            toast.success("La facture a été supprimée avec succès")
            handleTriggerRefetch()
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la suppression de la facture")
        }
    }

    const handleToggleModalState = (event, orderId) => {
        if (event) {
            setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget)
            setOrderIdHovered(orderId ? orderId : null)
        } else {
            setPopperAnchorEl(null)
            setOrderIdHovered(null)
        }
    }

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const startRange = (pageNumber - 1) * 10
    const endRange = pageNumber * 10

    const categories = [
        {
            title: "Numéro de facture",
            key: "billNumber"
        },
        {
            title: "Date de facturation",
            key: "billDate"
        },
        {
            title: "Total HT",
            key: "amount"
        },
        {
            title: "Opérations",
            key: "operations"
        },
        {
            title: "Client",
            key: "clientName"
        },
        {
            title: "Actions",
            key: "actions"
        }
    ]


    // const groupOperationsByBill = (operations) => {
    //     const result = removeDuplicateItems(operations, 'facture_id').map((item) => ({
    //         facture_id: item.facture_id,
    //         factureNumber: dayjs(item.dateCreation).format("YYYYMM") + item.facture_numero,
    //         dateFacture: item.dateFacture,
    //         clientName: item.client_nom,
    //         operations: []
    //     }))
    //     for (let bill of result) {
    //         for (let operation of operations) {
    //             if (bill.facture_id === operation.facture_id) {
    //                 bill.operations.push(operation)
    //             }
    //         }
    //         bill.prixHT = bill.operations.map((op) => parseFloat(op.prixHT)).reduce((prev, acc) => prev + acc)
    //         bill.prixHTPlusDepollution = bill.operations.map((op) => parseFloat(op.prixHTPlusDepollution)).reduce((prev, acc) => prev + acc)
    //         bill.prixTTC = bill.operations.map((op) => parseFloat(op.prixTTC)).reduce((prev, acc) => prev + acc)
    //         bill.customer = selectedClient
    //     }
    //     return result
    // }


    return (
        <Paper className={"client-operations-wrapper"}>
            {data ?
                (
                    <div className={"client-operations-wrapper-wrapper"}>
                        <div className={"client-operations-wrapper-header"} style={{height: "auto"}}>
                            <div className={"client-operations-wrapper-header-filter-sort"}>
                                <TextField className={"client-operations-wrapper-header-filter-input"} size="small"
                                           label={"Recherche"}
                                           variant="outlined" onChange={handleFilterInputValueChange}
                                           value={filterInputValue}/>
                            </div>

                        </div>
                        <div className={"client-operations-wrapper-content"}>
                            <TableContainer component={Paper} className={"client-operations-wrapper-content-table"}>
                                <Table size={"small"} style={{border:"1px solid #EFEFEF"}} stickyHeader={true}>
                                    <TableHead>
                                        <TableRow>
                                            {categories.map((category) => {
                                                return (
                                                    <TableCell style={{backgroundColor:"#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice(startRange, endRange).map((bill) => (
                                            <TableRow
                                                key={bill.facture_id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell style={{width: "150px"}}>
                                                    <div
                                                        className={'client-operations-wrapper-content-table-operation-ref-wrapper'}>
                                                        {bill.factureNumber}
                                                    </div>
                                                </TableCell>
                                                <TableCell>{dayjs(bill.facture_date).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{(bill.prixHT).toFixed(2)} €</TableCell>
                                                <TableCell>
                                                    <div style={{display: "flex", width: "300px", flexWrap: "wrap"}}>
                                                        {bill.operations.map((operation) => {
                                                            return (
                                                                <div
                                                                    onMouseEnter={(e) => handleToggleModalState(e, operation.numero)}
                                                                    onMouseLeave={() => handleToggleModalState()}
                                                                    onClick={() => history.push(`/operations/operation/detail/${operation.id}`)}
                                                                    style={{margin: "2px"}}
                                                                    className={'client-operations-wrapper-content-table-operation-ref-wrapper'}>
                                                                    {operation.id}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                </TableCell>
                                                <TableCell>{bill.clientName}</TableCell>
                                                <TableCell>
                                                    <Tooltip title={'Edition facture simple'}>
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={() => handleClickOnBasicBill(bill)}
                                                            style={{}}>
                                                            <Icon color={"#555555"}>{"insert_drive_file"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={'Edition facture détaillée'}>
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={() => handleClickOnDetailedBill(bill)}
                                                            style={{}}>
                                                            <Icon color={"#555555"}>{"description"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={'Annuler la facture'}>
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={() => handleClickOnCancelBill(bill.facture_id)}
                                                            style={{}}>
                                                            <Icon color={"#555555"}>{"cancel"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={'Enregistrer le paiement'}>
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={() => handleOpenCloseRegisterPaymentModal(bill.facture_id)}
                                                            style={{}}>
                                                            <Icon color={"#555555"}>{"check"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={"client-operations-wrapper-content-pagination"}>
                                <Pagination count={data && Math.ceil(data.length / 10)} onChange={handlePageChange} page={pageNumber}/>
                            </div>
                        </div>
                    </div>
                )
                :
                <div className={"client-operations-wrapper-content-loader-wrapper"}>
                    <Loader/>
                </div>
            }
            {popperAnchorEl &&
            <OperationDetailsModal popperAnchorEl={popperAnchorEl} toggleModal={handleToggleModalState} orderId={orderIdHovered}/>}
            <OperationBillsRegisterPaymentModal isOpen={isRegisterPaymentModalOpen} factureId={selectedFactureId} handleClose={handleOpenCloseRegisterPaymentModal}
                                                triggerRefetch={handleTriggerRefetch}/>
        </Paper>
    )
}

export default withRouter(ClientBills)
