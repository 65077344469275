import React, {useState, useEffect, useContext} from "react"
import {
    Icon,
    IconButton,
    Button,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import {getOrdersInProduction, downloadOperationPDF} from "../../operations/api/OperationsAPI";
import {matchOperationsWithFinish} from "../../../shared/utils/Utils";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {getSalesSummary} from "../api/StatsAPI";
import {UserContext} from '../../../contexts/UserContext'
import "./Exports.scss"

const Exports = () => {

    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [operationsInProduction, setOperationsInProduction] = useState(null)

    useEffect(() => {
        handleGetOperationsInProduction()
    }, [])

    const {jwt} = useContext(UserContext)

    const handleGetOperationsInProduction = async () => {
        const operations = await getOrdersInProduction()
        const operationsWithFinish = matchOperationsWithFinish(operations)
        setOperationsInProduction(operationsWithFinish.operations)
    }

    const handleCreateOperationsInProductionPDF = () => {
        if (operationsInProduction) {
            downloadOperationPDF(operationsInProduction, "operationsInProductionList")
        }
    }

    const handleGetSalesSummary = async () => {
        const start = dayjs(startDate).format("YYYY-MM-DD")
        const end = dayjs(endDate).format("YYYY-MM-DD")
        try {
            const stats = await getSalesSummary(start, end, jwt)
            const data = await stats.arrayBuffer()
            const file = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"});
            // process to auto download it
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `Commandes_production_${start}_au_${end}.xlsx`;
            link.click();
        } catch (err) {
            console.log(err)
        }
    }

    const handleSetStartDate = (value) => {
        setStartDate(value)
    }

    const handleSetEndDate = (value) => {
        setEndDate(value)
    }

    return (
        <>
            <div className={'exports-wrapper'}>
                <Paper className={'exports-wrapper-export'} style={{marginRight:"20px"}}>
                    <div>Télécharger le récapitulatif des commandes en cours</div>
                    <Button style={{marginTop:"20px"}} variant={"contained"} onClick={() => handleCreateOperationsInProductionPDF()}>Télécharger</Button>
                </Paper>

                <Paper className={'exports-wrapper-export'}>
                    <div style={{marginBottom:"20px"}}>Télécharger le récapitulatif des ventes sur la période sélectionnée</div>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Date de début"
                                inputFormat="dd/MM/yyyy"
                                value={startDate}
                                onChange={(newValue) => handleSetStartDate(dayjs(newValue).format("YYYY-MM-DD"))}
                                renderInput={(params) => <TextField {...params} size={"small"}/>}
                            />
                            <div style={{marginRight: "10px", marginLeft: "10px"}}>au</div>
                            <DesktopDatePicker
                                label="Date de fin"
                                inputFormat="dd/MM/yyyy"
                                value={endDate}
                                onChange={(newValue) => handleSetEndDate(dayjs(newValue).format("YYYY-MM-DD"))}
                                renderInput={(params) => <TextField {...params} size={"small"}/>}
                            />
                        </LocalizationProvider>
                    </div>

                    <Button style={{marginTop:"20px"}} variant={"contained"} onClick={() => handleGetSalesSummary()}>Télécharger</Button>
                </Paper>
            </div>
        </>
    )

}

export default Exports