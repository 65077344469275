import {CircularProgress, Paper, Step, StepLabel, Stepper} from "@mui/material";
import {
    DoneAll,
    QrCodeScanner,
    QrCodeScannerSharp,
    Search,
    Start,
    SystemUpdate,
    SystemUpdateAlt
} from "@mui/icons-material";


export function CraftingStepScanQR() {

    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", height: "calc(100vh - 58px)"}}>

            {/*<div>Poste de <strong>vernissage</strong></div>*/}
            <Stepper activeStep={1} alternativeLabel style={{marginTop: 20}}>
                <Step>
                    <StepLabel>Choix de l'action</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Scan du bon de suivi</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Enregistrement de l'avancement</StepLabel>
                </Step>
            </Stepper>

            <div>
                <QrCodeScanner style={{transform: "scale(8)", marginTop: 150, marginBottom: 150}}/>
            </div>

            <div style={{color: "grey", display:"flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                <CircularProgress variant={"indeterminate"}/>
                <div style={{fontSize: 18, padding: 20}}>Lecture en cours</div>
            </div>

            <div style={{fontSize: 20, fontWeight: "bold"}}>
                scannez le code QR du bon de suivi
            </div>



        </div>
    )



}
