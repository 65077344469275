import React, {useEffect, useState} from "react"
import {Link, withRouter} from "react-router-dom"
import {Button, Icon, IconButton, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip} from "@mui/material";
import {deleteOrder, getProviderDetails} from "../api/ProvidersAPI";
import "./Providers.scss"
import {deleteContact} from "../../clients/api/ClientAPI";
import ProvidersAddEditContact from "./ProvidersAddEditContact";
import dayjs from "dayjs";
import ProvidersDeleteConfirmationModal from "./ProvidersDeleteConfirmationModal"
import ProvidersCreateEditBC from "./ProvidersCreateEditBC";
import toast from "react-hot-toast";
import {downloadOperationPDF, getOperationDetail} from "../../operations/api/OperationsAPI";
import ProvidersEditStatusModal from "./ProvidersEditStatusModal";
import ProvidersCreateEditProvider from "./ProvidersCreateEditProvider";

const ProviderDetail = (props) => {

    const [providerDetails, setProviderDetails] = useState(null)
    const [orders, setOrders] = useState(null)
    const [contacts, setContacts] = useState(null)
    const [contactModalMode, setContactModalMode] = useState(null)
    const [selectedContact, setSelectedContact] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
    const [isCreateBCModalOpen, setIsCreateBcModalOpen] = useState(false)
    const [isEditStatusModalOpen, setIsEditStatusModalOpen] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [mode, setMode] = useState(null)
    const [isCreateEditProviderModalOpen, setIsCreatEditProviderModalOpen] = useState(false)


    useEffect(() => {
        handleGetProviderDetails()
    }, [])

    useEffect(() => {
        if (selectedOrder && mode) {
            if (mode === "edit") {
                handleToggleCreateBCModalOpen()
            } else if (mode === "editStatus") {
                handleToggleEditStatusModalOpen()
            }

        }
    }, [selectedOrder])

    const handleGetProviderDetails = async () => {
        const providerId = props.match.params.providerId
        const providerDetails = await getProviderDetails(providerId)
        for (let bc of providerDetails.bon_de_commandes) {
            if (bc.datePaye) {
                bc.statusCommande = `Payé le ${dayjs(bc.datePaye).format("DD/MM/YYYY")}`
            } else if (bc.dateFacture) {
                bc.statusCommande = `Facturé le ${dayjs(bc.dateFacture).format("DD/MM/YYYY")}`
            } else if (bc.dateEnReceptionne) {
                bc.statusCommande = `Réceptionné le ${dayjs(bc.dateEnReceptionne).format("DD/MM/YYYY")}`
            } else if (bc.dateEnCour) {
                bc.statusCommande = `En cours le ${dayjs(bc.dateEnCour).format("DD/MM/YYYY")}`
            } else if (bc.dateEnAttente) {
                bc.statusCommande = `En attente le ${dayjs(bc.dateEnAttente).format("DD/MM/YYYY")}`
            }
            bc.entries = []
            for (let entry of providerDetails.entrees_bon_de_commandes) {
                if (entry.bonDeCommande_id === bc.id) {
                    bc.entries.push(entry)
                }
            }
        }
        setProviderDetails(providerDetails.fournisseur[0])
        setOrders(providerDetails.bon_de_commandes)
        setContacts(providerDetails.contacts)
    }

    const handleDeleteContact = async (contactId) => {
        await deleteContact(contactId)
        handleGetProviderDetails()
    }

    const handleDeleteOrder = async (orderId) => {
        try{
            await deleteOrder(orderId)
            toast.success("Commande supprimée avec succès")
            handleGetProviderDetails()
        }catch(err){
            console.log(err)
            toast.error("Erreur durant la suppression de la commande")
        }
    }

    const handleOpenEditCreateContactModal = (contact) => {
        if (contact) {
            setContactModalMode("edit")
            setSelectedContact(contact)
        } else {
            setContactModalMode("create")
            setSelectedContact({prenom: "", nom: "", genre: "", email: "", telephone: ""})
        }
    }

    const handleToggleDeleteConfirmationModal = () => {
        if (orders && orders.length > 0) {
            toast.error("Impossible de supprimer un fournisseur ayant des commandes")
            return
        }
        if (isDeleteConfirmationModalOpen) {
            setIsDeleteConfirmationModalOpen(false)
        } else if (!isDeleteConfirmationModalOpen) {
            setIsDeleteConfirmationModalOpen(true)
        }
    }

    const handleToggleCreateBCModalOpen = () => {
        if (isCreateBCModalOpen) {
            setIsCreateBcModalOpen(false)
            setSelectedOrder(null)
            setMode(null)
        } else if (!isCreateBCModalOpen) {
            setIsCreateBcModalOpen(true)
        }
    }

    const handleToggleEditStatusModalOpen = () => {
        if (isEditStatusModalOpen) {
            setIsEditStatusModalOpen(false)
            setSelectedOrder(null)
            setMode(null)
        } else if (!isEditStatusModalOpen) {
            setIsEditStatusModalOpen(true)
        }
    }

    const handleToggleCreateEditProviderModalOpen = () => {
        setIsCreatEditProviderModalOpen(!isCreateEditProviderModalOpen)
    }

    const handleCloseContactModal = () => {
        setContactModalMode(null)
        setSelectedContact(null)
    }

    const handleClickOnPDF = async (order) => {
        downloadOperationPDF({...order, ...providerDetails}, "providerOrder")
    }

    const handleSelectOrder = (order) => {
        setSelectedOrder(order)
    }

    const categories = [
        {
            title: "Ref. Renova",
            key: "renovaRef"
        },
        {
            title: "Ref. fournisseur",
            key: "providerRef"
        },
        {
            title: "Date de création",
            key: "creationDate"
        },
        {
            title: "Date de paiement",
            key: "paymentDate"
        },
        {
            title: "Montant HT",
            key: "totalHT"
        },
        {
            title: "Actions",
            key: "actions"
        },
    ]

    const startRange = (pageNumber - 1) * 10
    const endRange = pageNumber * 10

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    return (
        providerDetails && orders &&
        <div className={"provider-wrapper-content noscrollbar"}>
            <div className={"provider-wrapper-details-contacts"}>
                <Paper className={'providers-provider-details-wrapper'}>
                    <div className={"providers-provider-details-wrapper-title"}>
                        <Icon style={{fontSize: 18, color: "#1079FC", marginRight: 10, marginLeft: 4}}>assignment</Icon>
                        Informations sur le fournisseur
                        <div style={{flex: 1}}/>
                        <Button variant={"outlined"} style={{color: "#1079FC", borderColor: "#1079FC"}}
                                onClick={() => handleToggleCreateEditProviderModalOpen()}
                                size={"small"}
                        >Modifier</Button>
                        <Button variant={"outlined"} style={{borderColor: "#EE3E3B", color: "#EE3E3B", marginLeft: "10px"}}
                                size={"small"}
                                onClick={() => handleToggleDeleteConfirmationModal()}
                        >Supprimer</Button>
                    </div>
                    <div style={{height: "1px", width: "100%", backgroundColor: "#CCCCCC", marginBottom: "12px"}}/>
                    <div className={"providers-provider-details-wrapper-content"}>
                        <div className={"provider-details-left-line-wrapper"}>
                            <div className={"provider-details-left-line-content"}>
                                <strong style={{fontSize: 14, display: "inline-block", marginRight: 12}}>Dénomination </strong>
                                <div style={{flex: 1, height: 1, backgroundColor: "#EEEEEE"}}/>
                            </div>
                            {providerDetails.nom}
                        </div>
                        <div className={"provider-details-left-line-wrapper"}>
                            <div className={"provider-details-left-line-content"}>
                                <strong style={{fontSize: 14, display: "inline-block", marginRight: 12}}>SIRET </strong>
                                <div style={{flex: 1, height: 1, backgroundColor: "#EEEEEE"}}/>
                            </div>
                            {providerDetails.siret}
                        </div>
                        <div className={"provider-details-left-line-wrapper"}>
                            <div className={"provider-details-left-line-content"}>
                                <strong style={{fontSize: 14, display: "inline-block", marginRight: 12}}>Adresse </strong>
                                <div style={{flex: 1, height: 1, backgroundColor: "#EEEEEE"}}/>
                            </div>
                            {providerDetails.adresse}
                        </div>
                        <div className={"provider-details-left-line-wrapper"}>
                            <div className={"provider-details-left-line-content"}>
                                <strong style={{fontSize: 14, display: "inline-block", marginRight: 12}}>Ville </strong>
                                <div style={{flex: 1, height: 1, backgroundColor: "#EEEEEE"}}/>
                            </div>
                            {providerDetails.codePostal} {providerDetails.ville}
                        </div>
                        <div className={"provider-details-left-line-wrapper"}>
                            <div className={"provider-details-left-line-content"}>
                                <strong style={{fontSize: 14, display: "inline-block", marginRight: 12}}>Téléphone </strong>
                                <div style={{flex: 1, height: 1, backgroundColor: "#EEEEEE"}}/>
                            </div>
                            {providerDetails.telephone}
                        </div>
                        <div className={"provider-details-left-line-wrapper"}>
                            <div className={"provider-details-left-line-content"}>
                                <strong style={{fontSize: 14, display: "inline-block", marginRight: 12}}>Fax </strong>
                                <div style={{flex: 1, height: 1, backgroundColor: "#EEEEEE"}}/>
                            </div>
                            {providerDetails.fax}
                        </div>
                        <div className={"provider-details-left-line-wrapper"}>
                            <div className={"provider-details-left-line-content"}>
                                <strong style={{fontSize: 14, display: "inline-block", marginRight: 12}}>TVA applicable par défaut</strong>
                                <div style={{flex: 1, height: 1, backgroundColor: "#EEEEEE"}}/>
                            </div>
                            {providerDetails.tva === "1" ? "Oui" : "Non"}
                        </div>
                    </div>
                </Paper>
                <Paper className={'provider-contacts-wrapper'}>
                    <div className={"provider-contacts-wrapper-title"}>
                        <Icon style={{fontSize: 18, color: "#1079FC", marginRight: 10, marginLeft: 4}}>contacts</Icon>
                        Contacts
                        <div style={{flex: 1}}/>
                        <Button variant={"outlined"} style={{color: "#1079FC", borderColor: "#1079FC"}}
                                size={"small"}
                                onClick={() => handleOpenEditCreateContactModal()}
                        >Ajouter un contact</Button>
                    </div>
                    <div style={{height: "1px", width: "100%", backgroundColor: "#CCCCCC", marginBottom: "12px"}}/>
                    <TableContainer component={Paper} className={"provider-contacts-wrapper-table"}>
                        <Table size="small" style={{border: "1px solid #EFEFEF"}}>
                            <TableHead>
                                <TableRow className={"provider-contacts-wrapper-table-head"}>
                                    <TableCell style={{backgroundColor: "#FAFAFA"}}>Nom</TableCell>
                                    <TableCell style={{backgroundColor: "#FAFAFA"}}>Email</TableCell>
                                    <TableCell style={{backgroundColor: "#FAFAFA"}}>Fax</TableCell>
                                    <TableCell style={{backgroundColor: "#FAFAFA"}}>Téléphone</TableCell>
                                    <TableCell style={{backgroundColor: "#FAFAFA"}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contacts && contacts.length > 0 && contacts.map((contact) => (
                                    <TableRow
                                        key={contact.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>{contact.genre} {contact.prenom} {contact.nom}</TableCell>
                                        <TableCell>
                                            <a href={`mailto:${contact.email}`}>{contact.email}</a>
                                        </TableCell>
                                        <TableCell>{contact.fax}</TableCell>
                                        <TableCell>
                                            <a href={`tel:${contact.telephone}`}>{contact.telephone}</a>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <IconButton
                                                    size={"small"}
                                                    onClick={() => handleOpenEditCreateContactModal(contact)}
                                                    style={{}}>
                                                    <Icon color={"#555555"} style={{fontSize: 16}}>{"edit"}</Icon>
                                                </IconButton>
                                                <IconButton
                                                    size={"small"}
                                                    onClick={() => handleDeleteContact(contact.id)}
                                                    style={{}}>
                                                    <Icon color={"#555555"} style={{fontSize: 16}}>{"delete"}</Icon>
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            <div className={"provider-orders-wrapper"}>
                <Button variant={"contained"}
                        onClick={() => handleToggleCreateBCModalOpen()}
                        className={"provider-orders-wrapper-content-table-add-button"}
                        size={"small"}
                >Ajouter</Button>
                <TableContainer component={Paper} className={"provider-orders-wrapper-content-table"}>
                    <Table stickyHeader={true} style={{border: "1px solid #EFEFEF"}}>
                        <TableHead>
                            <TableRow>
                                {categories.map((category) => {
                                    return (
                                        <TableCell style={{backgroundColor: "#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.slice(startRange, endRange).map((order) => (
                                <TableRow
                                    key={order.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        <div
                                            className={'provider-orders-wrapper-content-table-operation-ref-wrapper'}>
                                            <div>
                                                {order.referenceRenova}
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{order.referenceExterne}</TableCell>
                                    <TableCell>{dayjs(order.dateEnAttente).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell>{order.datePaye ? dayjs(order.datePaye).format("DD/MM/YYYY") : "Non réglée"}</TableCell>
                                    <TableCell>{order.totalHT} €</TableCell>
                                    <TableCell className={"provider-orders-wrapper-content-table-actions-buttons"}>
                                        <Tooltip title={'Editer la commande'}>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => {
                                                    setMode("edit")
                                                    handleSelectOrder(order)
                                                }}
                                                style={{}}>
                                                <Icon color={"#444444"}>{"edit"}</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Editer le statut'}>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => {
                                                    setMode("editStatus")
                                                    handleSelectOrder(order)
                                                }}
                                                style={{}}>
                                                <Icon color={"#444444"}>{"autorenew"}</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Export PDF'}>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => handleClickOnPDF(order)}
                                                style={{}}>
                                                <Icon color={"#444444"}>{"picture_as_pdf"}</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Export Excel'}>
                                            <IconButton
                                                disabled={true}
                                                size={"small"}
                                                style={{}}>
                                                <Icon color={"#444444"}>{"insert_drive_file"}</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Supprimer la commande'}>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => handleDeleteOrder(order.id)}
                                                style={{}}>
                                                <Icon color={"#444444"}>{"delete"}</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={"provider-orders-wrapper-content-pagination"}>
                    <Pagination count={Math.ceil(orders.length / 10)} onChange={handlePageChange} page={pageNumber}/>
                </div>
            </div>
            {contactModalMode &&
            <ProvidersAddEditContact mode={contactModalMode} providerId={props.match.params.providerId} contact={selectedContact} handleClose={handleCloseContactModal}
                                     handleSetTriggerRefetch={handleGetProviderDetails}/>}
            {isDeleteConfirmationModalOpen &&
            <ProvidersDeleteConfirmationModal providerId={props.match.params.providerId} handleClose={handleToggleDeleteConfirmationModal}
                                              handleSetTriggerRefetch={props.history.goBack} isOpen={isDeleteConfirmationModalOpen}/>
            }
            {
                isCreateBCModalOpen &&
                <ProvidersCreateEditBC handleClose={handleToggleCreateBCModalOpen} isOpen={isCreateBCModalOpen} handleSetTriggerRefetch={handleGetProviderDetails}
                                       providerId={props.match.params.providerId} selectedOrder={selectedOrder}/>
            }
            {
                isEditStatusModalOpen &&
                <ProvidersEditStatusModal handleClose={handleToggleEditStatusModalOpen} isOpen={isEditStatusModalOpen} handleSetTriggerRefetch={handleGetProviderDetails}
                                          providerId={props.match.params.providerId} selectedOrder={selectedOrder}/>
            }
            {isCreateEditProviderModalOpen &&
            <ProvidersCreateEditProvider handleClose={handleToggleCreateEditProviderModalOpen} isOpen={isCreateEditProviderModalOpen}
                                         handleSetTriggerRefetch={handleGetProviderDetails} providerId={props.match.params.providerId} providerDetails={providerDetails}/>
            }
        </div>
    )

}

export default withRouter(ProviderDetail)