import React, {useState, useEffect} from "react"
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Icon,
    TextField,
    InputAdornment,
    ToggleButton,
    ToggleButtonGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination
} from '@mui/material'
import "../style/SuperSearchModal.scss"
import {getResults} from "../api/SuperSearchAPI"
import Loader from "../../../shared/loader/Loader"
import undraw_empty from '../../../shared/undraw/undraw_empty.svg'
import dayjs from 'dayjs'
import {withRouter} from "react-router-dom";

const SuperSearchModal = ({handleClose, isOpen, inputValue, handleTextFieldChange, history}) => {

    const [selectedCategory, setSelectedCategory] = useState("clients")
    const [searchResults, setSearchResults] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)

    const categories = [
        {
            key: "clients",
            name: "Clients"
        },
        {
            key: "fournisseurs",
            name: "Fournisseurs"
        },
        {
            key: "contacts",
            name: "Contacts"
        },
        // {
        //     key:"operations",
        //     name:"Opérations"
        // },
        // {
        //     key:"bills",
        //     name:"Factures"
        // },
        {
            key: "articles",
            name: "Articles"
        }
    ]

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    useEffect(() => {
        handleGetSearchResults()
            .then((res) => {
                setSearchResults(res)
            })
    }, [inputValue])

    useEffect(() => {
        setPageNumber(1)
    }, [selectedCategory])

    const handleChangeCategory = (e, cat) => {
        setSelectedCategory(cat)
    }

    const handleGetSearchResults = async () => {
        return await getResults({research: inputValue})
    }

    const redirect = (elementId) => {
        if (selectedCategory === "clients" || selectedCategory === "contacts") {
            history.push(`/clients/list/${elementId}`)
        }
        handleClose(false)
    }

    const renderTable = () => {
        const startRange = (pageNumber - 1) * 10
        const endRange = pageNumber * 10
        if (selectedCategory && !searchResults[selectedCategory].length) {
            return (
                <div className={'super-search-modal-wrapper-content-noresult'}>
                    <img src={undraw_empty} alt={"no result"}/>
                    <p>Aucun résultat</p>
                </div>
            )
        }
        if (selectedCategory === "operations") {
            return (
                <TableContainer component={Paper} className={'super-search-modal-wrapper-content-table'}>
                    <Table size="small" stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Référence</TableCell>
                                <TableCell>Référence client</TableCell>
                                <TableCell>Client</TableCell>
                                <TableCell>Date de création</TableCell>
                                <TableCell>Statut</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults["operations"].slice(startRange, endRange).sort((a, b) => dayjs.unix(b.dateCreation) - dayjs.unix(a.dateCreation)).map((operation) => (
                                <TableRow
                                    key={operation.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        <div className={'super-search-modal-wrapper-content-table-operation-ref-wrapper'}>
                                            {operation.numero}
                                        </div>

                                    </TableCell>
                                    <TableCell>{operation.numeroCommandeClient}</TableCell>
                                    <TableCell>{operation.nom}</TableCell>
                                    <TableCell>{dayjs.unix(operation.dateCreation).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell>{operation.statut}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        if (selectedCategory === "clients" || selectedCategory === "fournisseurs") {
            return (
                <TableContainer component={Paper} className={'super-search-modal-wrapper-content-table'}>
                    <Table size="small" stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom du {selectedCategory === "clients" ? "client" : "fournisseur"}</TableCell>
                                <TableCell>Ville</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults[selectedCategory === "clients" ? "clients" : "fournisseurs"].slice(startRange, endRange).sort((a, b) => a.nom - b.nom).map((item) => (
                                <TableRow
                                    style={{cursor: "pointer"}}
                                    key={item.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        <div onClick={() => redirect(item.id)}>
                                            {item.nom}
                                        </div>
                                    </TableCell>
                                    <TableCell>{item.ville}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        if (selectedCategory === "contacts") {
            return (
                <TableContainer component={Paper} className={'super-search-modal-wrapper-content-table'}>
                    <Table size="small" stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell>Société</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Téléphone</TableCell>
                                <TableCell>Fax</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults["contacts"].slice(startRange, endRange).sort((a, b) => a.nom - b.nom).map((contact) => (
                                <TableRow
                                    key={contact.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        <div>
                                            {contact.prenom} {contact.nom}
                                        </div>
                                    </TableCell>
                                    <TableCell style={{cursor: "pointer"}} onClick={() => redirect(contact.client_id)}>{contact.client_nom || "/"}</TableCell>
                                    <TableCell>
                                        {contact.email ?
                                            <a className="link-without-decoration" href={`mailto:${contact.email}`}>{contact.email}</a>
                                            :
                                            <span>/</span>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {contact.telephone ?
                                            <a className="link-without-decoration" href={`tel:${contact.telephone}`}>{contact.telephone}</a>
                                            :
                                            <span>/</span>
                                        }
                                    </TableCell>
                                    <TableCell>{contact.fax || "/"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        if (selectedCategory === "bills") {
            return (
                <TableContainer component={Paper} className={'super-search-modal-wrapper-content-table'}>
                    <Table size="small" stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Facture</TableCell>
                                <TableCell>Client</TableCell>
                                <TableCell>Date de création</TableCell>
                                <TableCell>Prix HT</TableCell>
                                <TableCell>Prix TTC</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults["bills"].slice(startRange, endRange).sort((a, b) => dayjs(b.date.date) - dayjs(a.date.date)).map((bill) => (
                                <TableRow
                                    key={bill.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        <div className={'super-search-modal-wrapper-content-table-operation-ref-wrapper'}>
                                            {bill.numero}
                                        </div>

                                    </TableCell>
                                    <TableCell>{bill.nom}</TableCell>
                                    <TableCell>{dayjs(bill.date.date).format("DD/MM/YYYY") || "/"}</TableCell>
                                    <TableCell>{bill.prixHT || "/"} €</TableCell>
                                    <TableCell>{bill.prixTTC || "/"} €</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        if (selectedCategory === "articles") {
            return (
                <TableContainer component={Paper} className={'super-search-modal-wrapper-content-table'}>
                    <Table size="small" stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Libellé</TableCell>
                                <TableCell>P.U. HT</TableCell>
                                <TableCell>Client</TableCell>
                                <TableCell>Matière</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults["articles"].slice(startRange, endRange).map((article) => (
                                <TableRow
                                    key={article.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        <div>
                                            {article.libelle}
                                        </div>

                                    </TableCell>
                                    <TableCell>{article.prixHT} €</TableCell>
                                    <TableCell>{article.client}</TableCell>
                                    <TableCell>{article.matiere}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
    }

    return (
        <Dialog fullWidth maxWidth="lg" className="super-search-modal-wrapper" onClose={() => handleClose(false)} open={isOpen}>
            <DialogTitle className={"super-search-modal-wrapper-header"}>
                <Icon>search</Icon>
                <div>Super recherche</div>
            </DialogTitle>
            <DialogContent className={"super-search-modal-wrapper-content"}>
                <TextField className={"super-search-modal-wrapper-content-textfield"}
                           autoFocus
                           InputProps={{
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <Icon>search</Icon>
                                   </InputAdornment>
                               ),
                           }}
                           value={inputValue} onChange={(e) => handleTextFieldChange(e.target.value)} size={"small"} placeholder={"Saisissez votre recherche"} variant="outlined"/>
                <ToggleButtonGroup
                    className="super-search-modal-wrapper-content-buttons"
                    fullWidth
                    value={selectedCategory}
                    exclusive
                    onChange={handleChangeCategory}
                    aria-label="text alignment"
                >
                    {categories.map((category) => {
                        return (
                            <ToggleButton key={category.key} value={category.key} className='super-search-modal-wrapper-content-buttons-button-wrapper'>
                                <div>{category.name}</div>
                                <div className='super-search-modal-wrapper-content-buttons-button-wrapper-count'>
                                    <span>{searchResults && searchResults[category.key].length}</span>
                                </div>
                            </ToggleButton>
                        )
                    })}
                </ToggleButtonGroup>
                {searchResults ?
                    <>
                        {renderTable()}
                        {selectedCategory && searchResults && !!searchResults[selectedCategory].length &&
                        <div className={"super-search-modal-wrapper-content-pagination"}>
                            <Pagination count={selectedCategory && searchResults && Math.ceil(searchResults[selectedCategory].length / 10)} onChange={handlePageChange}
                                        page={pageNumber}/>
                        </div>
                        }
                    </>
                    :
                    <div className={"super-search-modal-wrapper-content-loader-wrapper"}>
                        <Loader/>
                    </div>
                }
            </DialogContent>
            <DialogActions className={"super-search-modal-wrapper-footer"}>
                <Button variant="contained" onClick={() => handleClose(false)}>Fermer</Button>
            </DialogActions>
        </Dialog>
    )
}

export default withRouter(SuperSearchModal)