import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createNote} from "./api/ClientAPI";
import toast from "react-hot-toast"

const ClientsAddNote = ({clientId, isOpen, handleClose, handleSetTriggerRefetch}) => {

    const [content, setContent] = useState("")

    useEffect(() => {

    }, [])


    const handleSetContent = (value) => {
        setContent(value)
    }


    const handleCreateNote = async () => {
        const payload = {
            manuel: true,
            clientId: clientId,
            operationId: null,
            contenu: content,
        }
        try {
            await createNote(payload)
            handleClose()
            handleSetTriggerRefetch()
            toast.success("La note a été créée avec succès")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la création de la note")
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Création d'une note</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={content}
                        onChange={(e) => handleSetContent(e.target.value)}
                        variant="outlined"
                        label={"Contenu"}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleCreateNote()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ClientsAddNote