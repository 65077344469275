import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent, TextField, Box, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createEcriture} from "../api/OperationsAPI";
import toast from "react-hot-toast";

const OperationDetailsAddEcriture = ({operationId, ordre, isOpen, handleClose, triggerRefetch}) => {

    const [prixHT, setPrixHT] = useState("")
    const [libelle, setLibelle] = useState("")
    const [quantite, setQuantite] = useState("1")
    const [pourcentage, setPourcentage] = useState("")
    const [isPourcentage, setIsPourcentage] = useState(false)

    useEffect(() => {

    }, [])

    const handleSetLibelle = (value) => {
        setLibelle(value)
    }

    const handleSetPrixHT = (value) => {
        setPrixHT(value)
    }

    const handleSetQuantite = (value) => {
        setQuantite(value)
    }

    const handleSetPourcentage = (value) => {
        setPourcentage(value)
    }

    const handleSetIsNote = (value) => {
        setIsPourcentage(!isPourcentage)
    }

    const handleAddEcriture = async () => {
        let payload = {}
        if (isPourcentage) {
            payload = {
                operationId: operationId,
                quantite: null,
                ordre: ordre,
                libelle: libelle,
                avenantId: null,
                pourcentage: pourcentage,
                prixHT: null
            }

        } else {
            payload = {
                operationId: operationId,
                quantite: quantite,
                ordre: ordre,
                libelle: libelle,
                avenantId: null,
                pourcentage: null,
                prixHT: prixHT
            }
        }
        try {
            await createEcriture(payload)
            triggerRefetch()
            toast.success("L'article unique a été ajouté avec succès")
            handleClose(null)
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la création de l'article unique")
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Ajout d'une écriture</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <FormControlLabel control={<Checkbox onChange={() => handleSetIsNote()} value={isPourcentage}/>}
                                      label="Cette écriture applique t-elle un pourcentage ?"/>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={libelle}
                        onChange={(e) => handleSetLibelle(e.target.value)}
                        variant="outlined"
                        label={"Libellé de cette écriture"}
                        helperText={"Ne remplissez que le libellé pour ajouter une note aux documents"}
                    />
                    {
                        isPourcentage ?
                            <TextField
                                inputProps={{min: 0}}
                                type={'number'}
                                style={{width: "100%"}}
                                value={pourcentage}
                                onChange={(e) => handleSetPourcentage(e.target.value)}
                                variant="outlined"
                                label={"Pourcentage"}
                            />
                            :
                            <div style={{display: "flex", marginBottom: "12px"}}>
                                <TextField
                                    inputProps={{min: 0}}
                                    type={'number'}
                                    style={{width: "100%", marginRight: "5px"}}
                                    value={quantite}
                                    onChange={(e) => handleSetQuantite(e.target.value)}
                                    variant="outlined"
                                    label={"Quantité"}
                                />
                                <TextField
                                    inputProps={{min: 0}}
                                    type={'number'}
                                    value={prixHT}
                                    onChange={(e) => handleSetPrixHT(e.target.value)}
                                    variant="outlined"
                                    label={"Prix HT en €"}
                                />
                            </div>
                    }

                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleAddEcriture()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default OperationDetailsAddEcriture