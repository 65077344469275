import React, {useState} from "react"
import {Button, Icon, IconButton, Paper} from "@mui/material"
import dayjs from "dayjs";
import {withRouter} from "react-router-dom"
import {deleteNote} from "./api/ClientAPI";
import ClientsAddNote from "./ClientsAddNote";
import toast from "react-hot-toast"

const ClientNotes = ({
                         selectedClient,
                         notes,
                         handleSetTriggerRefetch,
                     }) => {

    const [isCreateNoteModalOpen, setIsCreateNoteModalOpen] = useState(false)

    const handleOpenCloseCreateNoteModal = () => {
        setIsCreateNoteModalOpen(!isCreateNoteModalOpen)
    }

    const handleDeleteNote = async (noteId) => {
        try {
            await deleteNote(noteId)
            handleSetTriggerRefetch()
            toast.success("La note a été supprimée avec succès")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la suppression de la note")
        }
    }

    return (
        <Paper className={'clients-notes-wrapper'}>
            <div className={"clients-notes-wrapper-title"}>
                <Icon style={{fontSize:18, color:"#1079FC", marginRight:10, marginLeft:4}}>comment</Icon>
                Notes
                <div style={{flex:1}}/>
                <Button variant={"outlined"} style={{color:"#1079FC", borderColor:"#1079FC"}}
                        size={"small"}
                        onClick={() => handleOpenCloseCreateNoteModal()}
                >Ajouter une note</Button>
            </div>
            <div style={{height: "1px", width: "100%", backgroundColor: "#CCCCCC", marginBottom: "12px"}}/>
            <div className={'clients-notes-wrapper-content noscrollbar'}>

                {notes && notes.length > 0 ?
                    notes.map((note) => {
                        return (
                            <div key={note.id} className={'clients-notes-wrapper-content-note'}>
                                <div className={'clients-notes-wrapper-content-note-header'} style={{justifyContent:"center"}}>
                                    <span style={{color:"#222222", fontSize:12}}>{dayjs(note.date).format("DD/MM/YYYY HH:mm")} - <span style={{fontStyle:"italic"}}>{note.auteur}</span></span>
                                    <div style={{flex: 1}}/>
                                    <IconButton
                                        size={'small'}
                                        onClick={() => handleDeleteNote(note.id)}
                                        style={{}}>
                                        <Icon color={"#555555"}>{"delete"}</Icon>
                                    </IconButton>
                                </div>
                                <div className={'clients-notes-wrapper-content-note-content'}>
                                    <Icon style={{color:"#797979", fontSize:16, marginRight:4}}>comment</Icon>
                                    {note.contenu}
                                </div>
                                <div style={{height: "1px", width: "100%", backgroundColor: "#CCCCCC"}}/>

                            </div>
                        )
                    })

                    :
                    <div className={'clients-client-details-wrapper-client-notes-wrapper-content-no-note-loader-wrapper'}>
                        <span>Aucune note à afficher</span>
                    </div>

                }
            </div>
            {isCreateNoteModalOpen && <ClientsAddNote clientId={selectedClient.id}  handleClose={handleOpenCloseCreateNoteModal} isOpen={isCreateNoteModalOpen}
                                                      handleSetTriggerRefetch={handleSetTriggerRefetch}/>}
        </Paper>
    )
}

export default withRouter(ClientNotes)
