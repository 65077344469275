import React, {createContext, useEffect, useState} from 'react';
import axios from "axios"
import {baseURL} from "../shared/utils/Utils";

export const UserContext = createContext();

export default function UserProvider({children}) {
    const [jwt, setJwt] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoadingUserContext, setIsLoadingUserContext] = useState(true);

    const checkJwt = async (jwt) => {
        await axios.get(baseURL() +'api/user/self', {
            headers: {
                "Authorization": "Bearer " + jwt
            }
        })
    }

    useEffect(async () => {
        setIsLoadingUserContext(true)
        if (localStorage.getItem("renova-chrome-jwt") && localStorage.getItem("renova-chrome-user")) {
            try {
                await checkJwt(localStorage.getItem("renova-chrome-jwt"))
                setJwt(localStorage.getItem("renova-chrome-jwt"))
                setUser(JSON.parse(localStorage.getItem("renova-chrome-user")))
                setIsLoadingUserContext(false)
            } catch (err) {
                console.log(err)
                setIsLoadingUserContext(false)
            }
        }
        setIsLoadingUserContext(false)
    }, []);

    const handleSetJwt = (jwt) => {
        if (jwt) {
            global.jwt = jwt
            setJwt(jwt)
            localStorage.setItem("renova-chrome-jwt", jwt)
        } else {
            global.jwt = null
            setJwt(null)
            localStorage.removeItem("renova-chrome-jwt")
        }
    }

    const handleSetUser = (user) => {
        if (user) {
            global.user = user
            setUser(user)
            localStorage.setItem("renova-chrome-user", JSON.stringify(user))
        } else {
            global.user = null
            setUser(null)
            localStorage.removeItem("renova-chrome-user")
        }
    }

    const setNewUser = (user, jwt) => {
        handleSetJwt(jwt)
        handleSetUser(user)
    }

    const removeUser = () => {
        handleSetJwt(null)
        handleSetUser(null)
    }

    return (
        <UserContext.Provider
            value={{jwt, user, setNewUser, removeUser, isLoadingUserContext, setIsLoadingUserContext}}>
            {children}
        </UserContext.Provider>
    );
}
