import React, {useState} from "react"
import {
    Pagination,
    Paper,
    Table,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import dayjs from 'dayjs'
import Loader from "../../../shared/loader/Loader";
import {numberWithCommas} from "../../../shared/utils/Utils";
import ProductsAddArticleModal from "./ProductsAddArticleModal";
import {Link} from "react-router-dom";

const ProductsAllProductsList = ({
                                     handleFilterInputValueChange,
                                     filterInputValue,
                                     data,
    triggerRefetch
                                 }) => {

    const [pageNumber, setPageNumber] = useState(1)
    const [selectedArticleIdForDisplayFinish, setSelectedArticleIdForDisplayFinish] = useState(null)
    // const [selectedArticleToEdit, setSelectedArticleToEdit] = useState(null)
    // const [modalMode, setModalMode] = useState(null)
    const [isCreateArticleModalOpen, setIsCreateArticleModalOpen] = useState(false)

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const categories = [
        {
            title: "Libellé",
            key: ""
        },
        {
            title: "Loi de longueur",
            key: ""
        },
        {
            title: "Loi d'épaisseur",
            key: ""
        },
        {
            title: "Loi de développé",
            key: ""
        },
        {
            title: "Finitions disponibles",
            key: ""
        }
    ]

    const handleOpenEditCreateArticleModal = (article) => {
        setIsCreateArticleModalOpen(true)
        // if(article){
        //     setModalMode("edit")
        //     setSelectedArticleToEdit(selectedArticleToEdit)
        // } else {
        //     setModalMode("create")
        //     setSelectedArticleToEdit({article_libelle:"", finish:[], loi_developpe_libelle:"", loi_epaisseur_libelle:"", loi_longueur_libelle:""})
        // }
    }

    const handleCloseModal = () => {
        setIsCreateArticleModalOpen(false)
        // setModalMode(null)
        // setSelectedArticleToEdit(null)
    }

    const handleDisplayFinishDetails = (articleId) => {
        if (articleId === selectedArticleIdForDisplayFinish) {
            setSelectedArticleIdForDisplayFinish(null)
        } else setSelectedArticleIdForDisplayFinish(articleId)
    }

    const startRange = (pageNumber - 1) * 10
    const endRange = pageNumber * 10

    return (
        <div className={"products-general-all-products-wrapper"}>
            <>
                <div className={"products-general-all-products-wrapper-header"}>
                    <div className={"products-general-all-products-wrapper-header-title"}>Liste des {data.length} {data.length > 1 ? "articles" : "article"} présents en
                        base
                    </div>
                    <div style={{flex:1}}/>
                    <div className={"products-general-all-products-wrapper-header-filter-sort"}>
                        <Button variant={"contained"} style={{backgroundColor:"#1079FC", color:"#FFFFFF"}} disabled
                                onClick={() => handleOpenEditCreateArticleModal(null)}>Ajouter un article</Button>
                        <TextField className={"products-general-all-products-wrapper-header-filter-input"} size="small"
                                   variant="outlined" onChange={handleFilterInputValueChange}
                                   label={"Rechercher"}
                                   value={filterInputValue}/>
                    </div>

                </div>
                <div className={"products-general-all-products-wrapper-content"}>
                    <TableContainer component={Paper} className={"products-general-all-products-wrapper-content-table"}>
                        <Table size={"small"} style={{border:"1px solid #EFEFEF"}}>
                            <TableHead>
                                <TableRow>
                                    {categories.map((category) => {
                                        return (
                                            <TableCell style={{backgroundColor:"#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.slice(startRange, endRange).map((article) => (
                                    <TableRow
                                        key={article.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell style={{width: "300px"}}>
                                            <Link to={`/articles/article/${article.article_id}`}>
                                                <span className='link-without-decoration'>{article.article_libelle}</span>
                                            </Link>

                                        </TableCell>
                                        <TableCell style={{width: "150px"}}>{article.loi_longueur_libelle}</TableCell>
                                        <TableCell style={{width: "150px"}}>{article.loi_epaisseur_libelle}</TableCell>
                                        <TableCell style={{width: "150px"}}>{article.loi_developpe_libelle}</TableCell>
                                        <TableCell style={{width: "200px"}}>
                                            <Button size={'small'} variant={"outlined"} color={'primary'} onClick={() => handleDisplayFinishDetails(article.article_id)}>
                                                {article.article_id === selectedArticleIdForDisplayFinish ? "Masquer les matières" : "Afficher les matières"}
                                            </Button>
                                            {
                                                article.article_id === selectedArticleIdForDisplayFinish &&
                                                article.finish.sort((a, b) => a.matiere_libelle.localeCompare(b.matiere_libelle)).map((finish, i) => {
                                                    return (
                                                        <div key={i}>
                                                            {finish.matiere_libelle}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={"products-general-all-products-wrapper-content-pagination"}>
                        <Pagination count={Math.ceil(data.length / 10)} onChange={handlePageChange} page={pageNumber}/>
                    </div>
                    {isCreateArticleModalOpen && <ProductsAddArticleModal isOpen={isCreateArticleModalOpen} handleClose={handleCloseModal} triggerRefetch={triggerRefetch}/>}
                </div>
            </>


        </div>
    )
}

export default ProductsAllProductsList
