import React, {useState, useEffect} from "react"
import "./OperationsProductions.scss"
import OperationsProductionList from "./OperationsProductionList";
import {getOrdersInProduction} from "../api/OperationsAPI";
import {filterData, matchOperationsWithFinish} from "../../../shared/utils/Utils";

const OperationsProduction = () => {
    const [sortColumn, setSortColumn] = useState("id")
    const [filterInputValue, setFilterInputValue] = useState("")
    const [sortOption, setSortOption] = useState("increasing")
    const [initialData, setInitialData] = useState(null)
    const [data, setData] = useState(null)
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const [orderIdHovered, setOrderIdHovered] = useState(null)
    const [triggerRefresh, setTriggerRefresh] = useState(false)

    // TODO for now the orders amounts are string, we need to receive or transforms them as numbers

    useEffect(() => {
        handleGetOrdersInProduction()
            .then((res) => {
                const operationsWithFinish = matchOperationsWithFinish(res)
                setInitialData(operationsWithFinish.operations)
                setData(operationsWithFinish.operations)
            })
    }, [triggerRefresh])

    useEffect(() => {
        if (initialData) {
            const filteredData = filterData(initialData, filterInputValue)
            setData(filteredData)
        }
    }, [filterInputValue])

    useEffect(() => {
        if (data) {
            let sortedData = data.slice()
            if (typeof data[0][sortColumn] === "number" || sortColumn === "prixHTPlusDepollution") {
                if (sortOption === "increasing") {
                    sortedData = sortedData.sort((a, b) => a[sortColumn] - b[sortColumn])
                } else {
                    sortedData = sortedData.sort((a, b) => b[sortColumn] - a[sortColumn])
                }
            } else if (typeof data[0][sortColumn] === "string") {
                if (sortOption === "increasing") {
                    sortedData = sortedData.sort((a, b) =>
                        a[sortColumn].localeCompare(b[sortColumn]))
                } else {
                    sortedData = sortedData.sort((a, b) => b[sortColumn].localeCompare(a[sortColumn]))
                }
            }
            setData(sortedData)
        }
    }, [sortColumn, sortOption])

    const handleSetTriggerRefresh = () => {
        setTriggerRefresh(!triggerRefresh)
    }

    const handleGetOrdersInProduction = async () => {
        return await getOrdersInProduction()
    }

    const handleSortCatChange = (e) => {
        setSortColumn(e.target.value)
    }

    const handleFilterInputValueChange = (e) => {
        setFilterInputValue(e.target.value)
    }

    const handleSortOptionChange = () => {
        if (sortOption === "increasing") {
            setSortOption("decreasing")
        } else {
            setSortOption("increasing")
        }
    }

    const handleToggleModalState = (event, orderId) => {
        if (event) {
            setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget)
            setOrderIdHovered(orderId ? orderId : null)
        } else {
            setPopperAnchorEl(null)
            setOrderIdHovered(null)
        }
    }

    const categories = [
        {
            title: "Ref.",
            key: "id"
        },
        {
            title: "Actions",
            key: "actions"
        },
        {
            title: "Référence client",
            key: "numeroCommandeclient"
        },
        {
            title: "Client",
            key: "nom"
        },
        {
            title: "Finitions",
            key: "finish"
        },
        {
            title: "Date de production",
            key: "dateProduction"
        },
        {
            title: "Date de livraison prévisionnelle",
            key: "dateRendu"
        },
        {
            title: "Total HT",
            key: "prixHTPlusDepollution"
        }
    ]

    return (
        <OperationsProductionList sortColumn={sortColumn} handleSortCatChange={handleSortCatChange}
                                  triggerRefresh={handleSetTriggerRefresh}
                                  categories={categories} handleSortOptionChange={handleSortOptionChange}
                                  sortOption={sortOption} handleFilterInputValueChange={handleFilterInputValueChange}
                                  filterInputValue={filterInputValue} data={data}
                                  popperAnchorEl={popperAnchorEl} toggleModal={handleToggleModalState}
                                  orderIdHovered={orderIdHovered}/>
    )
}

export default OperationsProduction

