import React, {useState, useEffect} from "react"
import {Paper, Icon} from "@mui/material"
import "../style/MonthlyTurnover.scss"
import {getMonthlyTurnover} from "../../stats/api/StatsAPI";
import Loader from "../../../shared/loader/Loader";
import undraw_empty from '../../../shared/undraw/undraw_empty.svg'
import { Line } from 'react-chartjs-2';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat"
import 'dayjs/locale/fr'
dayjs.extend(customParseFormat)

const MonthlyTurnover = () => {

    const [turnover, setTurnover] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        handleGetTurnover()
    }, [])

    const handleGetTurnover = async () => {
        const payload = {
            start: dayjs().subtract(12, "month").format("YYYY-MM-DDT00:00:00+00:00"),
            end: dayjs().format("YYYY-MM-DDT00:00:00+00:00"),
        }
        const turnover = await getMonthlyTurnover(payload)
        setTurnover(turnover)
        setIsLoading(false)
    }

    const renderContent = () => {
        if((!isLoading && !turnover) || (!isLoading && turnover && !turnover.length)){
            return(
                <div className={'home-turnover-wrapper-content-no-turnover-loader-wrapper'}>
                    <img src={undraw_empty} alt={"no data available"}/>
                    <span>Pas de donnée à afficher</span>
                </div>
            )
        }
        if(turnover && !isLoading){
            const data = {
                labels: turnover[0].values.map((month) => dayjs(month.month, 'DD/MM/YYYY').locale('fr').format("MMM YY")),
                datasets: [
                    {
                        label: turnover[0].key,
                        data: turnover[0].values.map((month) => month.ca),
                        fill: false,
                        backgroundColor: '#1E2129',
                        borderColor: '#1E2129',
                    },
                    {
                        label: turnover[1].key,
                        data: turnover[1].values.map((month) => month.ca),
                        fill: false,
                        backgroundColor: '#a1a7b7',
                        borderColor: '#a1a7b7',
                    },
                    {
                        label: turnover[2].key,
                        data: turnover[0].values.map((month) => turnover[2].values[0].ca),
                        fill: false,
                        backgroundColor: '#647dc1',
                        borderColor: '#647dc1',
                    },
                ],
            };

            const options = {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || '';
                                return label = label + " : " + context.parsed.y + " €";
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            callback: function(value, index, values) {
                                return value + " €";
                            }
                        },
                        beginAtZero: false
                    }
                }
            }
            return(
               <Line data={data} options={options} className={'home-turnover-wrapper-content'}/>
            )
        }
        if(isLoading){
            return (
                <div className={'home-turnover-wrapper-content-no-turnover-loader-wrapper'}>
                    <Loader/>
                </div>
            )
        }
    }

    return (
        <Paper elevation={8} className={'home-turnover-wrapper'}>
            <div className={'home-turnover-wrapper-header'}>
                <Icon>bar_chart</Icon>
                <div>Chiffre d'affaires mensuel</div>
            </div>
            <div className={'home-turnover-wrapper-content'}>
                {renderContent()}
            </div>
        </Paper>
    )

}

export default MonthlyTurnover