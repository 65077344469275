import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Checkbox, DialogContent, FormControlLabel, TextField, Box} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createSpecificCondition, editSpecificCondition} from "../api/ProductsAPI";
import toast from "react-hot-toast"

const ProductsAddSpecificConditionModal = ({isOpen, handleClose, triggerRefetch, modalMode, selectedCondition}) => {

    const [libelle, setLibelle] = useState(selectedCondition.libelle)
    const [pourcentage, setPourcentage] = useState(selectedCondition.pourcentage)
    const [textWarning, setTextWarning] = useState(selectedCondition.text_warning)


    const handleSetLibelle = (value) => {
        setLibelle(value)
    }

    const handleSetPourcentage = (value) => {
        setPourcentage(value)
    }

    const handleSetTextWarning = (value) => {
        setTextWarning(value)
    }


    const handleCreateSpecificCondition = async () => {
        const payload = {
            libelle: libelle,
            texteWarning: textWarning,
            pourcentage: pourcentage
        }
        try {
            if(modalMode === "create"){
                await createSpecificCondition(payload)
                triggerRefetch()
                toast.success("La condition spécifique a été ajoutée avec succès")
                handleClose(null)
            } else if(modalMode === "edit"){
                await editSpecificCondition(payload, selectedCondition.id)
                toast.success("La condition spécifique a été modifiée avec succès")
                triggerRefetch()

                handleClose(null)
            }

        } catch (err) {
            toast.error("Erreur durant l'opération'")
            console.log(err)
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>{modalMode === "create" ? "Création " : "Modification "}d'une condition spécifique</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={libelle}
                        onChange={(e) => handleSetLibelle(e.target.value)}
                        variant="outlined"
                        label={"Libellé"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={textWarning}
                        onChange={(e) => handleSetTextWarning(e.target.value)}
                        variant="outlined"
                        label={"Message d'alerte"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={pourcentage}
                        onChange={(e) => handleSetPourcentage(e.target.value)}
                        variant="outlined"
                        label={"Pourcentage à appliquer"}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleCreateSpecificCondition()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProductsAddSpecificConditionModal