import React, {useState, useEffect} from "react"
import {
    Icon,
    IconButton,
    Button,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Checkbox,
   Tooltip
} from "@mui/material";
import dayjs from 'dayjs'
import Loader from "../../shared/loader/Loader";
import OperationDetailsModal from "../operations/OperationDetailsModal"
import {filterData, groupArticles} from "../../shared/utils/Utils";
import './style/Clients.scss'
import {getOperationDetail, downloadOperationPDF, changeOperationStatus} from "../operations/api/OperationsAPI";
import ClientsInvoiceModal from "./ClientsInvoiceModal";
import toast from "react-hot-toast";
import {withRouter} from "react-router-dom";

const ClientDeliveries = ({
                              operations,
                              handleTriggerRefetch,
                              clientId,
                              history
                          }) => {


    const [data, setData] = useState(operations)
    const [initialData, setInitialData] = useState(operations)
    const [filterInputValue, setFilterInputValue] = useState("")
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const [orderIdHovered, setOrderIdHovered] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [operationsIdsToInvoice, setOperationsIdsToInvoice] = useState([])
    const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false)

    useEffect(() => {
        setInitialData(operations)
        setData(operations)
    }, [operations])

    useEffect(() => {
        if (initialData) {
            const filteredData = filterData(initialData, filterInputValue)
            setData(filteredData)
        }
    }, [filterInputValue])

    const handleFilterInputValueChange = (e) => {
        setFilterInputValue(e.target.value)
    }

    const handleOpenCloseModal = () => {
        setIsInvoiceModalOpen(!isInvoiceModalOpen)
    }

    const handleClickOnProforma = async (operation) => {
        const operationData = await getOperationDetail(operation.id)
        if (operationData && operationData.operation && operationData.operation[0]) {
            const groupedOperationData = groupArticles(operationData)
            downloadOperationPDF(groupedOperationData, "proforma")
        }
    }

    const handleClickOnBackToProduction = async (operation) => {
        try {
            await changeOperationStatus({statut: "commande"}, operation.id)
            handleTriggerRefetch()
            toast.success("Le statut de l'opération a été mis à jour")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la mise à jour du statut de l'opération")
        }
    }

    const handleToggleModalState = (event, orderId) => {
        if (event) {
            setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget)
            setOrderIdHovered(orderId ? orderId : null)
        } else {
            setPopperAnchorEl(null)
            setOrderIdHovered(null)
        }
    }

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const handleAddOperationToInvoice = (operationId) => {
        const operationsIds = operationsIdsToInvoice.slice()
        if (operationsIds.indexOf(operationId) !== -1) {
            const filteredOperationsIds = operationsIds.filter((op) => op !== operationId)
            setOperationsIdsToInvoice(filteredOperationsIds)
            return
        }
        operationsIds.push(operationId)
        setOperationsIdsToInvoice(operationsIds)
    }

    const startRange = (pageNumber - 1) * 10
    const endRange = pageNumber * 10


    const categories = [
        {
            title: "Ref.",
            key: "internalRef"
        },
        {
            title: "Référence client",
            key: "customerRef"
        },
        {
            title: "Finitions",
            key: "finish"
        },
        {
            title: "Date de création",
            key: "createdAt"
        },
        {
            title: "Date fin prévisionnelle",
            key: "prevEndDate"
        },
        {
            title: "Total HT",
            key: "amount"
        },
        {
            title: "TVA",
            key: "tva"
        },
        {
            title: "Dépollution",
            key: "depollution"
        },
        {
            title: "Actions",
            key: "actions"
        },
        {
            title: "Facturer",
            key: "invoice"
        }
    ]

    return (
        <Paper className={"client-operations-wrapper"}>
            {data ?
                (
                    <div className={"client-operations-wrapper-wrapper"}>
                        <div className={"client-operations-wrapper-header"} style={{height: "auto"}}>
                            <div className={"client-operations-wrapper-header-filter-sort"}>
                                {
                                    operationsIdsToInvoice && operationsIdsToInvoice.length > 0 &&
                                    <Button variant={'contained'} size={"small"} style={{marginRight: "10px"}}
                                            onClick={() => handleOpenCloseModal()}
                                    >{`Facturer (${operationsIdsToInvoice.length})`}
                                    </Button>
                                }

                                <TextField className={"client-operations-wrapper-header-filter-input"} size="small"
                                           variant="outlined" onChange={handleFilterInputValueChange}
                                           label={"Recherche"}
                                           value={filterInputValue}/>
                            </div>

                        </div>
                        <div className={"client-operations-wrapper-content"}>
                            <TableContainer component={Paper} className={"client-operations-wrapper-content-table"}>
                                <Table size={"small"} style={{border:"1px solid #EFEFEF"}} stickyHeader={true}>
                                    <TableHead>
                                        <TableRow>
                                            {categories.map((category) => {
                                                return (
                                                    <TableCell style={{backgroundColor:"#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice(startRange, endRange).map((operation) => (
                                            <TableRow
                                                key={operation.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>
                                                    <div
                                                        className={'client-operations-wrapper-content-table-operation-ref-wrapper'}>
                                                        <div onMouseEnter={(e) => handleToggleModalState(e, operation.numero)}
                                                             onMouseLeave={() => handleToggleModalState()}
                                                             onClick={() => history.push(`/operations/operation/detail/${operation.id}`)}>
                                                            {operation.numero}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{width: "300px"}}>{operation.numeroCommandeClient}</TableCell>
                                                <TableCell style={{width: "300px"}}>
                                                    <div>
                                                        {operation.matieres.map((fin) => {
                                                            return (
                                                                <div style={{display: "flex", alignItems: "center"}}>
                                                                    <Icon>
                                                                        format_paint
                                                                    </Icon>
                                                                    <span>{fin}</span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </TableCell>
                                                <TableCell>{dayjs(operation.dateCreation).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{dayjs(operation.dateRendu).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{operation.prixHT} €</TableCell>
                                                <TableCell>
                                                    <Checkbox checked={operation.operation_tva === "1"}/>
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox checked={operation.operation_depollution === "1"}/>
                                                </TableCell>
                                                <TableCell className={"client-operations-wrapper-content-table-actions"}>
                                                    <Tooltip title={"Retour commande"}>
                                                        <IconButton onClick={() => handleClickOnBackToProduction(operation)}>
                                                            <Icon color={"primary"}>{"restore"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Proforma"}>
                                                        <IconButton onClick={() => handleClickOnProforma(operation)}>
                                                            <Icon color={"primary"}>{"assignment"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox checked={operationsIdsToInvoice.indexOf(operation.id) !== -1} onChange={() => handleAddOperationToInvoice(operation.id)}/>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={"client-operations-wrapper-content-pagination"}>
                                <Pagination count={data && Math.ceil(data.length / 10)} onChange={handlePageChange} page={pageNumber}/>
                            </div>
                        </div>
                    </div>
                )
                :
                <div className={"client-operations-wrapper-content-loader-wrapper"}>
                    <Loader/>
                </div>
            }
            {popperAnchorEl &&
            <OperationDetailsModal popperAnchorEl={popperAnchorEl} toggleModal={handleToggleModalState} orderId={orderIdHovered}/>}
            {isInvoiceModalOpen &&
            <ClientsInvoiceModal isOpen={isInvoiceModalOpen} clientId={clientId} operationsIdsToInvoice={operationsIdsToInvoice} handleClose={handleOpenCloseModal}
                                 handleSetTriggerRefetch={handleTriggerRefetch}/>}
        </Paper>
    )
}

export default withRouter(ClientDeliveries)
