import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent, TextField, Box, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createArticleUnique, createArticleUniqueCommande} from "../api/OperationsAPI";
import toast from "react-hot-toast";

const OperationDetailsCreateAndAddUniqueArticle = ({operationId, ordre, isOpen, handleClose, triggerRefetch, matieresCatalogue, clientId}) => {

    const [selectedFinish, setSelectedFinish] = useState(null)
    const [isAvailableForEverybody, setIsAvailableForEverybody] = useState(false)
    const [prixHT, setPrixHT] = useState("")
    const [libelle, setLibelle] = useState("")
    const [quantite, setQuantite] = useState(1)
    const [matieresCat, setMatieresCat] = useState(matieresCatalogue)

    useEffect(() => {

    }, [])

    const handleSetLibelle = (value) => {
        setLibelle(value)
    }

    const handleSetPrixHT = (value) => {
        setPrixHT(value)
    }

    const handleSetQuantite = (value) => {
        setQuantite(value)
    }

    const handleSelectFinish = (e) => {
        const selectedFinish = matieresCatalogue.find((article) => article.id === e.target.value)
        setSelectedFinish(selectedFinish)
    }

    const handleSetIsAvailableForEverybody = () => {
        setIsAvailableForEverybody(!isAvailableForEverybody)
    }

    const handleAddUniqueArticle = async () => {
        const articleUniquePayload = {
            libelle: libelle,
            clientId: clientId,
            prixHT: prixHT,
            disponiblePourTous: isAvailableForEverybody,
            invalide: false,
            matiereId: selectedFinish.id,
        }
        try {
            const articleUniqueId = await createArticleUnique(articleUniquePayload)
            const articleUniqueCommandePayload = {
                libelle: libelle,
                quantite: quantite,
                prixHT: prixHT,
                operationId: operationId,
                articleUniqueId: articleUniqueId,
                ordre:ordre,
            }
            await createArticleUniqueCommande(articleUniqueCommandePayload)
            triggerRefetch()
            toast.success("L'article unique a été ajouté avec succès")
            handleClose(null)
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la création de l'article unique")
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Création et ajout d'un article unique</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={libelle}
                        onChange={(e) => handleSetLibelle(e.target.value)}
                        variant="outlined"
                        label={"Libellé"}
                    />
                    <FormControl sx={{minWidth: 350}}>
                        <InputLabel>Choisissez une finition</InputLabel>
                        <Select
                            style={{marginBottom: "12px"}}
                            label={'Finitions disponibles'}
                            className={""}
                            onChange={handleSelectFinish}
                        >
                            {matieresCat && matieresCat.map((article) => {
                                return (
                                    <MenuItem key={article.id} value={article.id}
                                    >{article.libelle}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <div style={{display: "flex", marginBottom: "12px"}}>
                        <TextField
                            inputProps={{min: 0}}
                            type={'number'}
                            style={{width: "100%", marginRight: "5px"}}
                            value={quantite}
                            onChange={(e) => handleSetQuantite(e.target.value)}
                            variant="outlined"
                            label={"Quantité"}
                        />
                        <TextField
                            value={prixHT}
                            onChange={(e) => handleSetPrixHT(e.target.value)}
                            variant="outlined"
                            label={"Prix HT en €"}
                        />
                    </div>
                    <FormControlLabel control={<Checkbox onChange={() => handleSetIsAvailableForEverybody()} value={isAvailableForEverybody}/>}
                                      label="Rendre cet article unique disponible pour tous les clients ?"/>

                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleAddUniqueArticle()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default OperationDetailsCreateAndAddUniqueArticle