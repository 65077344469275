import React, {useEffect, useState} from "react"
import './style/Clients.scss'
import {getAllClients} from "../operations/api/OperationsAPI";
import {deleteContact, getClientNotes, getClientOperations, getClientHistory} from "./api/ClientAPI";
import ClientQuotes from "./ClientQuotes";
import ClientProductions from "./ClientProductions"
import ClientDeliveries from "./ClientDeliveries";
import ClientArchives from "./ClientArchives";
import ClientBills from "./ClientBills";
import ClientsMenu from "./ClientsMenu"
import ClientDetails from "./ClientsDetails"
import ClientContacts from "./ClientsContacts";
import ClientsNotes from "./ClientsNotes";
import ClientAvoirs from "./ClientAvoirs"
import ClientHistory from "./ClientHistory"
import {withRouter} from "react-router-dom";
import OperationDetailsEditModal from "../operations/operationDetails/OperationDetailsEditModal"
import ClientsAddEditClient from "./ClientsAddEditClient";
import ClientPayed from "./ClientPayed"
import undraw_empty from '../../shared/undraw/undraw_empty.svg'
import {matchOperationsWithFinish, removeDuplicateItems, matchEcrituresAndAvoirs} from "../../shared/utils/Utils";
import dayjs from "dayjs";

const Clients = (props) => {
    const [selectedClient, setSelectedClient] = useState(null)
    const [triggerRefetch, setTriggerRefetch] = useState(false)
    const [tabNumber, setTabNumber] = useState(0);
    const [allClients, setAllClients] = useState(null)
    const [notes, setNotes] = useState(null)
    const [clientModalMode, setClientModalMode] = useState(null)
    const [selectedClientToEdit, setSelectedClientToEdit] = useState(null)
    const [isNewOperationModalOpen, setIsNewOperationModalOpen] = useState(false)
    const [operationsQuotes, setOperationsQuotes] = useState(null)
    const [operationsCommande, setOperationsCommande] = useState(null)
    const [operationsDelivery, setOperationsDelivery] = useState(null)
    const [operationsInvoiced, setOperationsInvoiced] = useState(null)
    const [operationsPayed, setOperationsPayed] = useState(null)
    const [operationsArchived, setOperationsArchived] = useState(null)
    const [operationsCounts, setOperationsCounts] = useState(null)
    const [operationsAvoirs, setOperationsAvoirs] = useState(null)
    const [clientHistory, setClientHistory] = useState(null)
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)

    useEffect(() => {
        handleGetAllClients()
    }, [triggerRefetch])

    useEffect(() => {
        const clientIdOnUrl = props.match.params.clientId
        if (allClients && allClients.length > 0) {
            if (clientIdOnUrl) {
                setSelectedClient(allClients.find((client) => client.id === clientIdOnUrl))
            } else {
                setSelectedClient(null)
            }
        }
    }, [allClients, props.match.params.clientId])

    useEffect(() => {
        if (selectedClient) {
            handleGetClientOperations(selectedClient.id)
        }
    }, [selectedClient])

    useEffect(async () => {
        if (selectedClient) {
            const notes = await getClientNotes(selectedClient.id)
            setNotes(notes)
        }
    }, [selectedClient])

    const handleGetClientOperations = async (clientId) => {
        const clientOperations = await getClientOperations(clientId)
        const clientHistory = await getClientHistory(clientId)
        const operationsWithFinish = matchOperationsWithFinish(clientOperations)
        handleSetOperationsByType(operationsWithFinish.operations, operationsWithFinish.avenants, operationsWithFinish.ecritures, clientHistory)
    }

    const groupOperationsByBill = (operations) => {
        const result = removeDuplicateItems(operations, 'facture_id').map((item) => ({
            facture_id: item.facture_id,
            factureNumber: dayjs(item.dateCreation).format("YYYYMM") + item.facture_numero,
            dateFacture: item.dateFacture,
            clientName: item.client_nom,
            operations: []
        }))
        for (let bill of result) {
            for (let operation of operations) {
                if (bill.facture_id === operation.facture_id) {
                    bill.operations.push(operation)
                }
            }
            bill.prixHT = bill.operations.map((op) => parseFloat(op.prixHT)).reduce((prev, acc) => prev + acc)
            bill.prixHTPlusDepollution = bill.operations.map((op) => parseFloat(op.prixHTPlusDepollution)).reduce((prev, acc) => prev + acc)
            bill.prixTTC = bill.operations.map((op) => parseFloat(op.prixTTC)).reduce((prev, acc) => prev + acc)
            bill.customer = selectedClient
            bill.datePaiement = bill.operations && bill.operations.length > 0 && bill.operations[0].facture_date_paiement
        }
        return result
    }


    const handleSetOperationsByType = (operations, avenants, ecritures, clientHistory) => {
        let quotes = []
        let commandes = []
        let delivery = []
        let invoiced = []
        let payed = []
        let archived = []
        const sortedOperations = operations.sort((a, b) => dayjs(b.dateCreation) - dayjs(a.dateCreation))
        for (let operation of sortedOperations) {
            if (operation.statut === "devis" && operation.client_id === selectedClient.id) {
                quotes.push(operation)
            }
            if (operation.statut === "commande" && operation.client_id === selectedClient.id) {
                commandes.push(operation)
            }
            if (operation.statut === "livraison" && operation.client_id === selectedClient.id) {
                delivery.push(operation)
            }
            if (operation.facture_id !== null && operation.statut === "facture" && !operation.facture_date_paiement && operation.client_id === selectedClient.id) {
                invoiced.push(operation)
            }
            if (operation.facture_date_paiement && operation.client_id === selectedClient.id) {
                payed.push(operation)
            }
            if (operation.statut === "archive" && !operation.facture_date_paiement && operation.client_id === selectedClient.id) {
                archived.push(operation)
            }
        }
        const groupedPayedOperations = groupOperationsByBill(payed)
        const groupedInvoicedOperations = groupOperationsByBill(invoiced)
        const avoirsWithEcritures = matchEcrituresAndAvoirs(avenants, ecritures)
        clientHistory.sort((a, b) => dayjs(b.date) - dayjs(a.date))
        setClientHistory(clientHistory)
        setOperationsAvoirs(avoirsWithEcritures)
        setOperationsQuotes(quotes)
        setOperationsCommande(commandes)
        setOperationsDelivery(delivery)
        setOperationsInvoiced(groupedInvoicedOperations)
        setOperationsPayed(groupedPayedOperations)
        setOperationsArchived(archived)
        setOperationsCounts([quotes.length, commandes.length, delivery.length, invoiced.length, groupedPayedOperations.length, archived.length, avoirsWithEcritures.length, clientHistory.length])
    }

    const handleOpenEditCreateClientModal = (client) => {
        if (client) {
            setClientModalMode("edit")
            setSelectedClientToEdit(client)
        } else {
            setClientModalMode("create")
            setSelectedClientToEdit({
                entreprise: "1",
                prenom: "",
                nom: "",
                adresse: "",
                email: "",
                telephone: "",
                code_postal: "",
                ville: "",
                fax: "",
                siret: "",
                depollution: true,
                TVA: true
            })
        }
    }

    const handleCloseModal = () => {
        setClientModalMode(null)
        setSelectedClientToEdit(null)
    }

    const handleSetSelectedClient = (client) => {
        if (client) {
            props.history.push(`/clients/list/${client.id}`)
        } else {
            props.history.push(`/clients/list/`)
        }
        handleSetTabNumber(0)
    }

    const handleSetTriggerRefetch = () => {
        setTriggerRefetch(!triggerRefetch)
    }

    const handleSetTabNumber = (tabNumber) => {
        setTabNumber(tabNumber)
    }

    const handleDeleteContact = async (contactId) => {
        await deleteContact(contactId)
        handleSetTriggerRefetch()
    }

    const handleGetAllClients = async () => {
        const clients = await getAllClients()
        for (let client of clients.clients) {
            client.contacts = []
            client.label = client.nom
            for (let contact of clients.contacts) {
                if (contact.client_id === client.id) {
                    client.contacts.push(contact)
                }
            }
        }
        setAllClients(clients.clients)
    }

    const handleOpenCloseNewOperationModal = () => {
        setIsNewOperationModalOpen(!isNewOperationModalOpen)
    }

    const handleToggleEmailModal = () => {
        setIsEmailModalOpen(!isEmailModalOpen)
    }

    return (
        <div className={"clients-wrapper"}>
            <ClientsMenu handleOpenEditCreateClientModal={handleOpenEditCreateClientModal} handleCloseModal={handleCloseModal} modalMode={clientModalMode} allClients={allClients}
                         handleSetSelectedClient={handleSetSelectedClient} selectedClient={selectedClient} handleSetTabNumber={handleSetTabNumber}
                         tabNumber={tabNumber} selectedClientToEdit={selectedClientToEdit} handleSetTriggerRefetch={handleSetTriggerRefetch}
                         handleOpenCloseNewOperationModal={handleOpenCloseNewOperationModal} operationsCount={operationsCounts}
            />
            <div className={"clients-wrapper-content noscrollbar"}>
                {selectedClient && tabNumber === 0 &&
                <>
                    <ClientDetails handleOpenEditCreateClientModal={handleOpenEditCreateClientModal} handleCloseModal={handleCloseModal}
                                   selectedClientToEdit={selectedClientToEdit} modalMode={clientModalMode}
                                   selectedClient={selectedClient} handleSetTriggerRefetch={handleSetTriggerRefetch}
                                   isEmailModalOpen={isEmailModalOpen} handleToggleEmailModal={handleToggleEmailModal}
                                   operationsQuotes={operationsQuotes} operationsCommande={operationsCommande} operationsDelivery={operationsDelivery}
                                   operationsInvoiced={operationsInvoiced}
                    />
                    <ClientContacts handleDeleteContact={handleDeleteContact} selectedClient={selectedClient} handleSetTriggerRefetch={handleSetTriggerRefetch}/>
                    <ClientsNotes notes={notes} selectedClient={selectedClient} handleSetTriggerRefetch={handleSetTriggerRefetch}/>
                </>
                }
                {selectedClient && tabNumber === 1 &&
                <ClientQuotes operations={operationsQuotes}
                              handleTriggerRefetch={handleSetTriggerRefetch}/>
                }
                {selectedClient && tabNumber === 2 &&
                <ClientProductions
                    operations={operationsCommande}
                    handleTriggerRefetch={handleSetTriggerRefetch}/>
                }
                {selectedClient && tabNumber === 3 &&
                <ClientDeliveries clientId={selectedClient.id}
                                  operations={operationsDelivery}
                                  handleTriggerRefetch={handleSetTriggerRefetch}/>
                }
                {selectedClient && tabNumber === 4 &&
                <ClientBills selectedClient={selectedClient} clientId={selectedClient.id}
                             operations={operationsInvoiced}
                             handleTriggerRefetch={handleSetTriggerRefetch}/>
                }
                {selectedClient && tabNumber === 5 &&
                <ClientPayed selectedClient={selectedClient} clientId={selectedClient.id}
                             operations={operationsPayed}
                             handleTriggerRefetch={handleSetTriggerRefetch}/>
                }
                {selectedClient && tabNumber === 6 &&
                <ClientArchives clientId={selectedClient.id}
                                operations={operationsArchived}
                                handleTriggerRefetch={handleSetTriggerRefetch}/>
                }
                {selectedClient && tabNumber === 7 &&
                <ClientAvoirs clientId={selectedClient.id}
                              selectedClient={selectedClient}
                              operations={operationsAvoirs}
                              handleTriggerRefetch={handleSetTriggerRefetch}/>
                }
                {selectedClient && tabNumber === 8 &&
                <ClientHistory clientId={selectedClient.id}
                               selectedClient={selectedClient}
                               operations={clientHistory}
                               handleTriggerRefetch={handleSetTriggerRefetch}/>
                }
                {
                    !selectedClient &&
                    <div className={"clients-wrapper-content-no-content"}>
                        <img src={undraw_empty} alt={"no data available"}/>
                        <div>Sélectionnez un client afin d'obtenir les éléments le concernant</div>
                    </div>
                }
            </div>
            {isNewOperationModalOpen &&
            <OperationDetailsEditModal modalMode={"create"} isOpen={isNewOperationModalOpen} handleClose={handleOpenCloseNewOperationModal} triggerRefresh={handleSetTriggerRefetch}
                                       clientId={selectedClient.id}/>}
            {clientModalMode &&
            <ClientsAddEditClient mode={clientModalMode} clientId={clientModalMode === "edit" && selectedClient.id} client={selectedClientToEdit} handleClose={handleCloseModal}
                                  handleSetTriggerRefetch={handleSetTriggerRefetch}/>}
        </div>
    )
}

export default withRouter(Clients)