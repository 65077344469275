import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Alert, Checkbox, DialogContent, FormControlLabel, Icon, IconButton, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createDevelopedRule, editDevelopedRule, editDimension, createDimension} from "./api/PricingRulesAPI";
import toast from 'react-hot-toast';
import {Line} from "react-chartjs-2";


const PricingRulesNewEditDevelopedRuleModal = ({rule, mode, handleClose, triggerRefetch, setTriggerRefetch}) => {

    const [libelle, setLibelle] = useState(rule.libelle)
    const [dimensions, setDimensions] = useState(rule.dimensions)
    const [formulaCalculation, setFormulaCalculation] = useState(rule.calcul)
    const [formulaRepresentation, setFormulaRepresentation] = useState(rule.representation)


    const handleSetLibelle = (value) => {
        setLibelle(value)
    }

    const handleSetFormulaCalculation = (value) => {
        setFormulaCalculation(value)
    }

    const handleSetFormulaRepresentation = (value) => {
        setFormulaRepresentation(value)
    }

    const handleEditDimension = (key, i, value) => {
        const dim = dimensions.slice()
        dim[i][key] = value
        setDimensions(dim)
    }

    const handleFilterDimensions = (indexToRemove) => {
        const dim = dimensions.slice()
        delete dim[indexToRemove]
        setDimensions(dim)
    }

    const handleAddDimension = () => {
        const dim = dimensions.slice()
        dim.push({libelle: "", unite: "", diminutif: ""})
        setDimensions(dim)
    }

    const handleUpdateAddRule = async () => {
        const payload = {
            libelle: libelle,
            calcul: formulaCalculation,
            representation: formulaRepresentation
        }
        if (mode === "create") {
            try {
                payload.dimensions = dimensions
                await createDevelopedRule(payload)
                handleClose()
                setTriggerRefetch(!triggerRefetch)
                toast.success("La loi de développé a été créée avec succès")
            } catch (err) {
                console.log(err)
                if (err.response.data.catched_exception) {
                    toast.error(err.response.data.catched_exception)
                } else {
                    toast.error("Erreur durant la création de la loi de développé")
                }

            }
        } else if (mode === "edit") {
            try {
                await editDevelopedRule(payload, rule.id)
                for (let dimension of dimensions) {
                    const dimensionPayload = {
                        libelle: dimension.libelle,
                        diminutif: dimension.diminutif,
                        unite: dimension.unite,
                        loiDeveloppeId: rule.id
                    }
                    await editDimension(dimensionPayload, dimension.id)
                }
                toast.success("La loi de développé a été modifiée avec succès")
                handleClose()
                setTriggerRefetch(!triggerRefetch)
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la modification de la loi de développé")
            }
        }
    }

    return (
        <Dialog open={mode} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>{mode === "create" ? "Création d'une loi de développé" : "Modification d'une loi de développé"}</DialogTitle>
            <DialogContent>
                <div className={"pricing-rules-developed-rules-modal-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={libelle}
                        onChange={(e) => handleSetLibelle(e.target.value)}
                        variant="outlined"
                        label={"Libellé"}
                    />
                    <Alert severity="info" style={{marginBottom: "12px"}}>
                        <div>Les variables doivent être placées entre crochets et correspondre aux diminutifs des dimensions.</div>
                        <div>Les opérateurs suivants sont acceptés : * / + - et la formule doit être homogène à des mm.</div>
                        <div>Exemple : [c1] * [c2] où c1 et c2 correspondent à des diminutifs de dimensions existantes.</div>
                    </Alert>
                    <TextField
                        style={{marginBottom: "12px"}}
                        variant="outlined"
                        onChange={(e) => handleSetFormulaCalculation(e.target.value)}
                        label={"Formule de calcul"}
                        value={formulaCalculation}
                    />
                    <Alert severity="info" style={{marginBottom: "12px"}}>
                     Cette formule sera affichée sur les documents
                    </Alert>
                    <TextField
                        style={{marginBottom: "12px"}}
                        variant="outlined"
                        onChange={(e) => handleSetFormulaRepresentation(e.target.value)}
                        label={"Formule de représentation"}
                        value={formulaRepresentation}
                    />
                    <div className={"pricing-rules-developed-rules-modal-fields-wrapper-dimensions"}>
                        <div className={"pricing-rules-developed-rules-modal-fields-wrapper-dimensions-header"}>
                            <span>Dimensions :</span>
                            <Button variant={"contained"} onClick={() => handleAddDimension()}>Ajouter une dimension</Button>
                        </div>


                        {dimensions.map((dim, i) => {
                            return (
                                <div className={"pricing-rules-developed-rules-modal-fields-wrapper-dimension"}>
                                    <TextField
                                        className={"pricing-rules-developed-rules-modal-fields-wrapper-dimension-libelle"}
                                        variant="outlined"
                                        onChange={(e) => handleEditDimension('libelle', i, e.target.value)}
                                        label={"Libellé de la dimension"}
                                        value={dimensions[i].libelle}
                                    />
                                    <TextField
                                        className={"pricing-rules-developed-rules-modal-fields-wrapper-dimension-diminutif"}
                                        variant="outlined"
                                        onChange={(e) => handleEditDimension('diminutif', i, e.target.value)}
                                        label={"Diminutif"}
                                        value={dimensions[i].diminutif}
                                    />
                                    <TextField
                                        className={"pricing-rules-developed-rules-modal-fields-wrapper-dimension-unit"}
                                        variant="outlined"
                                        onChange={(e) => handleEditDimension('unite', i, e.target.value)}
                                        label={"Unité"}
                                        value={dimensions[i].unite}
                                    />
                                    <IconButton
                                        onClick={() => handleFilterDimensions(i)}
                                    >
                                        <Icon color={"primary"}>{"delete"}</Icon>
                                    </IconButton>
                                </div>

                            )
                        })}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(null)}>Annuler</Button>
                <Button onClick={() => handleUpdateAddRule()}>
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PricingRulesNewEditDevelopedRuleModal
