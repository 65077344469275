import React, {useState, useEffect} from "react"
import "./ProductsUniqueProductsCatalogue.scss"
import {getARangeOfUniqueArticles, searchInUniqueArticles, getUniqueArticlesWithPictures, deleteUniqueArticle} from "../api/ProductsAPI";
import Loader from "../../../shared/loader/Loader";
import ProductsUniqueProductsList from "./ProductsUniqueProductsList";

const ProductsUniqueProductsCatalogue = () => {
    const [articlesFilterInputValue, setArticlesFilterInputValue] = useState("")
    const [initialAllArticles, setInitialAllArticles] = useState(null)
    const [allArticles, setAllArticles] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [count, setCount] = useState(null)
    const [getArticlesWithPicOnly, setGetArticlesWithPicOnly] = useState(false)
    // this boolean let the data refresh on deleting an article
    const [triggerRefetch, setTriggerRefetch] = useState(true)

    useEffect(async () => {
        if (getArticlesWithPicOnly || articlesFilterInputValue) {
            const startRange = (pageNumber - 1) * 10
            const endRange = pageNumber * 10
            setAllArticles(initialAllArticles.slice(startRange, endRange))
        } else {
            await handleGetAllUniqueArticles()
        }
    }, [pageNumber])

    useEffect(async () => {
        if(articlesFilterInputValue.length === 0 && !getArticlesWithPicOnly){
            await handleGetAllUniqueArticles()
            return
        }
        if(getArticlesWithPicOnly){
            setPageNumber(1)
            getUniqueArticlesWithPictures(articlesFilterInputValue)
                        .then((res) => {
                            const startRange = (pageNumber - 1) * 10
                            const endRange = pageNumber * 10
                            setInitialAllArticles(res)
                            setAllArticles(res.slice(startRange, endRange))
                            setCount(res.length)
                        })
            return
        }
        searchInUniqueArticles(articlesFilterInputValue)
                    .then((res) => {
                        const startRange = (pageNumber - 1) * 10
                        const endRange = pageNumber * 10
                        setInitialAllArticles(res)
                        setAllArticles(res.slice(startRange, endRange))
                        setCount(res.length)
                        setPageNumber(1)
                    })
    }, [articlesFilterInputValue, getArticlesWithPicOnly, triggerRefetch])

    const handleGetAllUniqueArticles = async () => {
        const offset = (pageNumber - 1) * 10
        const res = await getARangeOfUniqueArticles(10, offset)
        setInitialAllArticles(res.articles)
        setAllArticles(res.articles)
        setCount(res.count[0].count_total)
    }

    const handleArticlesFilterInputValueChange = (e) => {
        setArticlesFilterInputValue(e.target.value)
    }

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const handleDeleteArticle = async (articleId) => {
        await deleteUniqueArticle(articleId)
        setTriggerRefetch(!triggerRefetch)
    }

    return (
        allArticles ?

            <ProductsUniqueProductsList data={allArticles} handleFilterInputValueChange={handleArticlesFilterInputValueChange}
                                            filterInputValue={articlesFilterInputValue} handlePageChange={handlePageChange} pageNumber={pageNumber} count={count}
                                            getArticlesWithPicOnly={getArticlesWithPicOnly} setGetArticlesWithPicOnly={setGetArticlesWithPicOnly} handleDeleteArticle={handleDeleteArticle} triggerRefetch={triggerRefetch} setTriggerRefetch={setTriggerRefetch}/>

            :
            <div style={{display: "flex", width: "100%", height: "100vh", justifyContent: "center", alignItems: 'center'}}>
                <Loader/>
            </div>

    )
}

export default ProductsUniqueProductsCatalogue

