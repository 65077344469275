import React, {useState} from "react"
import {
    Icon,
    IconButton,
    Button,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import dayjs from 'dayjs'
import Loader from "../../../shared/loader/Loader";
import {groupArticles} from "../../../shared/utils/Utils";
import {getOperationDetail} from "../api/OperationsAPI";
import {downloadOperationPDF, registerPayment} from "../api/OperationsAPI";
import OperationDetailsModal from "../OperationDetailsModal"
import './OperationsBill.scss'
import toast, {Toaster} from 'react-hot-toast';
import {withRouter, Link} from "react-router-dom";
import OperationBillsRegisterPaymentModal from "./OperationBillsRegisterPaymentModal";

const OperationsBillList = ({
                                sortColumn,
                                handleSortCatChange,
                                categories,
                                handleSortOptionChange,
                                sortOption,
                                handleFilterInputValueChange,
                                filterInputValue,
                                data,
                                popperAnchorEl,
                                toggleModal,
                                orderIdHovered,
                                history,
                                triggerRefetch
                            }) => {

    const [pageNumber, setPageNumber] = useState(1)
    const [isRegisterPaymentModalOpen, setIsRegisterPaymentModalOpen] = useState(false)
    const [selectedFactureId, setSelectedFactureId] = useState(null)

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const handleOpenCloseRegisterPaymentModal = (factureId) => {
        if (isRegisterPaymentModalOpen) {
            setIsRegisterPaymentModalOpen(false)
            setSelectedFactureId(null)
        } else {
            setIsRegisterPaymentModalOpen(true)
            setSelectedFactureId(factureId)
        }
    }

    const startRange = (pageNumber - 1) * 10
    const endRange = pageNumber * 10

    const handleClickOnBasicBill = async (bill) => {
        const operationsDetails = []
        for (let operation of bill.operations) {
            operationsDetails.push(await getOperationDetail(operation.id))
        }
        bill.customer = {
            ville: operationsDetails[0].operation[0].ville,
            client_nom: operationsDetails[0].operation[0].client_nom,
            adresse: operationsDetails[0].operation[0].adresse,
            codePostal: operationsDetails[0].operation[0].codePostal,
        }
        bill.operations = operationsDetails.map((op) => op.operation[0])
        bill.prixHT = bill.operations.map((op) => parseFloat(op.prixHT)).reduce((prev, acc) => prev + acc)
        bill.prixHTPlusDepollution = bill.operations.map((op) => parseFloat(op.prixHTPlusDepollution)).reduce((prev, acc) => prev + acc)
        bill.prixTTC = bill.operations.map((op) => parseFloat(op.prixTTC)).reduce((prev, acc) => prev + acc)
        downloadOperationPDF(bill, "basicBill")
    }

    const handleClickOnDetailedBill = async (bill) => {
        console.log(bill)
        const operationsDetails = []
        for (let operation of bill.operations) {
            const operationData = await getOperationDetail(operation.id)
            if (operationData && operationData.operation && operationData.operation[0]) {
                const groupedOperationData = groupArticles(operationData)
                operationsDetails.push(groupedOperationData)
            }
        }
        bill.customer = {
            ville: operationsDetails[0].operation.ville,
            client_nom: operationsDetails[0].operation.client_nom,
            adresse: operationsDetails[0].operation.adresse,
            codePostal: operationsDetails[0].operation.codePostal,
        }
        bill.operationsDetails = operationsDetails
        downloadOperationPDF(bill, "detailedBill")
    }

    return (
        <Paper elevation={8} className={"client-operations-wrapper"}>
            {data ?
                (
                    <div className={"client-operations-wrapper-wrapper"}>
                        <div className={"client-operations-wrapper-header"} style={{height: "auto"}}>
                            <div className={"client-operations-wrapper-header-filter-sort"}>
                                <TextField className={"client-operations-wrapper-header-filter-input"} size="small"
                                           variant="outlined" onChange={handleFilterInputValueChange}
                                           value={filterInputValue}/>
                            </div>

                        </div>
                        <div className={"client-operations-wrapper-content"}>
                            <TableContainer component={Paper} className={"client-operations-wrapper-content-table"}>
                                <Table stickyHeader={true} style={{border: "1px solid #EFEFEF"}}>
                                    <TableHead>
                                        <TableRow>
                                            {categories.map((category) => {
                                                return (
                                                    <TableCell style={{backgroundColor: "#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice(startRange, endRange).map((bill) => (
                                            <TableRow
                                                key={bill.facture_id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell style={{width: "150px"}}>
                                                    <div

                                                        className={'client-operations-wrapper-content-table-operation-ref-wrapper'}>
                                                        {bill.factureNumber}
                                                    </div>
                                                </TableCell>
                                                <TableCell>{dayjs(bill.dateCreation).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{parseInt(bill.prixHTPlusDepollution).toFixed(2)} €</TableCell>
                                                <TableCell>
                                                    <div style={{display: "flex", width: "200px", flexWrap: "wrap"}}>
                                                        {bill.operations.map((operation) => {
                                                            return (
                                                                <div
                                                                    onMouseEnter={(e) => toggleModal(e, operation.id)}
                                                                    onMouseLeave={() => toggleModal()}
                                                                    key={operation.id}
                                                                    onClick={() => history.push(`/operations/operation/detail/${operation.id}`)}
                                                                    style={{margin: "2px"}}
                                                                    className={'client-operations-wrapper-content-table-operation-ref-wrapper'}
                                                                >
                                                                    {operation.id}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                </TableCell>
                                                <TableCell>
                                                    <Link className={"link-without-decoration"} to={`/clients/list/${bill.operations[0].client_id}`}>
                                                        {bill.clientName}
                                                    </Link>

                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title={'Edition facture simple'}>
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={() => handleClickOnBasicBill(bill)}
                                                            style={{}}>
                                                            <Icon color={"#444444"}>{"insert_drive_file"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={'Edition facture détaillée'}>
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={() => handleClickOnDetailedBill(bill)}
                                                            style={{}}>
                                                            <Icon color={"#444444"}>{"description"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={'Enregistrer le paiement'}>
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={() => handleOpenCloseRegisterPaymentModal(bill.facture_id)}
                                                            style={{}}>
                                                            <Icon color={"#444444"}>{"check"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={"client-operations-wrapper-content-pagination"}>
                                <Pagination count={Math.ceil(data.length / 5)} onChange={handlePageChange} page={pageNumber}/>
                            </div>
                        </div>
                    </div>
                )
                :
                <div className={"client-operations-wrapper-content-loader-wrapper"}>
                    <Loader/>
                </div>
            }
            {popperAnchorEl &&
            <OperationDetailsModal popperAnchorEl={popperAnchorEl} toggleModal={toggleModal} orderId={orderIdHovered}/>}
            {isRegisterPaymentModalOpen &&
            <OperationBillsRegisterPaymentModal isOpen={isRegisterPaymentModalOpen} factureId={selectedFactureId} handleClose={handleOpenCloseRegisterPaymentModal}
                                                triggerRefetch={triggerRefetch}/>}
        </Paper>
    )
}

export default withRouter(OperationsBillList)
