import {APIget} from '../../../services/API'

export const getHomeStatistics = () => {
    return new Promise((resolve, reject) => {
        APIget(`api/operation/welcome/stats`)
            .then((stats) => {
                resolve(stats)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getNotes = () => {
    return new Promise((resolve, reject) => {
        APIget(`api/welcome/notes`)
            .then((notes) => {
                resolve(notes)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getTurnoverOrderedByFinishGroup = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/groupe/finition/stats/ca`)
            .then((stats) => {
                resolve(stats)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getTurnoverInvoicedByFinishGroup = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/groupe/finition/stats/ca/trimestriel`)
            .then((stats) => {
                resolve(stats)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getMainCustomersByTurnover = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/groupe/finition/stats/ca/trimestriel/group/client`)
            .then((stats) => {
                resolve(stats)
            })
            .catch((error) => {
                reject(error)
            })
    })
}