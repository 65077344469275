import {Button, Checkbox, FormControlLabel, Switch} from "@mui/material";

export function SelectOperationContent() {


    return <div>

        <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: 10, padding: 10, border: "1px solid #2F2F2F", flexDirection: "row"}}>
            <div>Opération n°<strong>33432</strong> <i>VLD</i></div>
        </div>
        <FormControlLabel
            style={{margin: 10}}
            control={
                <Switch color="primary" />
            }
            label="Tout sélectionner"
        />
        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", marginBottom: 10}}>
            <div><Checkbox /></div>
            <div><strong>x3</strong> Coulisse 20x20x20 Lg1749 <br/><strong>Nickel Brillant</strong></div>
        </div>
        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", marginBottom: 10}}>
            <div><Checkbox disabled/></div>
            <div><strong>x1</strong> Coulisse 20x20x20 Lg1749 <br/><strong>Nickel Brillant</strong> <br/> <small>Ce produit est encore en activité <strong>polissage</strong></small></div>
        </div>
        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", marginBottom: 10}}>
            <div><Checkbox/></div>
            <div><strong>x2</strong> Plaque laiton 445x402mm Ep5mm  <br/><strong>Dorure Satiné Vernis PU</strong></div>
        </div>

        <div style={{margin: 20}}>
            <Button variant={"contained"} fullWidth={1} >Démarrer le traîtement</Button>
        </div>

    </div>
}
