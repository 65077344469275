import React, {useState, useEffect} from "react"
import {
    Icon,
    IconButton,
    Button,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import dayjs from 'dayjs'
import Loader from "../../shared/loader/Loader";
import OperationDetailsModal from "../operations/OperationDetailsModal"
import {filterData} from "../../shared/utils/Utils";
import './style/Clients.scss'
import {getOperationDetail, downloadOperationPDF, changeOperationStatus} from "../operations/api/OperationsAPI";
import {groupArticles} from "../../shared/utils/Utils";
import toast from "react-hot-toast";
import {withRouter} from "react-router-dom"

const ClientProductions = ({
                               operations,
                               handleTriggerRefetch,
                               history
                           }) => {


    const [data, setData] = useState(operations)
    const [initialData, setInitialData] = useState(operations)
    const [filterInputValue, setFilterInputValue] = useState("")
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const [orderIdHovered, setOrderIdHovered] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)

    useEffect(() => {
        setInitialData(operations)
        setData(operations)
    }, [operations])

    useEffect(() => {
        if (initialData) {
            const filteredData = filterData(initialData, filterInputValue)
            setData(filteredData)

        }
    }, [filterInputValue])

    const handleFilterInputValueChange = (e) => {
        setFilterInputValue(e.target.value)
    }

    const handleClickOnProforma = async (operation) => {
        const operationData = await getOperationDetail(operation.id)
        if (operationData && operationData.operation && operationData.operation[0]) {
            const groupedOperationData = groupArticles(operationData)
            downloadOperationPDF(groupedOperationData, "proforma")
        }
    }

    const handleClickOnDelivery = async (operation) => {
        const operationData = await getOperationDetail(operation.id)
        try {
            await changeOperationStatus({statut: "livraison"}, operation.id)
            handleTriggerRefetch()
            toast.success("Le statut de l'opération a été mis à jour")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la mise à jour du statut de l'opération")
        }
        if (operationData && operationData.operation && operationData.operation[0]) {
            const groupedOperationData = groupArticles(operationData)
            downloadOperationPDF(groupedOperationData, "deliveryReceipt")
        }
    }

    const handleClickOnBackToQuote = async (operation) => {
        try {
            await changeOperationStatus({statut: "devis"}, operation.id)
            handleTriggerRefetch()
            toast.success("Le statut de l'opération a été mis à jour")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la mise à jour du statut de l'opération")
        }

    }

    const handleToggleModalState = (event, orderId) => {
        if (event) {
            setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget)
            setOrderIdHovered(orderId ? orderId : null)
        } else {
            setPopperAnchorEl(null)
            setOrderIdHovered(null)
        }
    }

    const handleCreateOperationsInProductionPDF = () => {
        if(initialData){
            downloadOperationPDF(initialData, "operationsInProductionListForAClient")
        }
    }

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const startRange = (pageNumber - 1) * 10
    const endRange = pageNumber * 10


    const categories = [
        {
            title: "Ref.",
            key: "internalRef"
        },
        {
            title: "Référence client",
            key: "customerRef"
        },
        {
            title: "Finitions",
            key: "finish"
        },
        {
            title: "Date de création",
            key: "createdAt"
        },
        {
            title: "Date fin prévisionnelle",
            key: "prevEndDate"
        },
        {
            title: "Total HT",
            key: "amount"
        },
        {
            title: "",
            key: "actions"
        }
    ]

    return (
        <Paper className={"client-operations-wrapper"}>
            {data ?
                (
                    <div className={"client-operations-wrapper-wrapper"}>
                        <div className={"client-operations-wrapper-header"} style={{height: "auto"}}>
                            <div className={"client-operations-wrapper-header-filter-sort"}>
                                <Button variant={"outlined"} style={{marginRight:"10px", borderColor:"#1079FC", color:"#1079FC"}}
                                        onClick={() => handleCreateOperationsInProductionPDF()}
                                >Exporter la liste</Button>
                                <TextField className={"client-operations-wrapper-header-filter-input"} size="small"
                                           label={"Recherche"}
                                           variant="outlined" onChange={handleFilterInputValueChange}
                                           value={filterInputValue}/>
                            </div>

                        </div>
                        <div className={"client-operations-wrapper-content"}>
                            <TableContainer component={Paper} className={"client-operations-wrapper-content-table"}>
                                <Table size={"small"} style={{border:"1px solid #EFEFEF"}} stickyHeader={true}>
                                    <TableHead>
                                        <TableRow>
                                            {categories.map((category) => {
                                                return (
                                                    <TableCell style={{backgroundColor:"#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice(startRange, endRange).map((operation) => (
                                            <TableRow
                                                key={operation.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>
                                                    <div
                                                        className={'client-operations-wrapper-content-table-operation-ref-wrapper'}>
                                                        <div
                                                            onMouseEnter={(e) => handleToggleModalState(e, operation.numero)}
                                                            onMouseLeave={() => handleToggleModalState()}
                                                            onClick={() => history.push(`/operations/operation/detail/${operation.id}`)}
                                                        >
                                                            {operation.numero}
                                                        </div>

                                                    </div>

                                                </TableCell>
                                                <TableCell>{operation.numeroCommandeClient}</TableCell>
                                                <TableCell style={{width: "300px"}}>
                                                    <div>
                                                        {operation.matieres.map((fin) => {
                                                            return (
                                                                <div style={{display: "flex", alignItems: "center"}}>
                                                                    <Icon>
                                                                        format_paint
                                                                    </Icon>
                                                                    <span>{fin}</span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>


                                                </TableCell>
                                                <TableCell>{dayjs(operation.dateCreation).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{dayjs(operation.dateRendu).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{operation.prixHT} €</TableCell>
                                                <TableCell>
                                                    <Tooltip title={"Livrer + BL"}>
                                                        <IconButton onClick={() => handleClickOnDelivery(operation)}>
                                                            <Icon color={"primary"}>{"local_shipping"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Retour devis"}>
                                                        <IconButton onClick={() => handleClickOnBackToQuote(operation)}>
                                                            <Icon color={"primary"}>{"content_paste"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Proforma"}>
                                                        <IconButton onClick={() => handleClickOnProforma(operation)}>
                                                            <Icon color={"primary"}>{"assignment"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={"client-operations-wrapper-content-pagination"}>
                                <Pagination count={data && Math.ceil(data.length / 10)} onChange={handlePageChange}
                                            page={pageNumber}/>
                            </div>
                        </div>
                    </div>
                )
                :
                <div className={"client-operations-wrapper-content-loader-wrapper"}>
                    <Loader/>
                </div>
            }
            {popperAnchorEl &&
            <OperationDetailsModal popperAnchorEl={popperAnchorEl} toggleModal={handleToggleModalState}
                                   orderId={orderIdHovered}/>}
        </Paper>
    )
}

export default withRouter(ClientProductions)
