import React, {useState, useEffect} from "react"
import "./ProductsGeneralCatalogue.scss"
import {getAllArticles, getAllFinish, getAllSpecificConditions} from "../api/ProductsAPI";
import {filterData} from "../../../shared/utils/Utils";
import ProductsAllProductsList from "./ProductsAllProductsList";
import {removeDuplicateItems} from "../../../shared/utils/Utils";
import ProductsAllFinishList from "./ProductsAllFinishList";
import ProductsAllSpecificConditionsList from "./ProductsAllSpecificConditionsList";
import Loader from "../../../shared/loader/Loader";

const ProductsGeneralCatalogue = () => {
    const [articlesFilterInputValue, setArticlesFilterInputValue] = useState("")
    const [initialAllArticles, setInitialAllArticles] = useState(null)
    const [allArticles, setAllArticles] = useState(null)
    const [finishFilterInputValue, setFinishFilterInputValue] = useState("")
    const [initialAllFinish, setInitialAllFinish] = useState(null)
    const [allFinish, setAllFinish] = useState(null)
    const [specificConditionsFilterInputValue, setSpecificConditionsInputValue] = useState("")
    const [initialAllSpecificConditions, setInitialAllSpecificConditions] = useState(null)
    const [allSpecificConditions, setAllSpecificConditions] = useState(null)
    const [triggerRefetch, setTriggerRefetch] = useState(false)

    useEffect(() => {
        handleGetAllArticles()
            .then((res) => {
                const matchedData = manageArticlesData(res)
                setInitialAllArticles(matchedData.articles)
                setAllArticles(matchedData.articles)
            })
        handleGetAllFinish()
            .then((res) => {
                const finish = manageFinishData(res)
                setInitialAllFinish(finish)
                setAllFinish(finish)
            })
        handleGetAllSpecificConditions()
            .then((res) => {
                setInitialAllSpecificConditions(res)
                setAllSpecificConditions(res)
            })
    }, [triggerRefetch])

    useEffect(() => {
        if (allArticles) {
            const filteredData = filterData(initialAllArticles, articlesFilterInputValue)
            setAllArticles(filteredData)
        }
    }, [articlesFilterInputValue])

    useEffect(() => {
        if (allFinish) {
            const filteredData = filterData(initialAllFinish, finishFilterInputValue)
            setAllFinish(filteredData)
        }
    }, [finishFilterInputValue])

    useEffect(() => {
        if (allSpecificConditions) {
            const filteredData = filterData(initialAllSpecificConditions, specificConditionsFilterInputValue)
            setAllSpecificConditions(filteredData)
        }
    }, [specificConditionsFilterInputValue])

    const manageArticlesData = (data) => {
        for (let article of data.articles) {
            article.finish = []
            for (let finish of data.finitions) {
                if (article.article_id === finish.article_id) {
                    article.finish.push(finish)
                }
            }
        }
        return data
    }

    const manageFinishData = (data) => {
        const finalFinish = removeDuplicateItems(data, "matiere_id").map((finish) => {
            return (
                {
                    "matiere_libelle": finish.matiere_libelle,
                    "matiere_id": finish.matiere_id,
                    "petit_developpe": [],
                    "grand_developpe": [],
                    "surface": []
                }
            )
        })
        for (let finish of finalFinish) {
            for (let f of data) {
                if (f.matiere_id === finish.matiere_id) {
                    if (f.plage_de_validite_libelle === "Petit développé") {
                        finish.petit_developpe.push(f)
                    }
                    if (f.plage_de_validite_libelle === "Grand développé") {
                        finish.grand_developpe.push(f)
                    }
                    if (f.plage_de_validite_libelle === "A la surface") {
                        finish.surface.push(f)
                    }
                }
            }
        }
        return finalFinish
    }

    const handleSetTriggerRefetch = () => {
        setTriggerRefetch(!triggerRefetch)
    }

    const handleGetAllArticles = async () => {
        return await getAllArticles()
    }

    const handleGetAllFinish = async () => {
        return await getAllFinish()
    }

    const handleGetAllSpecificConditions = async () => {
        return await getAllSpecificConditions()
    }

    const handleArticlesFilterInputValueChange = (e) => {
        setArticlesFilterInputValue(e.target.value)
    }

    const handleFinishFilterInputValueChange = (e) => {
        setFinishFilterInputValue(e.target.value)
    }

    const handleSpecificConditionsFilterInputValueChange = (e) => {
        setSpecificConditionsInputValue(e.target.value)
    }

    return (
        allArticles && allFinish && allSpecificConditions ?
            <div>
                <ProductsAllProductsList data={allArticles} handleFilterInputValueChange={handleArticlesFilterInputValueChange}
                                         filterInputValue={articlesFilterInputValue} triggerRefetch={handleSetTriggerRefetch}/>
                <ProductsAllFinishList data={allFinish} handleFilterInputValueChange={handleFinishFilterInputValueChange}
                                       filterInputValue={finishFilterInputValue} triggerRefetch={handleSetTriggerRefetch}/>
                <ProductsAllSpecificConditionsList data={allSpecificConditions} handleFilterInputValueChange={handleSpecificConditionsFilterInputValueChange}
                                                   filterInputValue={specificConditionsFilterInputValue} triggerRefetch={handleSetTriggerRefetch}/>
            </div>
            :
            <div style={{display: "flex", width: "100%", height: "100vh", justifyContent: "center", alignItems: 'center'}}>
                <Loader/>
            </div>

    )
}

export default ProductsGeneralCatalogue

