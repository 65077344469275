import React, {useEffect, useState} from "react"
import {
    Icon,
    IconButton,
    Button,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Checkbox, Chip
} from "@mui/material";
import './OperationDetailsModal.scss'
import {getOperationDetail} from "./api/OperationsAPI";
import Popper from '@mui/material/Popper';
import Loader from "../../shared/loader/Loader";
import {groupArticles, numberWithSpaces} from "../../shared/utils/Utils";

const OperationDetailsModal = ({popperAnchorEl, toggleModal, orderId}) => {

    const [data, setData] = useState(null)

    useEffect(async () => {
        orderId && await handleGetOperationDetail()
    }, [orderId])

    const handleGetOperationDetail = async () => {
        const orderData = await getOperationDetail(orderId)
        const groupedOperationData = groupArticles(orderData)
        setData(groupedOperationData)

    }

    const renderElementActionBar = (element) => {
        if (element.type === "note") {
            return (
                <Chip className={"operations-details-table-wrapper-table-chip"} size={"small"} label="Note"/>
            )
        }
        if (element.type === "pourcentage") {
            return (
                <Chip className={"operations-details-table-wrapper-table-chip"} size={"small"} label="Pourcentage"/>
            )
        }
        if (element.type === "standard") {
            return (
                <Chip className={"operations-details-table-wrapper-table-chip"} size={"small"} label="Art. standard"/>
            )
        }
        if (element.type === "unique") {
            return (
                <Chip className={"operations-details-table-wrapper-table-chip"} size={"small"} label="Art. unique"/>
            )
        }
        if (element.type === "ecriture") {
            return (
                <Chip className={"operations-details-table-wrapper-table-chip"} size={"small"} label="Ecriture"/>
            )
        }
    }

    return (
        <Popper id={"operation-details-popper"} onClose={toggleModal} open={!!popperAnchorEl} anchorEl={popperAnchorEl}
                placement={"right"}>
            <Paper elevation={8} className={'operation-details-modal-wrapper'} style={{backgroundColor:"#F6F6F8"}}>
                <div className={'operation-details-modal-wrapper-header'}>
                  <span>
                      Détail de l'opération {orderId}
                  </span>
                    <div/>
                </div>
                {data && data.operation ?
                    <div className={'operation-details-modal-wrapper-content'}>
                        <div>Client : {data.operation.client_nom}</div>
                        <div>Référence client : {data.operation.numeroCommandeClient}</div>
                        <TableContainer component={Paper} style={{marginTop:"10px"}}>
                            <Table size="small" stickyHeader={true}>
                                <TableHead>
                                    <TableRow style={{fontWeight: "bold"}}>
                                        <TableCell>Désignation</TableCell>
                                        <TableCell>PU HT</TableCell>
                                        <TableCell>Quantité</TableCell>
                                        <TableCell>Prix HT</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.articlesToDisplay && data.articlesToDisplay.map((element) => (
                                        <TableRow
                                            key={element.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell className={'operation-details-modal-wrapper-content-libelle-cell'}>
                                                <div>
                                                    {element.libelle}
                                                    <strong>{element.matiere_libelle && " - " + element.matiere_libelle}</strong>
                                                </div>
                                                <div>
                                                    {renderElementActionBar(element)}
                                                </div>
                                            </TableCell>
                                            <TableCell>{element.prixHT ? element.prixHT + " €" : ""}</TableCell>
                                            <TableCell>{element.quantite && element.quantite}</TableCell>
                                            <TableCell>{element.quantite && element.prixHT && numberWithSpaces(Math.trunc((element.prixHT * element.quantite) * 100) / 100) + " €"}{element.pourcentage && element.pourcentage + " %"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    :
                    <div className={'operation-details-modal-wrapper-loader'}>
                        <Loader/>
                    </div>
                }
            </Paper>
        </Popper>
    )
}

export default OperationDetailsModal
