import React, {useState, useEffect} from "react"
import {
    Button,
    Icon,
    IconButton,
    Paper,
} from '@mui/material'
import {Line} from 'react-chartjs-2';

const ArticleThicknessRule = ({data, label}) => {

    const chartData = {
        labels: data.sort((a, b) => a.point - b.point).map((ps) => ps.point),
        datasets: [
            {
                label: 'Tarification (en %)',
                data: data.sort((a, b) => a.point - b.point).map((ps) => ps.pourcentage),
                fill: false,
                backgroundColor: '#1E2129',
                borderColor: '#1E2129',
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {}
    }

    return (
        chartData &&
        <Paper className={"article-rule-graph-wrapper"}>
            <div className={"article-rule-graph-wrapper-header"}>
                <div className={"article-rule-graph-wrapper-header-title"}>
                    Loi de longueur : {label}
                </div>
            </div>
            <div className={"article-rule-graph-wrapper-graph-container"}>
                <Line data={chartData} options={options}/>
            </div>
        </Paper>
    )
}

export default ArticleThicknessRule