import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Checkbox,
    DialogContent,
    FormControlLabel,
    TextField,
    Box,
    InputLabel,
    FormControl,
    Stepper,
    Step,
    StepLabel,
    tableHeadClasses,
    IconButton,
    Icon,
    Alert
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Select from '@mui/material/Select';
import {getAllFinish, createArticle} from "../api/ProductsAPI";
import {getThicknessRules, getLengthRules, getDevelopedRules} from "../../pricingRules/api/PricingRulesAPI";
import MenuItem from '@mui/material/MenuItem';
import {removeDuplicateItems} from "../../../shared/utils/Utils";
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import toast from 'react-hot-toast';
import {Add} from "@mui/icons-material";

const ProductsAddArticleModal = ({article, isOpen, handleClose, triggerRefetch}) => {

    const [libelle, setLibelle] = useState(article ? article?.article[0]?.article_libelle: "")
    const [finishIds, setFinishIds] = useState(article ? article?.finitions.map((matiere) => matiere.matiere_id): [])
    const [lengthRuleId, setLengthRuleId] = useState(article ? article?.article[0]?.loi_longueur_id : "")
    const [thicknessRuleId, setThicknessRuleId] = useState(article ? article?.article[0]?.loi_epaisseur_id : "")
    const [developedRuleId, setDevelopedRuleId] = useState(article ? article?.article[0]?.loi_developpe_id : "")

    const [finishesWithRanges, setfinishesWithRanges] = useState(null)

    const [finishList, setFinishList] = useState(null)
    const [allLengthRules, setAllLengthRules] = useState(null)
    const [allThicknessRules, setAllThicknessRules] = useState(null)
    const [allDevelopedRules, setAllDevelopedRules] = useState(null)

    const [step, setStep] = useState(0)

    useEffect(() => {
        handleGetAllFinish()
        handleGetAllLengthRules()
        handleGetAllThicknessRules()
        handleGetAllDevelopedRules()
    }, [])

    const handleGetAllFinish = async () => {
        const allFinish = manageFinishData(await getAllFinish())
        setFinishList(allFinish)
    }

    const handleGetAllLengthRules = async () => {
        const allLengthRules = await getLengthRules()
        setAllLengthRules(allLengthRules.loi_longueur)
    }

    const handleGetAllThicknessRules = async () => {
        const allThicknessRules = await getThicknessRules()
        setAllThicknessRules(allThicknessRules.loi_epaisseur)
    }

    const handleGetAllDevelopedRules = async () => {
        const allDevelopedRules = await getDevelopedRules()
        setAllDevelopedRules(allDevelopedRules.loideveloppe)
    }

    const handleSetLibelle = (value) => {
        setLibelle(value)
    }

    const handleSetFinish = (finishIds) => {
        setFinishIds(finishIds)
    };

    const handleSetLengthRule = (e) => {
        setLengthRuleId(e.target.value)
    }

    const handleSetThicknessRule = (e) => {
        setThicknessRuleId(e.target.value)
    }

    const handleSetDevelopedRule = (e) => {
        setDevelopedRuleId(e.target.value)
    }

    const manageFinishData = (data) => {
        const finalFinish = removeDuplicateItems(data, "matiere_id").map((finish) => {
            return (
                {
                    "matiere_libelle": finish.matiere_libelle,
                    "matiere_id": finish.matiere_id,
                    "petit_developpe": false,
                    "grand_developpe": false,
                    "surface": false
                }
            )
        })
        for (let finish of data) {
            for (let f of finalFinish) {
                if (finish.matiere_id === f.matiere_id) {
                    if (finish.plage_de_validite_libelle === "Petit développé") {
                        f.petit_developpe = finish.plage_de_validite_id
                    }
                    if (finish.plage_de_validite_libelle === "Grand développé") {
                        f.grand_developpe = finish.plage_de_validite_id
                    }
                    if (finish.plage_de_validite_libelle === "A la surface") {
                        f.surface = finish.plage_de_validite_id
                    }
                }
            }
        }
        return finalFinish.sort((a, b) => a.matiere_libelle.localeCompare(b.matiere_libelle))
    }

    const handleGoToSecondStep = () => {
        // creation mode
        if(!article){
            const finishes = []
            for (let finishId of finishIds) {
                const finish = finishList.find((fin) => fin.matiere_id === finishId)
                finish.developed = []
                if (finish.petit_developpe) {
                    finish.developed.push({
                        plage_de_validite_libelle: "Petit développé",
                        plage_de_validite_id: finish.petit_developpe,
                        min: "",
                        max: ""
                    })
                }
                if (finish.grand_developpe) {
                    finish.developed.push({
                        plage_de_validite_libelle: "Grand développé",
                        plage_de_validite_id: finish.grand_developpe,
                        min: "",
                        max: ""
                    })
                }
                if (finish.surface) {
                    finish.developed.push({
                        plage_de_validite_libelle: "A la surface",
                        plage_de_validite_id: finish.surface,
                        min: "",
                        max: ""
                    })
                }
                finishes.push(finish)
            }
            setfinishesWithRanges(finishes)
        } else if(article){
            const finishes = []
            for (let finishId of finishIds) {
                const finish = finishList.find((fin) => fin.matiere_id === finishId)
                finish.developed = []
                if (finish.petit_developpe) {
                    const initialValueMin =  article.finitions.find((finish) => finishId === finish.matiere_id)?.bornes.find((borne) => borne.pdv_libelle === "Petit développé")?.borneBasse
                    const initialValueMax =  article.finitions.find((finish) => finishId === finish.matiere_id)?.bornes.find((borne) => borne.pdv_libelle === "Petit développé")?.borneHaute
                    finish.developed.push({
                        plage_de_validite_libelle: "Petit développé",
                        plage_de_validite_id: finish.petit_developpe,
                        min: initialValueMin ? initialValueMin : "",
                        max:  initialValueMax ? initialValueMax : "",
                    })
                }
                if (finish.grand_developpe) {
                    const initialValueMin =  article.finitions.find((finish) => finishId === finish.matiere_id)?.bornes.find((borne) => borne.pdv_libelle === "Grand développé")?.borneBasse
                    const initialValueMax =  article.finitions.find((finish) => finishId === finish.matiere_id)?.bornes.find((borne) => borne.pdv_libelle === "Grand développé")?.borneHaute
                    finish.developed.push({
                        plage_de_validite_libelle: "Grand développé",
                        plage_de_validite_id: finish.grand_developpe,
                        min: initialValueMin ? initialValueMin : "",
                        max:  initialValueMax ? initialValueMax : "",
                    })
                }
                if (finish.surface) {
                    const initialValueMin =  article.finitions.find((finish) => finishId === finish.matiere_id)?.bornes.find((borne) => borne.pdv_libelle === "A la surface")?.borneBasse
                    const initialValueMax =  article.finitions.find((finish) => finishId === finish.matiere_id)?.bornes.find((borne) => borne.pdv_libelle === "A la surface")?.borneHaute
                    finish.developed.push({
                        plage_de_validite_libelle: "A la surface",
                        plage_de_validite_id: finish.surface,
                        min: initialValueMin ? initialValueMin : "",
                        max:  initialValueMax ? initialValueMax : "",
                    })
                }
                finishes.push(finish)
            }
            setfinishesWithRanges(finishes)
        }

        setStep(1)
    }

    const handleCreateEditArticle = async () => {
        if (step === 0) {
            if (libelle && finishIds && finishIds.length > 0 && developedRuleId && lengthRuleId && thicknessRuleId) {
                handleGoToSecondStep()
            } else {
                toast.error("Remplissez tous les champs avant de passer à l'étape suivante")
            }
        } else {
            const payload = {
                libelle: libelle,
                matiereIds: finishIds,
                loiDeveloppeId: developedRuleId,
                loiEpaisseurId: thicknessRuleId,
                loiLongueurId: lengthRuleId
            }
            try {
                await createArticle(payload)
                triggerRefetch()
                toast.success("L'article a été ajouté avec succès")
                handleClose(null)
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la création de l'article")
            }
        }

    }

    const renderStepper = () => {
        return (
            <Stepper activeStep={step} alternativeLabel>
                <Step>
                    <StepLabel>
                        Caractéristiques de l'article
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel>
                        Valeurs de plage
                    </StepLabel>
                </Step>
            </Stepper>
        )
    }

    const handleSetLimit = (type, index1, index2, value) => {
        const finishesCopy = finishesWithRanges.slice()
        finishesCopy[index1].developed[index2][type] = value
        setfinishesWithRanges(finishesCopy)
    }

    const handleAutoFill = () => {
        const smallDevelopedMin = finishesWithRanges.find((finish, i) => finish.developed.find((plage) => plage.plage_de_validite_libelle === "Petit développé" && plage.min !== ""))?.developed.find((plage) => plage.plage_de_validite_libelle === "Petit développé" && plage.min !== "")?.min
        const smallDevelopedMax = finishesWithRanges.find((finish, i) => finish.developed.find((plage) => plage.plage_de_validite_libelle === "Petit développé" && plage.max !== ""))?.developed.find((plage) => plage.plage_de_validite_libelle === "Petit développé" && plage.max !== "")?.max
        const bigDevelopedMin = finishesWithRanges.find((finish, i) => finish.developed.find((plage) => plage.plage_de_validite_libelle === "Grand développé" && plage.min !== ""))?.developed.find((plage) => plage.plage_de_validite_libelle === "Grand développé" && plage.min !== "")?.min
        const bigDevelopedMax = finishesWithRanges.find((finish, i) => finish.developed.find((plage) => plage.plage_de_validite_libelle === "Grand développé" && plage.max !== ""))?.developed.find((plage) => plage.plage_de_validite_libelle === "Grand développé" && plage.max !== "")?.max
        const surfaceMin = finishesWithRanges.find((finish, i) => finish.developed.find((plage) => plage.plage_de_validite_libelle === "A la surface" && plage.min !== ""))?.developed.find((plage) => plage.plage_de_validite_libelle === "A la surface" && plage.min !== "")?.min
        const surfaceMax = finishesWithRanges.find((finish, i) => finish.developed.find((plage) => plage.plage_de_validite_libelle === "A la surface" && plage.max !== ""))?.developed.find((plage) => plage.plage_de_validite_libelle === "A la surface" && plage.max !== "")?.max
        if (smallDevelopedMin || smallDevelopedMax || bigDevelopedMin || bigDevelopedMax || surfaceMin || surfaceMax) {
            const finishesCopy = finishesWithRanges.slice()
            for (let finish of finishesCopy) {
                for (let plage of finish.developed) {
                    if (smallDevelopedMin && plage.plage_de_validite_libelle === "Petit développé") {
                        plage.min = smallDevelopedMin
                    }
                    if (smallDevelopedMax && plage.plage_de_validite_libelle === "Petit développé") {
                        plage.max = smallDevelopedMax
                    }
                    if (bigDevelopedMin && plage.plage_de_validite_libelle === "Grand développé") {
                        plage.min = bigDevelopedMin
                    }
                    if (bigDevelopedMax && plage.plage_de_validite_libelle === "Grand développé") {
                        plage.max = bigDevelopedMax
                    }
                    if (surfaceMin && plage.plage_de_validite_libelle === "A la surface") {
                        plage.min = surfaceMin
                    }
                    if (surfaceMax && plage.plage_de_validite_libelle === "A la surface") {
                        plage.max = surfaceMax
                    }
                }
            }
            setfinishesWithRanges(finishesCopy)
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <div className={'products-create-article-wrapper'}>
                <DialogTitle>{article ? "Modification d'un article" : "Création d'un article"}</DialogTitle>
                {renderStepper()}
                {
                    step == 1 &&
                    <div className={"products-create-article-wrapper-header"}>
                        <Alert severity="info" style={{marginTop: 5}}>
                            <div>Vous devez renseigner les valeurs pour lesquelles les plages de développé seront usitées, pour chaque finition.</div>
                            <div> Si ces valeurs correspondent pour chaque finition, renseignez en une et cliquer sur le bouton de remplissage intelligent ci-dessous pour
                                compléter les autres finitions.
                            </div>
                            <div>Les plages ayant la même dénomination seront remplies avec les mêmes valeurs.</div>
                        </Alert>
                        <div className={"products-create-article-wrapper-header-autofill"}>
                            <Button variant={"outlined"} onClick={() => handleAutoFill()} style={{width: "100%"}} startIcon={<Icon color={"#555555"}>auto_fix_high</Icon>}>
                                Remplissage automatique
                            </Button>
                        </div>

                    </div>
                }

                {
                    step === 0 ?
                        <DialogContent>
                            <div className={"products-create-article-wrapper-text-fields"}>
                                <TextField
                                    style={{marginBottom: "12px"}}
                                    value={libelle}
                                    onChange={(e) => handleSetLibelle(e.target.value)}
                                    variant="outlined"
                                    label={"Libellé"}
                                />
                                <FormControl sx={{minWidth: 350}}>
                                    <InputLabel>Choisissez la loi de longueur applicable</InputLabel>
                                    <Select
                                        value={lengthRuleId}
                                        style={{marginBottom: "12px"}}
                                        label={'Choisissez la loi de longueur applicable'}
                                        className={""}
                                        onChange={handleSetLengthRule}
                                    >
                                        {allLengthRules && allLengthRules.map((rule) => {
                                            return (
                                                <MenuItem key={rule.id} value={rule.id}
                                                >{rule.libelle}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </ FormControl>
                                <FormControl sx={{minWidth: 350}}>
                                    <InputLabel>Choisissez la loi d'épaisseur applicable</InputLabel>
                                    <Select
                                        value={thicknessRuleId}
                                        style={{marginBottom: "12px"}}
                                        label={"Choisissez la loi d'épaisseur applicable"}
                                        className={""}
                                        onChange={handleSetThicknessRule}
                                    >
                                        {allThicknessRules && allThicknessRules.map((rule) => {
                                            return (
                                                <MenuItem key={rule.id} value={rule.id}
                                                >{rule.loi_epaisseur_libelle}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{minWidth: 350}}>
                                    <InputLabel>Choisissez la loi de développé applicable</InputLabel>
                                    <Select
                                        value={developedRuleId}
                                        style={{marginBottom: "12px"}}
                                        label={"Choisissez la loi de développé applicable"}
                                        className={""}
                                        onChange={handleSetDevelopedRule}
                                    >
                                        {allDevelopedRules && allDevelopedRules.map((rule) => {
                                            return (
                                                <MenuItem key={rule.id} value={rule.id}
                                                >{rule.libelle}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{minWidth: 350}}>
                                    <InputLabel>Choisissez les finitions applicables</InputLabel>
                                    <Select
                                        label={'Choisissez les finitions applicables'}
                                        multiple
                                        value={finishIds}
                                        onChange={(e) => handleSetFinish(e.target.value)}
                                        MenuProps={{
                                            style: {
                                                maxHeight: "200px",
                                            },
                                        }}
                                        renderValue={(selected) => (
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {selected.map((value) => {
                                                    return (
                                                        <Chip key={value} label={finishList && finishList.find((finish) => finish.matiere_id === value).matiere_libelle}/>
                                                    )
                                                })
                                                }
                                            </Box>
                                        )}
                                    >
                                        {
                                            finishList && finishList.map((finish) => {
                                                    return (
                                                        <MenuItem key={finish.matiere_id} value={finish.matiere_id}>{finish.matiere_libelle}</MenuItem>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </ FormControl>
                            </div>
                        </DialogContent>
                        :
                        <DialogContent>
                            <div className={"products-create-article-wrapper-ranges noscrollbar"}>
                                {finishesWithRanges && finishesWithRanges.map((finish, i1) => {
                                    return (
                                        <div style={{border: "solid 1px #CCCCCC", borderRadius: 4, padding: 5, marginBottom: "10px"}}>
                                            <div style={{display: "flex", alignItems: "center", marginBottom: 16}}>
                                                <div style={{fontSize: 16, fontWeight: "bold"}}>{finish.matiere_libelle}</div>
                                                <div style={{flex: 1}}/>
                                            </div>

                                            {
                                                finish.developed.map((plage, i2) => {
                                                    return (
                                                        <div className={"products-create-article-wrapper-range"}>
                                                            <div>{plage.plage_de_validite_libelle}</div>
                                                            <div style={{flex: 1}}/>
                                                            <TextField
                                                                onChange={(e) => handleSetLimit("min", i1, i2, e.target.value)}
                                                                size={"small"}
                                                                style={{width: "20%"}}
                                                                value={plage.min}
                                                                variant="outlined"
                                                                label={"Borne basse"}
                                                            />
                                                            <Icon style={{marginLeft: 10, marginRight: 10}}>arrow_right_alt</Icon>
                                                            <TextField
                                                                onChange={(e) => handleSetLimit("max", i1, i2, e.target.value)}
                                                                size={"small"}
                                                                style={{width: "20%"}}
                                                                value={plage.max}
                                                                variant="outlined"
                                                                label={"Borne haute"}
                                                            />
                                                        </div>
                                                    )
                                                })

                                            }

                                        </div>
                                    )
                                })}
                            </div>
                        </DialogContent>
                }
                <DialogActions>
                    <Button onClick={() => handleClose(null)}>Annuler</Button>
                    <Button
                        onClick={() => handleCreateEditArticle()}
                    >
                        Confirmer
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default ProductsAddArticleModal