import React, {useEffect, useState} from "react"
import {Checkbox, FormControlLabel, Paper, TextField, Button} from "@mui/material";
import "./NewOperation.scss"
import {getAllClients} from "../api/OperationsAPI";
import Autocomplete from '@mui/material/Autocomplete';
import {createOperation} from "../api/OperationsAPI";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import dayjs from 'dayjs'
import toast from "react-hot-toast"

const NewOperation = ({history}) => {

    const [allClients, setAllClients] = useState(null)
    const [client, setClient] = useState(null)
    const [renovaRef, setRenovaRef] = useState("")
    const [clientRef, setClientRef] = useState("")
    const [TVA, setTVA] = useState(true)
    const [wasteTreatment, setWasteTreatment] = useState(true)
    const [creationDate, setCreationDate] = useState(null)
    const [prevEndDate, setPrevEndDate] = useState(null)

    useEffect(() => {
        handleGetAllClients()
    }, [])

    const handleGetAllClients = async () => {
        const clients = await getAllClients()
        for (let client of clients.clients) {
            client.label = client.nom
        }
        setAllClients(clients.clients)
    }

    const handleRenovaRef = (value) => {
        setRenovaRef(value)
    }

    const handleClientRef = (value) => {
        setClientRef(value)
    }

    const handleTVA = (value) => {
        setTVA(value)
    }

    const handleWasteTreatment = (value) => {
        setWasteTreatment(value)
    }

    const handleCreationDate = (value) => {
        setCreationDate(value)
    }

    const handlePrevEndDate = (value) => {
        setPrevEndDate(value)
    }

    const handleSendNewOperation = async () => {
        const payload = {
            dateRendu: dayjs(prevEndDate).format("YYYY-MM-DDT00:00:00+00:00"),
            depollution: wasteTreatment,
            tva: TVA,
            numero:renovaRef,
            numeroCommandeClient: clientRef,
            clientId: client.id,
            dateCreation: dayjs(creationDate).format("YYYY-MM-DDT00:00:00+00:00"),
        }
        try{
            const operationCreated= await createOperation(payload)
            history.push(`/operations/operation/detail/${operationCreated.id}`)
            toast.success("L'opération a été créée avec succès")
        }catch(err){
            toast.error("Erreur durant la création de l'opération")
            console.log(err)
        }
    }

    return (
        <div className={'new-operation'}>
            <Paper elevation={8} className={'new-operation-wrapper'}>
                <div className={'new-operation-header'}>
                    Remplissez les champs suivants afin de créer une nouvelle opération
                    <div className={'new-operation-header-line'}/>
                </div>

                <div className={'new-operation-fields-wrapper'}>
                    <Autocomplete
                        onChange={(event, newValue) => {
                            setClient(newValue);
                        }}
                        className={'new-operation-fields-wrapper-client-wrapper'}
                        disablePortal
                        options={allClients && allClients}
                        sx={{width: 800}}
                        renderInput={(params) => <TextField {...params} label="Sélectionnez un client"/>}
                        renderOption={(props, option) => (
                            <div {...props} className={'new-operation-field-wrapper-autocomplete-client-fields-wrapper'}>
                                <div><strong>{option.nom}</strong></div>
                                <div>{option.adresse}</div>
                                <div>{option.code_postal} {option.ville}</div>
                                {option.telephone && <div>&#128241; {option.telephone}</div>}
                                {option.email && <div>&#128231; {option.email}</div>}
                            </div>
                        )}
                    />
                    <TextField
                        className={'new-operation-fields-wrapper-field'}
                        variant="outlined"
                        onChange={(e) => handleRenovaRef(e.target.value)}
                        label={"Référence Renova"}
                        helperText={"Si ce champ n'est pas rempli, il sera généré automatiquement"}
                    />
                    <TextField
                        className={'new-operation-fields-wrapper-field'}
                        variant="outlined"
                        onChange={(e) => handleClientRef(e.target.value)}
                        label={"Référence commande client"}
                    />
                    <FormControlLabel control={<Checkbox onChange={() => handleTVA(!TVA)} value={TVA}/>} label="La TVA s'applique t-elle ?"/>
                    <FormControlLabel control={<Checkbox onChange={() => handleWasteTreatment(!wasteTreatment)} value={wasteTreatment}/>}
                                      label="La taxe de dépollution s'applique t-elle ?"/>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date d'enregistrement de la commande"
                            inputFormat="dd/MM/yyyy"
                            value={creationDate}
                            onChange={(newValue) => handleCreationDate(dayjs(newValue).format("YYYY-MM-DD"))}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DesktopDatePicker
                            label="Date de fin de production prévisionnelle"
                            inputFormat="dd/MM/yyyy"
                            value={prevEndDate}
                            onChange={(newValue) => handlePrevEndDate(dayjs(newValue).format("YYYY-MM-DD"))}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                </div>

                <div className={"new-operation-buttons"}>
                    <div style={{flex: 1}}/>
                    <Button variant="contained" onClick={() => handleSendNewOperation()}>Créer</Button>
                </div>

            </Paper>
        </div>

    )


}

export default NewOperation
