import React, {useEffect, useState} from "react"
import {
    Icon,
    IconButton,
    Button,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import "./Stats.scss"
import undraw_empty from "../../../shared/undraw/undraw_empty.svg";
import {Doughnut} from "react-chartjs-2";
import Loader from "../../../shared/loader/Loader";

const StatsByFinishGroup = ({stats, isLoading}) => {

    const [total, setTotal] = useState(null)

    useEffect(() => {
        if (stats) {
            setTotal(Object.keys(stats).map((key) => stats[key]).reduce((prev, acc) => prev + acc))
        }
    }, [stats])

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const renderContent = () => {
        if (!isLoading && !stats) {
            return (
                <div className={''}>
                    <img src={undraw_empty} alt={"no data available"}/>
                    <span>Pas de donnée à afficher</span>
                </div>
            )
        }
        if (stats && !isLoading) {
            const data = {
                labels: Object.keys(stats).map((key) => key),
                datasets: [
                    {
                        data: Object.values(stats).map((value) => value),
                        backgroundColor: Object.keys(stats).map(() => getRandomColor()),
                    },
                ],
            };
            const options = {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                const keys = Object.keys(stats)
                                return keys[context.dataIndex] + ' : ' + context.formattedValue + " €"
                            }
                        }
                    },
                    legend: {
                        display: true,
                        position: "left"
                    },
                }
            }
            return (
                <Doughnut data={data} options={options} className={''}/>
            )
        }
        if (isLoading) {
            return (
                <div className={''}>
                    <Loader/>
                </div>
            )
        }
    }

    return (
        <Paper className={'stats-wrapper'} style={{marginTop:"20px"}}>
            <div className={'stats-wrapper-title'}>
                <div>{"Répartition du C.A. facturé par client "}<span style={{color:"#797979"}}>(calculé sur les prix HT)</span></div>
            </div>
            <div className={'stats-wrapper-graph-table'}>
                <div className={'stats-wrapper-graph'}>
                    {renderContent()}
                </div>
                <TableContainer component={Paper} className={'stats-wrapper-table'}>
                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{backgroundColor:"#FAFAFA"}}>Groupe de finition</TableCell>
                                <TableCell style={{backgroundColor:"#FAFAFA"}}>CA (HT)</TableCell>
                                <TableCell style={{backgroundColor:"#FAFAFA"}}>Pourcentage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                stats && total &&
                                Object.keys(stats).map(function (key, index) {
                                    return (
                                        <TableRow>
                                            <TableCell>{key}</TableCell>
                                            <TableCell>{(stats[key]).toFixed(2)} €</TableCell>
                                            <TableCell>{(((stats[key]) / total) * 100).toFixed(2)} %</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </Paper>
    )

}

export default StatsByFinishGroup
