import React, {useState, useEffect} from "react"
import {withRouter} from "react-router-dom";
import {deleteFinish, getMatiereDetails} from "../api/ProductsAPI";
import {Button, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import "./FinishDetails.scss"
import {removeDuplicateItems} from "../../../shared/utils/Utils";
import FinishDetailsCreateEditPdv from "./FinishDetailsCreateEditPdv";
import toast from "react-hot-toast";

const FinishDetails = (props) => {

    const [finishDetails, setFinishDetails] = useState(null)
    const [isCreateEditPdvModalOpen, setIsCreateEditPdvModalOpen] = useState(false)
    const [selectedPdv, setSelectedPdv] = useState(null)

    useEffect(() => {
        getFinishDetails()
    }, [])

    useEffect(() => {
        if (selectedPdv) {
            handleToggleModalOpen()
        }
    }, [selectedPdv])

    const getFinishDetails = async () => {
        const matiereId = props.match.params.finishId
        const result = await getMatiereDetails(matiereId)
        const groupedFinish = manageFinishData(result)
        setFinishDetails(groupedFinish)
    }

    const handleToggleModalOpen = () => {
        if (isCreateEditPdvModalOpen) {
            setIsCreateEditPdvModalOpen(false)
            setSelectedPdv(null)
        } else {
            setIsCreateEditPdvModalOpen(true)
        }
    }

    const handleDeleteFinish = async () => {
        try{
            const matiereId = props.match.params.finishId
            await deleteFinish(matiereId)
            props.history.goBack()
            toast.success("La finition a été supprimée avec succès")
        } catch(err){
            console.log(err)
            toast.error(err?.response?.data?.catched_exception ? err?.response?.data?.catched_exception : "Erreur durant la suppression de la finition")
        }

    }

    const manageFinishData = (data) => {
        const plagesDeValidite = removeDuplicateItems(data, "plage_de_validite_id").map((finish) => {
            return (
                {
                    "matiere_libelle": finish.matiere_libelle,
                    "plage_de_validite_libelle": finish.plage_de_validite_libelle,
                    "plage_de_validite_id": finish.plage_de_validite_id,
                    "plage_de_validite_unite": finish.plage_de_validite_unite,
                    "matiere_id": finish.matiere_id,
                    "tarifs": [],
                }
            )
        })
        for (let pdv of plagesDeValidite) {
            for (let tarif of data) {
                if (tarif.plage_de_validite_id === pdv.plage_de_validite_id) {
                    pdv.tarifs.push(tarif)
                }
            }
        }
        return plagesDeValidite
    }

    return (
        finishDetails &&
        <div className={"finish-details-wrapper"}>
            <div className={"finish-details-wrapper-header"}>
                <div className={"finish-details-wrapper-header"}>
                                <span>
                                   {finishDetails.length > 0 ? finishDetails[0].matiere_libelle : ""}
                                </span>
                </div>
                <div className={"finish-details-wrapper-header-buttons"}>
                    <Button variant={"contained"}
                            onClick={() => handleToggleModalOpen()}
                            style={{marginRight: "10px"}}
                    >
                        Ajouter une plage de tarification
                    </Button>
                    <Button variant={"outlined"}
                            onClick={() => handleDeleteFinish()}
                            style={{borderColor: "#EE3E3B", color: "#EE3E3B", marginLeft: "10px"}}
                    >
                        Supprimer la finition
                    </Button>
                </div>
            </div>
            {
                finishDetails.map((pdv) => {
                    return (
                        <Paper className={"finish-details-wrapper-pdv"}>
                            <div className={"finish-details-wrapper-pdv-header"}>
                                <div
                                    className={"finish-details-wrapper-pdv-title"}>{pdv.plage_de_validite_libelle} ({pdv.plage_de_validite_unite === "ml" ? "au mètre linéaire" : pdv.plage_de_validite_unite === "m2" ? "au m²" : ""})
                                </div>
                                <Button variant={"outlined"}
                                        onClick={() => setSelectedPdv(pdv)}
                                        style={{}}
                                >
                                    Editer
                                </Button>
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: 650}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{backgroundColor: "#FAFAFA"}} >
                                                <strong>Libellé</strong>
                                            </TableCell>
                                            {pdv.tarifs.map((col, i) => (
                                                <TableCell key={`${col.tarif_prix}${i}`} style={{backgroundColor: "#FAFAFA"}}>
                                                    <strong>{col.tarif_libelle}</strong>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>
                                                <strong>Prix</strong>
                                            </TableCell>
                                            {pdv.tarifs.map((tarif) => (
                                                <TableCell component="th" scope="row">
                                                    {tarif.tarif_prix} €
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )
                })
            }
            {
                isCreateEditPdvModalOpen && <FinishDetailsCreateEditPdv isOpen={isCreateEditPdvModalOpen} handleClose={handleToggleModalOpen} selectedPdv={selectedPdv} handleSetTriggerRefetch={getFinishDetails} matiereId={props.match.params.finishId}/>
            }
        </div>


    )
}

export default withRouter(FinishDetails)
