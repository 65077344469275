import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Checkbox, DialogContent, FormControlLabel, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createClient, editClient} from "./api/ClientAPI";
import toast from "react-hot-toast"

const ClientsAddEditClient = ({clientId, client, mode, handleClose, handleSetTriggerRefetch}) => {

    const [isCompany, setIsCompany] = useState(client.entreprise === "1")
    const [nom, setNom] = useState(client.nom)
    const [prenom, setPrenom] = useState(client.prenom)
    const [adresse, setAdresse] = useState(client.adresse)
    const [codePostal, setCodePostal] = useState(client.code_postal)
    const [ville, setVille] = useState(client.ville)
    const [telephone, setTelephone] = useState(client.telephone)
    const [fax, setFax] = useState(client.fax)
    const [email, setEmail] = useState(client.email)
    const [siret, setSiret] = useState(client.siret)
    const [TVA, setTVA] = useState(client.TVA)
    const [depollution, setDepollution] = useState(client.depollution)

    useEffect(() => {

    }, [])

    const handleSetIsCompany = (value) => {
        setIsCompany(value)
    }

    const handleSetNom = (value) => {
        setNom(value)
    }

    const handleSetPrenom = (value) => {
        setPrenom(value)
    }

    const handleSetAdresse = (value) => {
        setAdresse(value)
    }

    const handleSetCodePostal = (value) => {
        setCodePostal(value)
    }

    const handleSetVille = (value) => {
        setVille(value)
    }

    const handleSetTelephone = (value) => {
        setTelephone(value)
    }

    const handleSetFax = (value) => {
        setFax(value)
    }

    const handleSetEmail = (value) => {
        setEmail(value)
    }

    const handleSetSiret = (value) => {
        setSiret(value)
    }

    const handleSetTVA = (value) => {
        setTVA(value)
    }

    const handleSetDepollution = (value) => {
        setDepollution(value)
    }

    const handleCreateEditClient = async () => {
        const payload = {
            genre: null,
            entreprise: isCompany,
            nom: nom,
            prenom: isCompany ? null : prenom,
            adresse: adresse,
            codePostal: codePostal,
            ville: ville,
            siret: siret,
            email: email,
            telephone: telephone,
            fax: fax,
            depollution: depollution,
            tva: TVA
        }
        if (mode === "create") {
            try {
                await createClient(payload)
                handleClose()
                handleSetTriggerRefetch()
                toast.success("Le client a été créé avec succès")
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la création du client")
            }
            } else if (mode === "edit") {
                try {
                    await editClient(payload, clientId)
                    handleClose()
                    handleSetTriggerRefetch()
                    toast.success("Le client a été modifié avec succès")
                } catch (err) {
                    console.log(err)
                    toast.error("Erreur durant la modification du client")
                }
            }
        }

        return (
            <Dialog open={client ? true : false} onClose={() => handleClose(null)} maxWidth={"lg"}>
                <DialogTitle>{mode === "create" ? "Création d'un client" : "Edition d'un client"}</DialogTitle>
                <DialogContent>
                    <div className={"products-edit-unique-article-text-fields-wrapper"}>
                        <FormControlLabel control={<Checkbox onChange={() => handleSetIsCompany(!isCompany)} checked={isCompany}/>} label="Ce client est une entreprise ?"/>
                        <TextField
                            style={{marginBottom: "12px"}}
                            value={nom}
                            onChange={(e) => handleSetNom(e.target.value)}
                            variant="outlined"
                            label={"Nom"}
                        />
                        {
                            !isCompany &&
                            <TextField
                                style={{marginBottom: "12px"}}
                                value={prenom}
                                onChange={(e) => handleSetPrenom(e.target.value)}
                                variant="outlined"
                                label={"Prénom"}
                            />
                        }

                        <TextField
                            style={{marginBottom: "12px"}}
                            value={adresse}
                            onChange={(e) => handleSetAdresse(e.target.value)}
                            variant="outlined"
                            label={"Adresse"}
                        />
                        <TextField
                            style={{marginBottom: "12px"}}
                            value={codePostal}
                            onChange={(e) => handleSetCodePostal(e.target.value)}
                            variant="outlined"
                            label={"Code postal"}
                        />
                        <TextField
                            style={{marginBottom: "12px"}}
                            value={ville}
                            onChange={(e) => handleSetVille(e.target.value)}
                            variant="outlined"
                            label={"Ville"}
                        />
                        <TextField
                            style={{marginBottom: "12px"}}
                            value={telephone}
                            onChange={(e) => handleSetTelephone(e.target.value)}
                            variant="outlined"
                            label={"Téléphone"}
                        />
                        <TextField
                            style={{marginBottom: "12px"}}
                            value={fax}
                            onChange={(e) => handleSetFax(e.target.value)}
                            variant="outlined"
                            label={"Fax"}
                        />
                        <TextField
                            style={{marginBottom: "12px"}}
                            value={email}
                            onChange={(e) => handleSetEmail(e.target.value)}
                            variant="outlined"
                            label={"Email"}
                        />
                        <TextField
                            style={{marginBottom: "12px"}}
                            value={siret}
                            onChange={(e) => handleSetSiret(e.target.value)}
                            variant="outlined"
                            label={"Siret"}
                        />
                        <FormControlLabel control={<Checkbox onChange={() => handleSetTVA(!TVA)} checked={TVA}/>} label="La TVA s'applique t-elle ?"/>
                        <FormControlLabel control={<Checkbox onChange={() => handleSetDepollution(!depollution)} checked={depollution}/>}
                                          label="La taxe de dépollution s'applique t-elle ?"/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>Annuler</Button>
                    <Button
                        onClick={() => handleCreateEditClient()}
                    >
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

export default ClientsAddEditClient