import React from "react"
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import {NavLink} from "react-router-dom";
import {Button, Icon} from "@mui/material";


export const MenuButton = ({cluster}) => {
    return(
        <Accordion className={"menu-wrapper-body-accordion-wrapper"}>
            <AccordionSummary
                className={"menu-wrapper-body-accordion-summary"}
                expandIcon={<ExpandMoreIcon />}
            >
                <Icon>{cluster.icon}</Icon>
                <Typography>{cluster.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {cluster.submenu.length > 0 && cluster.submenu.map((item) => {
                    return(
                        <NavLink key={item.title} key={item.title} activeClassName={'menu-wrapper-body-button-wrapper-active'} to={{pathname: item.link, state:{title: item.title}}}>
                            <Button  className={"menu-wrapper-body-accordion-content"}>
                                <Icon>{item.icon}</Icon>
                                <span>{item.name}</span>
                            </Button>
                        </NavLink>
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}