import React, {useState, useEffect} from "react"
import {withRouter} from "react-router-dom"
import {filterData} from "../../../shared/utils/Utils";
import {getAllProviders} from "../api/ProvidersAPI";
import ProvidersList from "./ProvidersList";

const Providers = () => {

    const [filterInputValue, setFilterInputValue] = useState("")
    const [initialData, setInitialData] = useState(null)
    const [data, setData] = useState(null)
    const [triggerRefresh, setTriggerRefresh] = useState(false)

    useEffect(() => {
        handleGetAllProviders()
    }, [triggerRefresh])

    const handleGetAllProviders = () => {
        getAllProviders()
            .then((res) => {
                setInitialData(res)
                setData(res)
            })
    }

    useEffect(() => {
        if (initialData) {
            const filteredData = filterData(initialData, filterInputValue)
            setData(filteredData)
        }
    }, [filterInputValue])

    const handleFilterInputValueChange = (e) => {
        setFilterInputValue(e.target.value)
    }

    const categories = [
        {
            title: "Fournisseur",
            key: "provider"
        },
        {
            title: "Adresse",
            key: "address"
        },
        {
            title: "Siret",
            key: "siret"
        },
        {
            title: "Téléphone",
            key: "phone"
        },
    ]

    return (
        <ProvidersList categories={categories} handleFilterInputValueChange={handleFilterInputValueChange} filterInputValue={filterInputValue} data={data} triggerRefresh={handleGetAllProviders}/>
    )

}

export default withRouter(Providers)