import {APIget, APIput, APIdelete, APIpatch, APIpost} from "../../services/API"
import {avoirTemplate, billTemplate, deliveryReceiptTemplate, operationsList, proformaPDFTemplate, providerOrderTemplate, quoteTemplate} from "../../shared/pdf/PDFTemplates";
import {baseURL} from "../../shared/utils/Utils";

export const getContacts = (clientId) => {
    return new Promise((resolve, reject) => {
        APIget(`/api/client/${clientId}/fetch/contacts`)
            .then((contacts) => {
                resolve(contacts)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const sendEmail = (email, jwt, proforma, quote, deliveryReceipt, simpleInvoice, detailedInvoice) => {
    return new Promise((resolve, reject) => {
        const form_content = JSON.stringify({
            "contenu": email.body,
            "objet": email.subject,
            "destinataire": email.contacts,
            "cc": email.cc,
            "bcc": email.bcc,
            "bl": email.isDeliveryReceiptAttached,
            "signature": email.isSignatureIncluded,
            "devis": email.isQuoteAttached,
            "proforma": email.isProformaAttached
        });
        let formData = new FormData();
        formData.append("facture_detaille_file", detailedInvoice);
        formData.append("facture_file", simpleInvoice);
        formData.append("proforma_file", proforma);
        formData.append("devis_file", quote);
        formData.append("bl_file", deliveryReceipt);
        formData.append("form_content", form_content);
        let headers = new Headers({"Authorization": 'Bearer ' + jwt});

        let url = baseURL() + `api/send/mail`;

        fetch(url, {
            method: "POST",
            headers: headers,
            body: formData
        })
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const attachPDF = async (orderData, documentType) => {
    if (!documentType) {
        return
    }
    let template = null;
    if (documentType === "proforma") {
        template = proformaPDFTemplate(orderData)
    } else if (documentType === 'deliveryReceipt') {
        template = deliveryReceiptTemplate(orderData)
    } else if (documentType === 'quote') {
        template = quoteTemplate(orderData)
    } else if (documentType === 'basicBill') {
        template = billTemplate(orderData, 'basic')
    } else if (documentType === 'detailedBill') {
        template = billTemplate(orderData, 'detailed')
    } else if (documentType === 'operationsInProductionList' || documentType === 'operationsInProductionListForAClient') {
        template = operationsList(orderData, documentType)
    } else if (documentType === 'avoir') {
        template = avoirTemplate(orderData)
    } else if (documentType === 'providerOrder') {
        template = providerOrderTemplate(orderData)
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const payload = JSON.stringify({
        html: template,
        // textFooter:" Norme AFNOR NF P03-001. En l'absence de dispositions particulières contraires, la norme AFNOR NF P-03001 de décembre 2000 vaut pièce contractuelle complémentaire. " +
        //     "Le vendeur se réserve, jusqu'à leur complet paiement, la propriété des fournitures éligibles à la revendication prévue par l'article" +
        //     "L.264-16 du code du commerce." +
        //     "36 rue Mathieu 93400 SAINT-OUEN Tl: 01 40 11 33 40 Fax: 01 40 11 33 11 decord@renovachrome.fr" +
        //     "SARL au capital de 8 000 Euros RCS Bobigny B572 142 347 APE 2561Z TVA intracommunautaire FR2557214234700028"
        //     ,
        // noMargin: true
    })
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: payload,
        redirect: 'follow'
    };
    const result = await fetch("https://yan.erp.hubup.cloud/pdf/make/from/html", requestOptions)
    const resultArrayBuffer = await result.arrayBuffer()
    return new Blob([resultArrayBuffer], {type: 'application/pdf'});
}
