import React, {useEffect, useState} from "react"
import {
    Icon,
    IconButton,
    Button,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import "./Stats.scss"
import undraw_empty from "../../../shared/undraw/undraw_empty.svg";
import {Doughnut, Line} from "react-chartjs-2";
import Loader from "../../../shared/loader/Loader";
import dayjs from "dayjs";

const StatsByFinishGroup = ({turnover, isLoading}) => {

    const [total, setTotal] = useState(null)

    useEffect(() => {
        if (turnover) {
            setTotal(Object.keys(turnover).map((key) => turnover[key]).reduce((prev, acc) => prev + acc))
        }
    }, [turnover])

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const renderContent = () => {
        if((!isLoading && !turnover) || (!isLoading && turnover && !turnover.length)){
            return(
                <div className={''}>
                    <img src={undraw_empty} alt={"no data available"}/>
                    <span>Pas de donnée à afficher</span>
                </div>
            )
        }
        if(turnover && !isLoading){
            const data = {
                labels: turnover[0].values.map((month) => dayjs(month.month, 'DD/MM/YYYY').locale('fr').format("MMM YY")),
                datasets: [
                    {
                        label: turnover[0].key,
                        data: turnover[0].values.map((month) => month.ca),
                        fill: false,
                        backgroundColor: '#1E2129',
                        borderColor: '#1E2129',
                    },
                    {
                        label: turnover[1].key,
                        data: turnover[1].values.map((month) => month.ca),
                        fill: false,
                        backgroundColor: '#a1a7b7',
                        borderColor: '#a1a7b7',
                    },
                    {
                        label: turnover[2].key,
                        data: turnover[0].values.map((month) => turnover[2].values[0].ca),
                        fill: false,
                        backgroundColor: '#647dc1',
                        borderColor: '#647dc1',
                    },
                ],
            };

            const options = {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || '';
                                return label = label + " : " + context.parsed.y + " €";
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            callback: function(value, index, values) {
                                return value + " €";
                            }
                        },
                        beginAtZero: false
                    }
                }
            }
            return(
                <Line data={data} options={options} className={''}/>
            )
        }
        if(isLoading){
            return (
                <div className={''}>
                    <Loader/>
                </div>
            )
        }
    }

    return (
        <Paper className={'stats-wrapper'} style={{marginTop:"20px"}}>
            <div className={'stats-wrapper-title'}>
                <div>{"Evolution du chiffre d'affaires total "}<span style={{color:"#797979"}}>(calculé sur les prix HT et intégrant la taxe de dépollution)</span></div>
            </div>
            <div className={'stats-wrapper-graph-table'}>
                <div className={'stats-wrapper-graph'} style={{width:"70%", margin:"30px 0"}}>
                    {renderContent()}
                </div>
            </div>
        </Paper>
    )

}

export default StatsByFinishGroup
