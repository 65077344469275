import Typography from "@mui/material/Typography";
import MainAppBar from "./MainAppBar";
import {Button, FormControlLabel, Radio, RadioGroup, Step, StepLabel, Stepper, Switch} from "@mui/material";
import {Link} from "react-router-dom";

export function Mobile() {



    return <div style={{padding: 10, display: "flex", justifyContent: "space-between", flexDirection:"column", height: "calc(100vh - 56px)"}}>
        <div>
            <Stepper activeStep={0} alternativeLabel style={{marginTop: 20}}>
                <Step>
                    <StepLabel>Sélection du poste</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Démarrer le suvi</StepLabel>
                </Step>
            </Stepper>

            <div style={{marginTop: 20, marginBottom: 20}}>
                <RadioGroup
                    aria-label="craftingStep"
                    name="radio-buttons-group"
                >
                    <FormControlLabel value="1" control={<Radio />} label="Réception des marchandises" />
                    <FormControlLabel value="2" control={<Radio />} label="Polissage" />
                    <FormControlLabel value="3" control={<Radio />} label="Vernissage" />
                    <FormControlLabel value="4" control={<Radio />} label="Mise au bain (Hors BM)" />
                    <FormControlLabel value="5" control={<Radio />} label="Mise au bain (BM)" />
                    <FormControlLabel value="6" control={<Radio />} label="Peinture" />
                </RadioGroup>
            </div>

            <div>
                <FormControlLabel
                    control={
                        <Switch defaultChecked color="primary" />
                    }
                    label="Sauvegarder ce choix"
                />

            </div>
        </div>
        <div>
            <Link to={"/mobile/craftingStep"} style={{textDecoration: "none"}}>
                <Button variant={"contained"} size={"large"} fullWidth={1}>Sélectionner ce poste</Button>
            </Link>
        </div>

    </div>;

}
