import axios from 'axios';
import UserProvider from "./../contexts/UserContext"
import {baseURL} from "../shared/utils/Utils";


// const headers = () => {
//     let headers = {}
//     if(localStorage.getItem("renova-chrome-jwt")){
//         headers = {
//             "Authorization": 'Bearer ' + localStorage.getItem("renova-chrome-jwt")
//         }
//         return headers
//     }
// }

const axiosInstance = axios.create({
    baseURL: baseURL(),
    // headers : headers(),
});

export const APIget = (url) => {
    return new Promise((resolve, reject) => {
        axiosInstance.get(url)
            .then((response) => {
                resolve(handleResponse(response))
            })
            .catch((err) => {
                reject(handleError(err, url))
            })
    })
}

export const APIpost = (url, parameters) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(url, parameters)
            .then((response) => {
                resolve(handleResponse(response))
            })
            .catch((err) => {
                reject(handleError(err, url))
            })
    })
}

export const APIput = (url, parameters) => {
    return new Promise((resolve, reject) => {
        axiosInstance.put(url, parameters)
            .then((response) => {
                resolve(handleResponse(response))
            })
            .catch((err) => {
                reject(handleError(err, url))
            })
    })
}

export const APIpatch = (url, parameters) => {
    return new Promise((resolve, reject) => {
        axiosInstance.patch(url, parameters)
            .then((response) => {
                resolve(handleResponse(response))
            })
            .catch((err) => {
                reject(handleError(err, url))
            })
    })
}

export const APIdelete = (url) => {
    return new Promise((resolve, reject) => {
        axiosInstance.delete(url)
            .then((response) => {
                resolve(handleResponse(response))
            })
            .catch((err) => {
                reject(handleError(err, url))
            })
    })
}

const handleResponse = (response) => {
    return response.data
}

const handleError = (err, url = null) => {
    if (err.response) {
        console.log("" +
            "─────────────── ERROR ───────────\n" +
            "─────────▄██████████████████▄─────\n" +
            "────────██▀░░░░░░░░░░░░░░░░▀██▄───\n" +
            "───────█▌░░▄░░░░░░░▀▄▄▀░░░░░░▀█───\n" +
            "──────█▌░░░▀███▄░░▀▄▄▄▄▀░░░░░░▀█──\n" +
            "─────█▌░░░░█▀──▀▄░░░░░░░░░▄▄█▀░▐█─\n" +
            "────█▌░░░░░█─────█░▄▄▄░░▄▀▀▀▀▄░░▐█\n" +
            "───█▌░░░░░░█──█──█░░░░░█─────█░░░█\n" +
            "──█▌░░░░░░░░▀▄▄▄▀░░░░░░█──█──█░░░█\n" +
            "─█▌░░░░░░░░░░░░░░▄░░▄░░░▀▄▄▄▀░░░░█\n" +
            "█▌░░░░▄▀▀▄░░░░░▀▀░░░░▀▀░░░░░░░░░░█\n" +
            "█░░░░░▀▄░░░░░░░▄▀░▀▄░░░░░▄▀▀▄░░░░█\n" +
            "█░▐░░░░░▀▀▀▀▀▀▀░░░░░▀▀▄▄▄▄▄▄▀░░▌░█\n" +
            "█░░▌▄░░░░░░▄█▀▀█▀▀█▀▄░░░░░░░▄░▐░░█\n" +
            "█░░▌█▀█▀▀█▀─█──█──█──█▀▀█▀▀██░▐░░█\n" +
            "█░▐░█▄█▄▄█▄▄█▄▄█▄▄█▄▄█▄▄█▄██░░▐░░█\n" +
            "█░░░███████████████████████░░░░▌░█\n" +
            "█░░░█▄─█──█──█──█──█──█──█▀░░░░░░█\n" +
            "█░░░░▀██▄▄█▄▄█▄▄█▄▄█▄▄█▄█▀░░░░░░▐█\n" +
            "█▌░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▄█▀\n" +
            "─█▄░░░░░░░░░░░░░░░░░░░░░░░░░░░▄█──\n" +
            "──█▄░░░░░░░▀▄▄▄▄▄▄▄▀░░░░░░░░░▄█───\n" +
            "───██▄░░░░░░░░░░░░░░░░░░░░░▄██────\n" +
            "────▀██▄░░░░░░░░░░░░░░░░░▄██▀─────\n" +
            "──────▀███████████████████▀───────");


        if (err.response) {
            console.log("============================DATA=================================");
            console.log(err.response.data);
            console.log("===========================STATUS================================");
            console.log(err.response.status);
            console.log("===========================HEADERS===============================");
            console.log(err.response.headers);
            console.log("============================OVER=================================");
        }
        return err
    }
}

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem("renova-chrome-jwt");
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });
