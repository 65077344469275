import React, {useState} from "react"
import {Button, Icon, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"
import ClientsAddEditContact from "./ClientsAddEditContact";
import {withRouter} from "react-router-dom"

const ClientContacts = ({
                            selectedClient,
                            handleDeleteContact,
                            handleSetTriggerRefetch,
                        }) => {

    const [contactModalMode, setContactModalMode] = useState(null)
    const [selectedContact, setSelectedContact] = useState(null)

    const handleOpenEditCreateContactModal = (contact) => {
        if (contact) {
            setContactModalMode("edit")
            setSelectedContact(contact)
        } else {
            setContactModalMode("create")
            setSelectedContact({prenom: "", nom: "", genre: "", email: "", telephone: ""})
        }
    }

    const handleCloseContactModal = () => {
        setContactModalMode(null)
        setSelectedContact(null)
    }

    return (
        <Paper className={'clients-contacts-wrapper'}>
            <div className={"clients-contacts-wrapper-title"}>
                <Icon style={{fontSize:18, color:"#1079FC", marginRight:10, marginLeft:4}}>contacts</Icon>
                Contacts
                <div style={{flex:1}}/>

                <Button variant={"outlined"} style={{color:"#1079FC", borderColor:"#1079FC"}}
                        size={"small"}
                        onClick={() => handleOpenEditCreateContactModal()}
                >Ajouter un contact</Button>
            </div>
            <div style={{height: "1px", width: "100%", backgroundColor: "#CCCCCC", marginBottom: "12px"}}/>
            <TableContainer component={Paper} className={"clients-contacts-wrapper-table"}>
                <Table size="small" style={{border:"1px solid #EFEFEF"}}>
                    <TableHead>
                        <TableRow className={"clients-contacts-wrapper-table-head"}>
                            <TableCell style={{backgroundColor:"#FAFAFA"}}>Nom</TableCell>
                            <TableCell style={{backgroundColor:"#FAFAFA"}}>Email</TableCell>
                            <TableCell style={{backgroundColor:"#FAFAFA"}}>Fax</TableCell>
                            <TableCell style={{backgroundColor:"#FAFAFA"}}>Téléphone</TableCell>
                            <TableCell style={{backgroundColor:"#FAFAFA"}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedClient && selectedClient.contacts && selectedClient.contacts.length > 0 && selectedClient.contacts.map((contact) => (
                            <TableRow
                                key={contact.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>{contact.genre} {contact.prenom} {contact.nom}</TableCell>
                                <TableCell>
                                    <a href={`mailto:${contact.email}`}>{contact.email}</a>
                                </TableCell>
                                <TableCell>{contact.fax}</TableCell>
                                <TableCell>
                                    <a href={`tel:${contact.telephone}`}>{contact.telephone}</a>
                                </TableCell>
                                <TableCell>
                                    <div>
                                        <IconButton
                                            size={"small"}
                                            onClick={() => handleOpenEditCreateContactModal(contact)}
                                            style={{}}>
                                            <Icon color={"#555555"} style={{fontSize:16}}>{"edit"}</Icon>
                                        </IconButton>
                                        <IconButton
                                            size={"small"}
                                            onClick={() => handleDeleteContact(contact.id)}
                                            style={{}}>
                                            <Icon color={"#555555"} style={{fontSize:16}}>{"delete"}</Icon>
                                        </IconButton>
                                    </div>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {contactModalMode && <ClientsAddEditContact mode={contactModalMode} clientId={selectedClient.id} contact={selectedContact} handleClose={handleCloseContactModal}
                                                            handleSetTriggerRefetch={handleSetTriggerRefetch}/>}
            </TableContainer>
        </Paper>
    )
}

export default withRouter(ClientContacts)
