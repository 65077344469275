import React, {useState} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent, TextField, Box, Select, MenuItem} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createContact, editContact} from "./api/ClientAPI";
import toast from "react-hot-toast"

const ClientsAddEditContact = ({clientId, contact, mode, handleClose, handleSetTriggerRefetch}) => {

    const [nom, setNom] = useState(contact.nom)
    const [prenom, setPrenom] = useState(contact.prenom)
    const [genre, setGenre] = useState(contact.genre)
    const [email, setEmail] = useState(contact.email)
    const [telephone, setTelephone] = useState(contact.telephone)

    const handleSetNom = (value) => {
        setNom(value)
    }

    const handleSetPrenom = (value) => {
        setPrenom(value)
    }

    const handleSetGenre = (e) => {
        setGenre(e.target.value)
    }

    const handleSetEmail = (value) => {
        setEmail(value)
    }

    const handleSetTelephone = (value) => {
        setTelephone(value)
    }

    const handleCreateEditContact = async () => {
        const payload = {
            genre: genre,
            nom: nom,
            prenom: prenom,
            email: email,
            telephone: telephone
        }
        if (mode === "create") {
            try {
                await createContact(payload, clientId)
                handleClose()
                handleSetTriggerRefetch()
                toast.success("Le contact a été créé avec succès")
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la création du contact")
            }
        } else if (mode === "edit") {
            try {
                await editContact(payload, contact.id)
                handleClose()
                handleSetTriggerRefetch()
                toast.success("Le client a été modifiée avec succès")
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la modification du contact")
            }
        }
    }

    return (
        <Dialog open={contact} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>{mode === "create" ? "Création d'un contact" : "Edition d'un contact"}</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <Select
                        style={{marginBottom: "12px"}}
                        value={genre}
                        label="Age"
                        onChange={handleSetGenre}
                    >
                        <MenuItem value={"M."}>M. </MenuItem>
                        <MenuItem value={"Mme."}>Mme.</MenuItem>
                    </Select>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={prenom}
                        onChange={(e) => handleSetPrenom(e.target.value)}
                        variant="outlined"
                        label={"Prénom"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={nom}
                        onChange={(e) => handleSetNom(e.target.value)}
                        variant="outlined"
                        label={"Nom"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={email}
                        onChange={(e) => handleSetEmail(e.target.value)}
                        variant="outlined"
                        label={"Email"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={telephone}
                        onChange={(e) => handleSetTelephone(e.target.value)}
                        variant="outlined"
                        label={"Telephone"}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleCreateEditContact()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ClientsAddEditContact