import {APIget, APIpost, APIput, APIpatch, APIdelete} from "./../../../services/API"
import axios from 'axios'
import {baseURL} from "../../../shared/utils/Utils";
require('dotenv').config();

export const login = (payload) => {
    return new Promise((resolve, reject) => {
        let jwt;
        APIpost(`/api/login_check`,{
            username:payload.username,
            password:payload.password
        })
            .then((response) => {
                jwt = response.token;
                return axios.get(baseURL() +'api/user/self', {
                    headers: {
                        "Authorization": "Bearer " + jwt
                    }
                })
            })
            .then((response)=>{
                resolve({
                    user:response.data,
                    jwt:jwt,
                })
            })
            .catch((error) => {
                reject(error);
            })
    });
}
