import React, {useState, useEffect} from "react"
import "./OperationsBill.scss"
import OperationsBillList from "./OperationsBillList";
import {getPendingBills} from "../api/OperationsAPI";
import {filterData, removeDuplicateItems} from "../../../shared/utils/Utils";
import dayjs from "dayjs";

const OperationsBill = () => {
    const [sortColumn, setSortColumn] = useState("id")
    const [filterInputValue, setFilterInputValue] = useState("")
    const [sortOption, setSortOption] = useState("increasing")
    const [initialData, setInitialData] = useState(null)
    const [data, setData] = useState(null)
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const [orderIdHovered, setOrderIdHovered] = useState(null)
    const [triggerRefetch, setTriggerRefetch] = useState(false)

    useEffect(() => {
        handleGetPendingBills()
            .then((res) => {
                const filteredOperations = res.operations.filter((op) => !op.facture_date_paiement)
                res.operations = filteredOperations
                setInitialData(groupOperationsByBill(res.operations))
                setData(groupOperationsByBill(res.operations))
            })
    }, [triggerRefetch])

    useEffect(() => {
        if (initialData && filterInputValue.length > 0) {
            const filteredData = filterData(initialData, filterInputValue)
            setData(filteredData)
        }
    }, [filterInputValue])

    const groupOperationsByBill = (operations) => {
        const result = removeDuplicateItems(operations, 'facture_id').map((item) => ({
            facture_id: item.facture_id,
            factureNumber: dayjs(item.dateCreation).format("YYYYMM") + item.facture_numero,
            dateFacture: item.dateFacture,
            clientName: item.nom,
            operations: []
        }))
        for (let bill of result) {
            for (let operation of operations) {
                if (bill.facture_id === operation.facture_id) {
                    bill.operations.push(operation)
                }
            }
            bill.prixHT = bill.operations.length > 0 ? bill.operations[0].facture_prixHT : 0
            bill.prixHTPlusDepollution = bill.operations.length > 0 ? bill.operations[0].facture_prixHTPlusDepollution : 0
            bill.prixTTC = bill.operations.length > 0 ? bill.operations[0].facture_prixTTC : 0
        }
        return result
    }

    const handleTriggerRefetch = () => {
        setTriggerRefetch(!triggerRefetch)
    }

    useEffect(() => {
        if (data) {
            let sortedData = data.slice()
            if (typeof data[0][sortColumn] === "string") {
                if (sortOption === "increasing") {
                    sortedData = sortedData.sort((a, b) => a[sortColumn].localeCompare(b[sortColumn]))
                } else {
                    sortedData = sortedData.sort((a, b) => b[sortColumn].localeCompare(a[sortColumn]))
                }

            } else if (typeof data[0][sortColumn] === "number") {
                if (sortOption === "increasing") {
                    sortedData = sortedData.sort((a, b) => a[sortColumn] - b[sortColumn])
                } else {
                    sortedData = sortedData.sort((a, b) => b[sortColumn] - a[sortColumn])
                }

            }
            setData(sortedData)
        }
    }, [sortColumn, sortOption])

    const handleGetPendingBills = async () => {
        return await getPendingBills()
    }

    const handleSortCatChange = (e) => {
        setSortColumn(e.target.value)
    }

    const handleFilterInputValueChange = (e) => {
        setFilterInputValue(e.target.value)
    }

    const handleSortOptionChange = () => {
        if (sortOption === "increasing") {
            setSortOption("decreasing")
        } else {
            setSortOption("increasing")
        }
    }

    const handleToggleModalState = (event, orderId) => {

        if (event && orderId) {
            setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget)
            setOrderIdHovered(orderId ? orderId : null)
        }
        else {
            setPopperAnchorEl(null)
            setOrderIdHovered(null)
        }
    }

    const categories = [
        {
            title: "Numéro de facture",
            key: "id"
        },
        {
            title: "Date de création",
            key: "createdAt"
        },
        {
            title: "Total HT",
            key: "amount"
        },
        {
            title: "Opérations",
            key: "operationsIds"
        },
        {
            title: "Client",
            key: "customer"
        },
        {
            title: "Actions",
            key: "actions"
        },
    ]

    return (
        <OperationsBillList sortColumn={sortColumn} handleSortCatChange={handleSortCatChange} categories={categories} handleSortOptionChange={handleSortOptionChange}
                            sortOption={sortOption} handleFilterInputValueChange={handleFilterInputValueChange} filterInputValue={filterInputValue} data={data}
                            popperAnchorEl={popperAnchorEl} toggleModal={handleToggleModalState} orderIdHovered={orderIdHovered} triggerRefetch={handleTriggerRefetch}/>
    )
}

export default OperationsBill

