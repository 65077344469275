import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent, TextField, Box} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createFinish} from "../api/ProductsAPI";
import toast from "react-hot-toast";

const ProductsAddFinishModal = ({isOpen, handleClose, triggerRefetch, setTriggerRefetch}) => {

    const [libelle, setLibelle] = useState("")
    const [diminutif, setDiminutif] = useState("")


    useEffect(() => {

    }, [])


    const handleSetLibelle = (value) => {
        setLibelle(value)
    }

    const handleSetDiminutif = (value) => {
        setDiminutif(value)
    }

    const handleCreateFinish = async () => {

        const payload = {
            libelle: libelle,
            diminutif: diminutif
        }
        try {
            await createFinish(payload)
            triggerRefetch()
            toast.success("La finition a été ajoutée avec succès")
            handleClose(null)
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la création de la finition")
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Création d'une finition</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={libelle}
                        onChange={(e) => handleSetLibelle(e.target.value)}
                        variant="outlined"
                        label={"Libellé"}
                    />
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={diminutif}
                        onChange={(e) => handleSetDiminutif(e.target.value)}
                        variant="outlined"
                        label={"Diminutif"}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleCreateFinish()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProductsAddFinishModal