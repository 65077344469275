import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Checkbox, DialogContent, FormControlLabel, TextField, Paper, IconButton, Icon, MenuItem, FormControl, InputLabel, Select} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createPlageDeValidite, editPlageDeValidite} from "../api/ProductsAPI";
import toast from "react-hot-toast"
import {Add} from "@mui/icons-material";


const FinishDetailsCreateEditPdv = ({isOpen, handleClose, handleSetTriggerRefetch, selectedPdv, matiereId}) => {

    const [developedType, setDevelopedType] = useState(selectedPdv ? selectedPdv.plage_de_validite_libelle : "Petit développé")
    const [unit, setUnit] = useState(selectedPdv ? selectedPdv.plage_de_validite_unite : "ml")
    const [tarifs, setTarifs] = useState(selectedPdv ?
        selectedPdv.tarifs :
        [
            {
                tarif_libelle: "",
                tarif_prix: "",
                tarif_id: null,
            }
        ])

    const handleSetDevelopedType = (value) => {
        setDevelopedType(value)
    }

    const handleSetUnit = (value) => {
        setUnit(value)
    }

    const handleCreateBC = async () => {
        if (tarifs.length === 0) {
            toast.error("Merci de saisir au un tarif pour cette plage de validité")
            return
        }
        if (selectedPdv) {
            const payload = {
                libelle: developedType,
                unite: unit,
                matiereId: matiereId,
                tarifs: tarifs
            }
            try {
                await editPlageDeValidite(selectedPdv.plage_de_validite_id, payload)
                handleClose()
                handleSetTriggerRefetch()
                toast.success("La plage de validité a été modifiée avec succès")
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la modification de la plage de validité")
            }
        } else if (!selectedPdv) {
            const payload = {
                libelle: developedType,
                unite: unit,
                matiereId: matiereId,
                tarifs: tarifs
            }
            try {
                await createPlageDeValidite(payload)
                handleClose()
                handleSetTriggerRefetch()
                toast.success("La plage de validité a été créée avec succès")
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la création de la plage de validité")
            }
        }
    }

    const handleEditTarifs = (value, index, type) => {
        const tarifsCopy = tarifs.slice()
        tarifsCopy[index][type] = value
        setTarifs(tarifsCopy)
    }

    const handleAddTarif = () => {
        const tarifsCopy = tarifs.slice()
        tarifsCopy.push({
                tarif_libelle: "",
                tarif_prix: "",
                tarif_id: null,
            }
        )
        setTarifs(tarifsCopy)
    }

    const handleFilterTarifs = (index) => {
        const tarifsCopy = tarifs.slice()
        tarifsCopy.splice(index, 1)
        setTarifs(tarifsCopy)
    }


    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>{selectedPdv ? "Modifier une plage de validité" : "Créer une plage de validité"}</DialogTitle>
            <DialogContent>
                <div className={"pdv-edit-modal-text-fields-wrapper"} style={{width: "800px"}}>

                    <FormControl fullWidth style={{marginBottom: "12px"}}>
                        <InputLabel id="developed-type">Type de développé</InputLabel>
                        <Select
                            labelId="developed-type"
                            value={developedType}
                            label="Type de développé"
                            onChange={(e) => handleSetDevelopedType(e.target.value)}
                        >
                            <MenuItem value={"Petit développé"}>Petit développé</MenuItem>
                            <MenuItem value={"Grand développé"}>Grand développé</MenuItem>
                            <MenuItem value={"A la surface"}>A la surface</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth style={{marginBottom: "12px"}}>
                        <InputLabel id="unit">Unité utilisée pour le calcul</InputLabel>
                        <Select
                            labelId="unit"
                            value={unit}
                            label="Unité utilisée pour le calcul"
                            onChange={(e) => handleSetUnit(e.target.value)}
                        >
                            <MenuItem value={"m2"}>mètre carré</MenuItem>
                            <MenuItem value={"ml"}>mètre linéaire</MenuItem>
                        </Select>
                    </FormControl>
                    <div style={{border: "solid 1px #CCCCCC", borderRadius: 4, padding: 10}}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 16}}>
                            <div style={{fontSize: 16}}>Tarifs par face</div>
                            <div style={{flex: 1}}/>
                            <Button variant={"contained"} size="small" style={{marginLeft: 10}} onClick={() => handleAddTarif()} startIcon={<Add/>}>
                                Ajouter un tarif
                            </Button>
                        </div>
                        {tarifs.map((tarif, i) => {
                            return (
                                <div className={"pdv-edit-modal-text-fields-wrapper-tarifs-wrapper"}>
                                    <TextField
                                        style={{width: "60%"}}
                                        value={tarif.tarif_libelle}
                                        onChange={(e) => handleEditTarifs(e.target.value, i, "tarif_libelle")}
                                        variant="outlined"
                                        label={"Libellé de ce tarif"}
                                    />
                                    <TextField
                                        style={{width: "25%"}}
                                        value={tarif.tarif_prix}
                                        onChange={(e) => handleEditTarifs(e.target.value, i, "tarif_prix")}
                                        variant="outlined"
                                        label={"Prix HT"}
                                    />
                                    <IconButton>
                                        <Icon color={"primary"} onClick={() => handleFilterTarifs(i)}>{"delete"}</Icon>
                                    </IconButton>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleCreateBC()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FinishDetailsCreateEditPdv