import React, {useEffect, useState} from "react"
import {Link, withRouter} from "react-router-dom"
import {filterData, numberWithCommas} from "../../../shared/utils/Utils";
import {Button, Icon, IconButton, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import dayjs from "dayjs";
import undraw_the_search_s0xf from "../../../shared/undraw/undraw_the_search_s0xf.svg"

const UniqueArticleSearchResultsList = (props) => {
    const [pageNumber, setPageNumber] = useState(1)

    const {results, filterInputValue, handleFilterInputValueChange} = props

    const categories = [
        {
            title: "Libellé",
            key: "label"
        },
        {
            title: "Prix HT",
            key: "prixHT"
        },
        {
            title: "Quantité",
            key: "quantity"
        },
        {
            title: "Matière",
            key: "finish"
        },
        // {
        //     title: "Développé",
        //     key: "developed"
        // },
        {
            title: "Opération",
            key: "operation"
        },
    ]

    const startRange = (pageNumber - 1) * 7
    const endRange = pageNumber * 7

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    return (
        results ?
            <>
                <div className={"unique-article-search-wrapper-header"}>
                    <div className={"unique-article-search-wrapper-header-counts"}>
                                <span><strong style={{
                                    color: "#1079FC",
                                    borderRadius: 4,
                                    backgroundColor: "#D9EDF7",
                                    display: "inline-block",
                                    paddingLeft: 5,
                                    paddingRight: 5
                                }}>{results.length}</strong> {results.length > 1 ? "résultats" : "résultat"}</span>

                    </div>
                    <div className={"unique-article-search-wrapper-header-filter-sort"}>

                        <TextField className={"operations-production-wrapper-header-filter-input"} size="small"
                                   label={"Rechercher"}
                                   variant="outlined" onChange={handleFilterInputValueChange}
                                   value={filterInputValue}/>
                    </div>
                </div>
                <div className={"unique-article-search-wrapper-content"}>
                    <TableContainer component={Paper} className={"unique-article-search-wrapper-content-table"}>
                        <Table stickyHeader={true} style={{border: "1px solid #EFEFEF"}}>
                            <TableHead>
                                <TableRow>
                                    {categories.map((category) => {
                                        return (
                                            <TableCell style={{backgroundColor: "#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results.slice(startRange, endRange).map((result, i) => (
                                    <TableRow
                                        onClick={() => props.history.push(`/operations/operation/detail/${result.operation_id}`)}
                                        key={i}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>
                                            {result.article_commande_libelle}
                                        </TableCell>
                                        <TableCell>{result.prixHT}</TableCell>
                                        <TableCell>{result.quantite}</TableCell>
                                        <TableCell>{result.libelle}</TableCell>
                                        {/*<TableCell>Waiting for back-end result</TableCell>*/}
                                        <TableCell>
                                            <div>
                                                Total HT: {parseFloat(result.totalHT).toFixed(2)} €
                                            </div>
                                            <div>
                                                Date de création: {dayjs(result.dateCreation).format("DD/MM/YYYY")}
                                            </div>
                                            <div>
                                                Etat: {result.statut}
                                            </div>
                                            <div>
                                                Client: {result.nom}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={"unique-article-search-content-pagination"}>
                        <Pagination count={Math.ceil(results.length / 7)} onChange={handlePageChange} page={pageNumber}/>
                    </div>
                </div>
            </>
            :
            <div className={"unique-article-search-wrapper-empty-content"}>
                <img className={"unique-article-search-wrapper-empty-content-undraw"} src={undraw_the_search_s0xf}/>
                <p>Saisissez des valeurs pour lancer une recherche</p>
            </div>

    )
}

export default withRouter(UniqueArticleSearchResultsList)