import React, {useState, useEffect} from "react"
import "./OperationsDelivery.scss"
import OperationsDeliveryList from "./OperationsDeliveryList";
import {getOrdersInDelivery} from "../api/OperationsAPI";
import {filterData, matchOperationsWithFinish} from "../../../shared/utils/Utils";

const OperationsDelivery = () => {
    const [sortColumn, setSortColumn] = useState("id")
    const [filterInputValue, setFilterInputValue] = useState("")
    const [sortOption, setSortOption] = useState("increasing")
    const [initialData, setInitialData] = useState(null)
    const [data, setData] = useState(null)
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const [orderIdHovered, setOrderIdHovered] = useState(null)
    const [triggerRefresh, setTriggerRefresh] = useState(false)

    useEffect(() => {
        handleGetOrdersInDelivery()
            .then((res) => {
                const operationsWithFinish = matchOperationsWithFinish(res)
                setInitialData(res.operations)
                setData(res.operations)
            })
    }, [])

    useEffect(() => {
        if (initialData) {
           const filteredData = filterData(initialData, filterInputValue)
            setData(filteredData)
        }
    }, [filterInputValue])

    useEffect(() => {
        if (data) {
           let sortedData = data.slice()
            if (typeof data[0][sortColumn] === "number" || sortColumn === "prixHTPlusDepollution") {
                if (sortOption === "increasing") {
                    sortedData = sortedData.sort((a, b) => a[sortColumn] - b[sortColumn])
                } else {
                    sortedData = sortedData.sort((a, b) => b[sortColumn] - a[sortColumn])
                }
            } else if (typeof data[0][sortColumn] === "string") {
                if (sortOption === "increasing") {
                    sortedData = sortedData.sort((a, b) =>
                        a[sortColumn].localeCompare(b[sortColumn]))
                } else {
                    sortedData = sortedData.sort((a, b) => b[sortColumn].localeCompare(a[sortColumn]))
                }
            }
            setData(sortedData)
        }
    }, [sortColumn, sortOption])

    const handleGetOrdersInDelivery = async () => {
        return await getOrdersInDelivery()
    }

    const handleSetTriggerRefresh = () => {
        setTriggerRefresh(!triggerRefresh)
    }

    const handleSortCatChange = (e) => {
        setSortColumn(e.target.value)
    }

    const handleFilterInputValueChange = (e) => {
        setFilterInputValue(e.target.value)
    }

    const handleSortOptionChange = () => {
        if (sortOption === "increasing") {
            setSortOption("decreasing")
        } else {
            setSortOption("increasing")
        }
    }

    const handleToggleModalState = (event, orderId) => {
        if (event) {
            setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget)
            setOrderIdHovered(orderId ? orderId : null)
        } else {
            setPopperAnchorEl(null)
            setOrderIdHovered(null)
        }
    }

    const categories = [
        {
            title: "Ref.",
            key: "id"
        },
        {
            title: "Actions",
            key: "actions"
        },
        {
            title: "Référence client",
            key: "numeroCommandeClient"
        },
        {
            title: "Client",
            key: "nom"
        },
        {
            title: "Finitions",
            key: "finish"
        },
        {
            title: "Date de production",
            key: "dateProduction"
        },
        {
            title: "Date de livraison prévisionnelle",
            key: "dateRendu"
        },
        {
            title: "Total HT",
            key: "prixHTPlusDepollution"
        }
    ]

    return (
        <OperationsDeliveryList sortColumn={sortColumn} handleSortCatChange={handleSortCatChange} categories={categories} handleSortOptionChange={handleSortOptionChange}
                                sortOption={sortOption} handleFilterInputValueChange={handleFilterInputValueChange} filterInputValue={filterInputValue} data={data}
                                popperAnchorEl={popperAnchorEl} toggleModal={handleToggleModalState} orderIdHovered={orderIdHovered} triggerRefresh={handleSetTriggerRefresh}/>
    )
}

export default OperationsDelivery

