import React from "react"
import "../style/Header.scss"
import SuperSearch from "./SuperSearch"
import {IconButton, Icon} from "@mui/material"

const Header = (props) => {
    return(
        <div className={"header-wrapper"}>
            <div className="header-wrapper-title">
                    <IconButton
                        onClick={()=>{props.history.goBack()}}
                        style={{}}>
                        <Icon style={{color:'#FFFFFF'}}>{"arrow_back"}</Icon>
                    </IconButton>
                <div>{props?.location?.state?.title || "Accueil"}</div>
            </div>
            <SuperSearch/>
        </div>
    )
}

export default Header