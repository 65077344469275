import React, {useState} from "react"
import {
    Icon,
    IconButton,
    Button,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import Loader from "../../../shared/loader/Loader";
import {withRouter, Link} from "react-router-dom";
import "./Providers.scss"
import ProvidersCreateEditProvider from "./ProvidersCreateEditProvider";

const ProvidersList = ({
                           categories,
                           handleFilterInputValueChange,
                           filterInputValue,
                           data,
                           triggerRefresh,
                           history
                       }) => {

    const [pageNumber, setPageNumber] = useState(1)
    const [isNewProviderModalOpen, setIsNewProviderModalOpen] = useState(false)

    const startRange = (pageNumber - 1) * 12
    const endRange = pageNumber * 12

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const handleClickOnProvider = (providerId) => {
        history.push(`/providers/list/${providerId}`)
    }

    const handleToggleIsNewProviderModalOpen = () => {
        setIsNewProviderModalOpen(!isNewProviderModalOpen)
    }

    return (
        <Paper className={"providers-list-wrapper"}>
            {data ?
                (
                    <>
                        <div className={"providers-list-wrapper-header"}>
                            <div className={"providers-list-wrapper-header"}>
                                <span><strong style={{
                                    color: "#1079FC",
                                    borderRadius: 4,
                                    backgroundColor: "#D9EDF7",
                                    display: "inline-block",
                                    paddingLeft: 5,
                                    paddingRight: 5
                                }}>{data.length}</strong> {data.length > 1 ? "fournisseurs répertoriés" : "fournisseur répertorié"}</span>
                            </div>
                            <div className={"providers-list-wrapper-header-filter"}>
                                <Button variant={"contained"}
                                        style={{marginRight:"10px"}}
                                        onClick={() => handleToggleIsNewProviderModalOpen()}
                                    >
                                    Ajouter un fournisseur
                                </Button>
                                <TextField className={"providers-list-wrapper-header-filter-input"} size="small"
                                           label={"Rechercher un fournisseur"}
                                           variant="outlined" onChange={handleFilterInputValueChange}
                                           value={filterInputValue}/>
                            </div>
                        </div>
                        <div className={"providers-list-wrapper-content"}>
                            <TableContainer component={Paper} className={"providers-list-wrapper-content-table"}>
                                <Table stickyHeader={true} style={{border: "1px solid #EFEFEF"}}>
                                    <TableHead>
                                        <TableRow>
                                            {categories.map((category) => {
                                                return (
                                                    <TableCell style={{backgroundColor: "#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice(startRange, endRange).map((provider) => (
                                            <TableRow
                                                onClick={() => handleClickOnProvider(provider.id)}
                                                key={provider.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>{provider.nom}</TableCell>
                                                <TableCell>{provider.adresse} {provider.codePostal} {provider.ville}</TableCell>
                                                <TableCell>{provider.siret ? provider.siret : "/"}</TableCell>
                                                <TableCell>{provider.telephone ? provider.telephone : "/"}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={"providers-list-wrapper-wrapper-content-pagination"}>
                                <Pagination count={Math.ceil(data.length / 12)} onChange={handlePageChange} page={pageNumber}/>
                            </div>
                        </div>
                    </>
                )
                :
                <div className={"providers-list-wrapper-content-loader-wrapper"}>
                    <Loader/>
                </div>
            }
            {
                isNewProviderModalOpen &&
                  <ProvidersCreateEditProvider handleClose={handleToggleIsNewProviderModalOpen} handleSetTriggerRefetch={triggerRefresh} isOpen={isNewProviderModalOpen}/>
            }
        </Paper>
    )
}

export default withRouter(ProvidersList)
