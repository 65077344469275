import React, {useState, useEffect} from "react"
import {
    Icon,
    IconButton,
    Button,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import StatsByFinishGroup from "./StatsByFinishGroup";
import StatsByClient from "./StatsByClient";
import "./Stats.scss"
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import dayjs from "dayjs";
import {getGroupsFinishStatistics, getClientsStatistics, getMonthlyTurnover} from "../api/StatsAPI";
import StatsMonthlyTurnover from "./StatsMonthlyTurnover";

const Statistics = () => {

    const [startDate, setStartDate] = useState(dayjs().subtract(5, 'month'))
    const [endDate, setEndDate] = useState(dayjs())
    const [groupsFinishStats, setGroupsFinishStats] = useState(null)
    const [monthlyTurnover, setMonthlyTurnover] = useState(null)
    const [clientsStats, setClientsStats] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        handleGetGroupsFinishStatistics()
        handleGetClientsStatistics()
        handleGetMonthlyTurnover()
    }, [startDate, endDate])

    const handleGetGroupsFinishStatistics = async () => {
        setIsLoading(true)
        const payload = {
            start: dayjs(startDate).format("YYYY-MM-DDT00:00:00+00:00"),
            end: dayjs(endDate).format("YYYY-MM-DDT00:00:00+00:00"),
        }
        try {
            const stats = await getGroupsFinishStatistics(payload)
            setGroupsFinishStats(stats)
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const handleGetClientsStatistics = async () => {
        setIsLoading(true)
        const payload = {
            start: dayjs(startDate).format("YYYY-MM-DDT00:00:00+00:00"),
            end: dayjs(endDate).format("YYYY-MM-DDT00:00:00+00:00"),
        }
        try {
            const stats = await getClientsStatistics(payload)
            setClientsStats(stats)
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const handleGetMonthlyTurnover = async () => {
        setIsLoading(true)
        const payload = {
            start: dayjs(startDate).format("YYYY-MM-DDT00:00:00+00:00"),
            end: dayjs(endDate).format("YYYY-MM-DDT00:00:00+00:00"),
        }
        try {
            const stats = await getMonthlyTurnover(payload)
            setMonthlyTurnover(stats)
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }


    const handleSetStartDate = (value) => {
        setStartDate(value)
    }

    const handleSetEndDate = (value) => {
        setEndDate(value)
    }

    return (
        <>
            <div className={'stats-date-wrapper'} style={{paddingTop:8}}>
                <div style={{marginRight: "10px"}}>Statistiques du</div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        style={{width:240}}
                        label="Date de début"
                        inputFormat="dd/MM/yyyy"
                        value={startDate}
                        onChange={(newValue) => handleSetStartDate(dayjs(newValue).format("YYYY-MM-DD"))}
                        renderInput={(params) => <TextField style={{backgroundColor:"#FFFFFF"}} {...params} size={"small"}/>}
                    />
                    <div style={{marginRight: "10px", marginLeft: "10px"}}>au</div>
                    <DesktopDatePicker
                        style={{width:240}}
                        label="Date de fin"
                        inputFormat="dd/MM/yyyy"
                        value={endDate}
                        onChange={(newValue) => handleSetEndDate(dayjs(newValue).format("YYYY-MM-DD"))}
                        renderInput={(params) => <TextField style={{backgroundColor:"#FFFFFF"}} {...params} size={"small"}/>}
                    />
                </LocalizationProvider>
            </div>
            <div style={{width:"100%", padding:8}}>
                <StatsByFinishGroup stats={groupsFinishStats} isLoading={isLoading}/>
                <StatsByClient stats={clientsStats} isLoading={isLoading}/>
                <StatsMonthlyTurnover turnover={monthlyTurnover} isLoading={isLoading}/>
            </div>

        </>
    )

}

export default Statistics
