import React, {useState} from "react"
import {
    Pagination,
    Paper,
    Table,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, IconButton, Icon
} from "@mui/material";
import dayjs from 'dayjs'
import Loader from "../../../shared/loader/Loader";
import {numberWithCommas} from "../../../shared/utils/Utils";
import ProductsAddSpecificConditionModal from "./ProductsAddSpecificConditionModal";
import {deleteSpecificCondition} from "../api/ProductsAPI";
import toast from "react-hot-toast"


const ProductsAllSpecificConditionsList = ({
                                               handleFilterInputValueChange,
                                               filterInputValue,
                                               data,
                                               triggerRefetch
                                           }) => {

    const [pageNumber, setPageNumber] = useState(1)
    const [selectedCondition, setSelectedCondition] = useState(null)
    const [modalMode, setModalMode] = useState(null)

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const handleEditAddCondition = (condition) => {
        if (condition) {
            setSelectedCondition(condition)
            setModalMode("edit")
        } else {
            setSelectedCondition({
                libelle: "",
                texteWarning: "",
                pourcentage: ""
            })
            setModalMode("create")
        }
    }

    const handleCloseModal = () => {
        setModalMode(null)
        setSelectedCondition(null)
    }

    const categories = [
        {
            title: "Libellé",
            key: ""
        },
        {
            title: "Pourcentage par défaut à appliquer",
            key: ""
        },
        {
            title: "Actions",
            key: ""
        },
    ]

    const handleDeleteSpecificCondition = async (conditionId) => {
        try{
            await deleteSpecificCondition(conditionId)
            toast.success("La condition spécifique a été supprimée avec succès")
            triggerRefetch()
        } catch(err){
            console.log(err.response)
            if(err?.response?.data?.catched_exception){
                toast.error(err.response.data.catched_exception)
            } else {
                toast.error("Erreur durant la suppression de la condition spécifique")
            }
        }
    }

    const startRange = (pageNumber - 1) * 10
    const endRange = pageNumber * 10

    return (
        <dib className={"products-general-all-products-wrapper"}>

            <>
                <div className={"products-general-all-products-wrapper-header"}>
                    <div className={"products-general-all-products-wrapper-header-title"}>Liste
                        des {data.length} {data.length > 1 ? "conditions spécifiques" : "condition spécifique"} présentes en
                        base
                    </div>
                    <div style={{flex:1}}/>
                    <div className={"products-general-all-products-wrapper-header-filter-sort"}>
                        <Button variant={"contained"} style={{backgroundColor:"#1079FC", color:"#FFFFFF"}}
                                onClick={() => handleEditAddCondition()}>Ajouter une condition spécifique</Button>
                        <TextField className={"products-general-all-products-wrapper-header-filter-input"} size="small"
                                   label={"Rechercher"}
                                   variant="outlined" onChange={handleFilterInputValueChange}
                                   value={filterInputValue}/>
                    </div>

                </div>
                <div className={"products-general-all-products-wrapper-content"}>
                    <TableContainer style={{minHeight: "475px", maxHeight: "475px"}} component={Paper} className={"products-general-all-products-wrapper-content-table"}>
                        <Table size={"small"} style={{border:"1px solid #EFEFEF"}}>
                            <TableHead>
                                <TableRow>
                                    {categories.map((category) => {
                                        return (
                                            <TableCell style={{backgroundColor:"#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.slice(startRange, endRange).map((sc) => (
                                    <TableRow
                                        key={sc.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell style={{width: "45%"}}>{sc.libelle}</TableCell>
                                        <TableCell style={{width: "45%"}}>{sc.pourcentage}%</TableCell>
                                        <TableCell style={{width: "10%"}}>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => handleEditAddCondition(sc)}
                                                style={{}}>
                                                <Icon color={"#555555"}>{"edit"}</Icon>
                                            </IconButton>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => handleDeleteSpecificCondition(sc.id)}
                                                style={{}}>
                                                <Icon color={"#555555"}>{"delete"}</Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={"products-general-all-products-wrapper-content-pagination"}>
                        {/*<Pagination/>*/}
                        <Pagination count={Math.ceil(data.length / 10)} onChange={handlePageChange} page={pageNumber}/>
                    </div>
                </div>
                {modalMode &&
                <ProductsAddSpecificConditionModal isOpen={modalMode} handleClose={handleCloseModal} selectedCondition={selectedCondition} modalMode={modalMode}
                                                   triggerRefetch={triggerRefetch}/>}
            </>
        </dib>
    )
}

export default ProductsAllSpecificConditionsList
