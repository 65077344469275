import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
    DialogContent,
    TextField,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Alert, IconButton, Icon
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {getArticleInformations, getTarifsAndDeveloped, createArticleCommande} from "../api/OperationsAPI";
import {getAllSpecificConditions} from "../../products/api/ProductsAPI";
import toast from "react-hot-toast";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

const OperationDetailsCreateSerieModal = ({article, isOpen, handleClose, ordre, triggerRefetch, operationId}) => {

    const [specificConditionsIds, setSpecificConditionsIds] = useState([])
    const [articlesToCreate, setArticlesToCreate] = useState([{
        length: article.valeur_longueur,
        thickness: article.valeur_epaisseur,
        quantity: 1
    }])
    const [pointsSeuilsEpaisseur, setPointsSeuilsEpaisseur] = useState(null)
    const [pointsSeuilsLongueur, setPointsSeuilsLongueur] = useState(null)
    const [prixHT, setPrixHT] = useState([article.prixHT])

    useEffect(() => {
        if (article) {
            handleGetArticleInformations()
        }
    }, [article])

    useEffect(() => {
        if (articlesToCreate.length === 0) {
            handleClose()
            return
        }
        if (pointsSeuilsEpaisseur && pointsSeuilsLongueur && articlesToCreate) {
            handleGetFinalPrice()
        }
    }, [articlesToCreate])


    const handleSetQuantite = (value, index) => {
        const articlesToCreateCopy = articlesToCreate.slice()
        articlesToCreateCopy[index].quantity = value
        setArticlesToCreate(articlesToCreateCopy)
    }

    const handleSetLength = (value, index) => {
        const articlesToCreateCopy = articlesToCreate.slice()
        articlesToCreateCopy[index].length = value
        setArticlesToCreate(articlesToCreateCopy)
    }

    const handleSetThickness = (value, index) => {
        const articlesToCreateCopy = articlesToCreate.slice()
        articlesToCreateCopy[index].thickness = value
        setArticlesToCreate(articlesToCreateCopy)
    }

    const handleCreateSerie = async () => {
        let i = 0
        try {
            for (let art of articlesToCreate) {
                const payload = {
                    libelle: article.libelle,
                    quantite: art.quantity,
                    articleId: article.article_id,
                    matiereId: article.matiere_id,
                    prixHT: prixHT[i],
                    ordre: ordre,
                    conditionsSpecifiqueIds: specificConditionsIds,
                    valeurEpaisseur: art.thickness.toString(),
                    valeurLongueur: art.length.toString(),
                    valeurDeveloppe: article.valeur_developpe.toString(),
                    tarifId: article.tarif_id
                }
                await createArticleCommande(payload, operationId)
                i++
                ordre++
            }
            triggerRefetch()
            toast.success("La série a été créée avec succès")
            handleClose(null)
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la création de la sérié")
        }

    }

    const handleAddFields = () => {
        const articlesToCreateCopy = articlesToCreate.slice()
        const prixHTCopy = prixHT.slice()
        articlesToCreateCopy.push({length: article.valeur_longueur, thickness: article.valeur_epaisseur, quantity: 1})
        prixHTCopy.push(article.prixHT)
        setPrixHT(prixHTCopy)
        setArticlesToCreate(articlesToCreateCopy)
    }

    const handleDeleteFields = (index) => {
        const articlesToCreateCopy = articlesToCreate.slice().filter((art, i) => i !== index)
        const prixHTCopy = prixHT.slice().filter((prix, i) => i !== index)
        setPrixHT(prixHTCopy)
        setArticlesToCreate(articlesToCreateCopy)
    }

    const handleGetArticleInformations = async () => {
        const articleInformations = await getArticleInformations(article.article_id)
        for (let dimension of articleInformations.dimensions) {
            dimension.value = ""
        }
        setPointsSeuilsEpaisseur(articleInformations.pointSeuilsEpaisseur.sort((a, b) => a.point - b.point))
        setPointsSeuilsLongueur(articleInformations.pointSeuilsLongueur.sort((a, b) => a.point - b.point))
    }

    const handleGetFinalPrice = () => {
        if (articlesToCreate && articlesToCreate.length > 0) {
            let prixHTCopy = prixHT.slice()
            let i = 0
            for (let art of articlesToCreate) {
                if (art) {
                    let pourcentageLongueur = 0
                    let pourcentageEpaisseur = 0
                    for (let pointSeuil of pointsSeuilsLongueur) {
                        if (art.length > parseInt(pointSeuil.point)) {
                            pourcentageLongueur = pointSeuil.pourcentage
                        }
                    }
                    for (let pointSeuil of pointsSeuilsEpaisseur) {
                        if (art.thickness > parseInt(pointSeuil.point)) {
                            pourcentageEpaisseur = pointSeuil.pourcentage
                        }
                    }
                    const prixHTDependingOfDimensions = article.tarif_prix * (art.length / 100)
                    const majLength = (article.tarif_prix) * (art.length / 100) * (pourcentageLongueur / 100)
                    const majThickness = (article.tarif_prix) * (art.length / 100) * (pourcentageEpaisseur / 100)
                    let majSpecificConditions = 0
                    for (let cs of article.conditionsSpecifiques) {
                        majSpecificConditions += (prixHTDependingOfDimensions + majLength + majThickness) * (cs.pourcentage / 100)
                    }
                    prixHTCopy[i] = prixHTDependingOfDimensions + majLength + majThickness + majSpecificConditions
                    i++
                }

            }
            setPrixHT(prixHTCopy)
        }

    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <DialogTitle>Création d'une série</DialogTitle>
                <DialogActions>
                    <Button style={{backgroundColor: "#1d6bfc"}} variant='contained'
                            onClick={() => handleAddFields()}>
                        <Icon>add</Icon>
                        Ajouter une ligne
                    </Button>
                </DialogActions>
            </div>
            <DialogContent>
                {
                    articlesToCreate && articlesToCreate.length > 0 && prixHT && prixHT.length > 0 && articlesToCreate.map((art, i) => {
                        return (
                            <div className={"products-create-serie-modal-text-fields-wrapper"}>
                                <TextField
                                    className={"products-create-serie-modal-text-fields-wrapper-text-field"}
                                    size={"small"}
                                    inputProps={{min: 0}}
                                    type={'number'}
                                    value={articlesToCreate[i].length}
                                    onChange={(e) => handleSetLength(e.target.value, i)}
                                    variant="outlined"
                                    label={"Longueur de l'article en mm"}
                                />
                                <TextField
                                    className={"products-create-serie-modal-text-fields-wrapper-text-field"}
                                    size={"small"}
                                    inputProps={{min: 0}}
                                    type={'number'}
                                    value={articlesToCreate[i].thickness}
                                    onChange={(e) => handleSetThickness(e.target.value, i)}
                                    variant="outlined"
                                    label={"Epaisseur de l'article en mm"}
                                />
                                <TextField
                                    className={"products-create-serie-modal-text-fields-wrapper-text-field"}
                                    size={"small"}
                                    inputProps={{min: 0}}
                                    type={'number'}
                                    value={articlesToCreate[i].quantity}
                                    onChange={(e) => handleSetQuantite(e.target.value, i)}
                                    variant="outlined"
                                    label={"Quantité"}
                                />
                                <Alert severity={"info"} style={{height: "40px", display: "flex", alignItems: "center"}}>P.U.
                                    H.T. : <strong>{parseFloat(prixHT[i]).toFixed(2)} €</strong></Alert>
                                <IconButton>
                                    <Icon onClick={() => handleDeleteFields(i)} style={{color: "#1d6bfc"}}>{"cancel"}</Icon>
                                </IconButton>
                            </div>
                        )
                    })
                }
            </DialogContent>
            <DialogActions>
                <Button style={{backgroundColor: "#1d6bfc"}} variant={"contained"} onClick={() => handleCreateSerie()}>Créer
                    la série</Button>
            </DialogActions>
        </Dialog>
    )
}

export default OperationDetailsCreateSerieModal
