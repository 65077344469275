import React from "react"
import {Icon, Paper} from "@mui/material";
import "../style/KPICard.scss"

const KPICard = ({title, count, subtitle, icon}) => {
    return (
        <Paper elevation={8} className={'home-kpi-card-wrapper'}>
                <div className={'home-kpi-card-wrapper-label'} >
                    <Icon>{icon}</Icon>
                    <span>{title}</span>
                </div>
                <div className={'home-kpi-card-wrapper-count'}>
                    {count}
                </div>
                <div className={'home-kpi-card-wrapper-subtitle'}>{subtitle}</div>
        </Paper>
    )
}


export default KPICard