import dayjs from "dayjs"

export const baseURL = _ => {
    return process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "https://prod.erp.api.renova.hubup.cloud/";
}

export const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const numberWithSpaces = (number) => {
    return (+number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const filterData = (dataToFilter, inputValue) => {
    return dataToFilter.filter((item) => {
        let result = false
        Object.keys(item).forEach(function (key) {
            if(typeof item[key] === "undefined"){
                result = false
            } else if (Array.isArray(item[key])) {
                for(let value of item[key]){
                    if (value.toString().toLowerCase().includes(inputValue.toLowerCase())) {
                        result = true
                    }
                }
            } else if (typeof item[key] !== "object") {
                if (item[key].toString().toLowerCase().includes(inputValue.toLowerCase())) {
                    result = true
                }
            }
        });
        return result
    })
}

export const removeDuplicateItems = (array, keyToCompare) => {
    return array.filter((item, index, currentArray) => currentArray.findIndex(currentItem => (currentItem[keyToCompare] === item[keyToCompare])) === index)
}

export const groupArticles = (orderData) => {
    orderData.operation = orderData.operation[0]
    for(let article of orderData.articleCommandes){
        article.dimensions = []
        article.conditionsSpecifiques = []
        for(let art of orderData.articles){
            if(article.article_id === art.id){
                if(orderData.dimensions !== undefined) {
                    for(let dim of orderData.dimensions){
                        if(art.loiDeveloppe_id === dim.loiDeveloppe_id){
                            article.dimensions.push(dim)
                        }
                    }
                }
            }
        }
        article.type = "standard"
        for(let cs of orderData.conditionsSpecifiques){
            if(cs.articlecommande_id === article.id){
                article.conditionsSpecifiques.push(cs)
            }
        }
    }
    for(let article of orderData.articleUniqueCommandes){
        article.type = "unique"
    }
    for(let ecriture of orderData.ecritures){
        if(ecriture.pourcentage){
            ecriture.type = "pourcentage"
        }
        if(!ecriture.prixHT && !ecriture.pourcentage){
            ecriture.type = "note"
        } if(ecriture.prixHT) {
            ecriture.type = "ecriture"
        }

    }
    orderData.articlesToDisplay = [...orderData.articleCommandes, ...orderData.articleUniqueCommandes, ...orderData.ecritures, ...orderData.notes].sort((a, b) => a.ordre - b.ordre)
    return orderData
}

export const matchOperationsWithFinish = (res) => {
    for (let operation of res.operations) {
        operation.matieres = []
        for (let articleUnique of res.articleUniqueCommandeLibelles) {
            if ((articleUnique.operation_id === operation.id) && (operation.matieres.indexOf(articleUnique.libelle) === -1)) {
                operation.matieres.push(articleUnique.libelle)
            }
        }
        for (let article of res.articlecommandeLibelles) {
            if ((article.operation_id === operation.id) && (operation.matieres.indexOf(article.libelle) === -1)) {
                operation.matieres.push(article.libelle)
            }
        }
    }
    return res
}

export const matchEcrituresAndAvoirs = (avenants, ecritures) => {
    const sortedAvenants = avenants.sort((a, b) => dayjs(b.date) - dayjs(a.date))
    for(let avenant of sortedAvenants){
        avenant.ecritures = []
        for(let ecriture of ecritures){
            if(ecriture.avenant_id === avenant.id){
                avenant.ecritures.push(ecriture)
            }
        }
    }
    return avenants
}
