import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent, TextField, Box, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createEcriture} from "../api/OperationsAPI";
import toast from "react-hot-toast";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import dayjs from "dayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {registerPayment} from "../api/OperationsAPI";

const OperationBillsRegisterPaymentModal = ({factureId, isOpen, handleClose, triggerRefetch}) => {

    const [date, setDate] = useState(null)
    const [paymentMode, setPaymentMode] = useState("cb")

    const handleSetDate = (value) => {
        setDate(value)
    }

    const handleSetPaymentMode = (e) => {
        setPaymentMode(e.target.value)
    }

    const handleRegisterPayment = async () => {
        let payload = {
            datePaiement: dayjs(date).format("YYYY-MM-DDT00:00:00+00:00"),
            modeReglement: paymentMode
        }
        try {
            await registerPayment(payload, factureId)
            triggerRefetch()
            toast.success("Le paiement a été enregistré avec succès")
            handleClose(null)
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant l'enregistrement du paiement")
        }
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Enregistrement d'un paiement</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date d'enregistrement du paiement"
                            inputFormat="dd/MM/yyyy"
                            value={date}
                            onChange={(newValue) => handleSetDate(dayjs(newValue).format("YYYY-MM-DD"))}
                            renderInput={(params) => <TextField {...params}  style={{marginBottom:"12px"}}/>}
                        />
                    </LocalizationProvider>
                    <FormControl>
                        <InputLabel>Mode de paiement</InputLabel>
                        <Select
                            value={paymentMode}
                            style={{marginBottom: "12px"}}
                            label={"Mode de paiement"}
                            className={""}
                            onChange={handleSetPaymentMode}
                        >
                            <MenuItem value={"cb"}>Carte bancaire</MenuItem>
                            <MenuItem value={"virement"}>Virement</MenuItem>
                            <MenuItem value={"especes"}>Espèces</MenuItem>
                            <MenuItem value={"cheque"}>Chèques</MenuItem>
                            <MenuItem value={"avoir"}>Avoir</MenuItem>
                            <MenuItem value={"autre"}>Autre</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleRegisterPayment()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default OperationBillsRegisterPaymentModal