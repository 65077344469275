import React from "react"
import {Paper} from "@mui/material"
import "../style/TurnoverChart.scss"
import Loader from "../../../shared/loader/Loader";
import undraw_empty from '../../../shared/undraw/undraw_empty.svg'
import {Doughnut} from 'react-chartjs-2';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat"
import 'dayjs/locale/fr'

dayjs.extend(customParseFormat)

const TurnoverChart = ({title, stats, isLoading}) => {

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const renderContent = () => {
        if (!isLoading && !stats) {
            return (
                <div className={'home-turnover-chart-wrapper-content-no-turnover-loader-wrapper'}>
                    <img src={undraw_empty} alt={"no data available"}/>
                    <span>Pas de donnée à afficher</span>
                </div>
            )
        }
        if (stats && !isLoading) {
            const data = {
                labels: Object.keys(stats).map((key) => key),
                datasets: [
                    {
                        data: Object.values(stats).map((value) => value),
                        backgroundColor: Object.keys(stats).map(() => getRandomColor()),
                    },
                ],
            };
            const options = {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                const keys = Object.keys(stats)
                                return keys[context.dataIndex] + ' : ' + context.formattedValue + " €"
                            }
                        }
                    },
                    legend: {
                        display: true,
                        position: "left"
                    },
                }
            }
            return (
                <Doughnut data={data} options={options} className={'home-turnover-wrapper-content'}/>
            )
        }
        if (isLoading) {
            return (
                <div className={'home-turnover-chart-wrapper-content-no-turnover-loader-wrapper'}>
                    <Loader/>
                </div>
            )
        }
    }

    return (
        <Paper elevation={8} className={'home-turnover-chart-wrapper'}>
            <div className={'home-turnover-chart-wrapper-header'}>
                <div>{title}</div>
            </div>
            <div className={'home-turnover-chart-wrapper-content'}>
                {renderContent()}
            </div>
        </Paper>
    )

}

export default TurnoverChart