import React, {useState} from "react"
import {
    Icon,
    IconButton,
    Button,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import dayjs from 'dayjs'
import Loader from "../../../shared/loader/Loader";
import {withRouter, Link} from "react-router-dom";

const OperationsProductionList = ({
                                      data,
                                      articleLabel
                                  }) => {

    const [pageNumber, setPageNumber] = useState(1)

    const startRange = (pageNumber - 1) * 10
    const endRange = pageNumber * 10

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const categories = [
        {
            title: "Finition",
            key: "finish"
        },
        {
            title: "Plage de développé",
            key: "developedRange"
        },
        {
            title: "Intervalle de développé associé",
            key: "interval"
        },
    ]

    return (
        <Paper className={"article-details-ranges-list-wrapper"}>
            {data ?
                (
                    <div className={"article-details-ranges-list-wrapper-content"}>
                        <TableContainer component={Paper} className={"article-details-ranges-list-wrapper-content-table"}>
                            <Table stickyHeader={true} style={{border: "1px solid #EFEFEF"}}>
                                <TableHead>
                                    <TableRow>
                                        {categories.map((category) => {
                                            return (
                                                <TableCell style={{backgroundColor: "#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.slice(startRange, endRange).map((finish) => (
                                        <TableRow
                                            key={finish.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>
                                                <strong>
                                                    {finish.matiere_libelle}
                                                </strong>

                                            </TableCell>
                                            <TableCell>
                                                <div className={'article-details-ranges-list-wrapper-developed-ranges-labels'}>


                                                    {finish.bornes.map((borne) => {
                                                        return (
                                                            <div>
                                                                <strong>{borne.pdv_libelle}</strong>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </TableCell>

                                            <TableCell>
                                                <div className={'article-details-ranges-list-wrapper-developed-ranges-intervals'}>
                                                    {
                                                        finish.bornes.map((borne) => {
                                                            return (
                                                                <div>
                                                                    <strong>{borne.borneBasse}</strong> ≤ {articleLabel} ≤ <strong>{borne.borneHaute}</strong>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={"article-details-ranges-list-wrapper-content-pagination"}>
                            <Pagination count={Math.ceil(data.length / 10)} onChange={handlePageChange} page={pageNumber}/>
                        </div>
                    </div>
                )
                :
                <div className={"article-details-ranges-list-wrapper-content-loader-wrapper"}>
                    <Loader/>
                </div>
            }
        </Paper>
    )
}

export default withRouter(OperationsProductionList)
