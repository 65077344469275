import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Checkbox, DialogContent, FormControlLabel, Icon, IconButton, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createLengthRule, createThicknessRule, editLengthRule, editThicknessRule, editPointSeuil} from "./api/PricingRulesAPI";
import {Line} from "react-chartjs-2";
import toast from "react-hot-toast";

const PricingRulesNewEditThicknessLengthRuleModal = ({ruleType, rule, mode, handleClose, triggerRefetch, setTriggerRefetch}) => {

    const [libelle, setLibelle] = useState(rule.libelle)
    const [labels, setLabels] = useState(rule.pointsSeuils.sort((a, b) => a.point - b.point).map((ps) => ps.point))
    const [dataSet, setDataSet] = useState(rule.pointsSeuils.sort((a, b) => a.point - b.point).map((ps) => ps.pourcentage))
    const [pointsSeuils, setPointsSeuils] = useState(rule.pointsSeuils)

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Tarification (en %)',
                data: dataSet,
                fill: false,
                backgroundColor: '#1E2129',
                borderColor: '#1E2129',
            },
        ],
    };

    useEffect(() => {
        setLabels(pointsSeuils.slice().sort((a, b) => a.point - b.point).map((ps) => ps.point))
        setDataSet(pointsSeuils.slice().sort((a, b) => a.point - b.point).map((ps) => ps.pourcentage))
    }, [pointsSeuils])

    const handleSetLibelle = (value) => {
        setLibelle(value)
    }

    const handlePointSeuils = (key, i, value) => {
        const dim = pointsSeuils.slice().sort()
        dim[i][key] = value
        setPointsSeuils(dim)
    }

    const handleFilterPointsSeuils = (indexToRemove) => {
        const dim = pointsSeuils.slice()
        delete dim[indexToRemove]
        setPointsSeuils(dim)
    }

    const handleAddPointSeuil = () => {
        const dim = pointsSeuils.slice()
        dim.push({seuil: "", pourcentage: ""})
        setPointsSeuils(dim)
    }

    const handleUpdateAddRule = async () => {
        const payload = {
            libelle: libelle,
        }
        if (mode === "create") {
            try {
                payload.pointSeuils = pointsSeuils
                if (ruleType === "length") {
                    await createLengthRule(payload)
                    toast.success("La loi de longueur a été créée avec succès")
                } else if (ruleType === "thickness") {
                    await createThicknessRule(payload)
                    toast.success("La loi d'épaisseur a été créée avec succès")
                }

                handleClose()
                setTriggerRefetch(!triggerRefetch)
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la création de la loi")
            }
        } else if (mode === "edit") {
            try {
                if (ruleType === "length") {
                    await editLengthRule(payload, rule.id)
                } else if (ruleType === "thickness") {
                    await editThicknessRule(payload, rule.id)
                }
                for (let seuil of pointsSeuils) {
                    const seuilPayload = {
                        point: seuil.point,
                        pourcentage: seuil.pourcentage
                    }
                    await editPointSeuil(seuilPayload, seuil.id)
                }
                toast.success("La loi a été modifiée avec succès")
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la modification de la loi")
            }
        }
    }

    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {}
    }

    const returnModalTitle = () => {
        let title = ""
        if (mode === "create") {
            title += 'Création'
        }
        if (mode === "edit") {
            title += 'Modification'
        }
        if (ruleType === "thickness") {
            title += " d'une règle d'épaisseur"
        }
        if (ruleType === "length") {
            title += " d'une règle de longueur"
        }
        return title
    }

    return (
        <Dialog open={mode} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>{returnModalTitle()}</DialogTitle>
            <DialogContent>
                <div className={"pricing-rules-developed-rules-modal-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={libelle}
                        onChange={(e) => handleSetLibelle(e.target.value)}
                        variant="outlined"
                        label={"Libellé"}
                    />
                    <div className={"pricing-rules-developed-rules-modal-fields-wrapper-dimensions"}>
                        <div className={"pricing-rules-developed-rules-modal-fields-wrapper-dimensions-header"}>
                            <span>Dimensions :</span>
                            <Button variant={"contained"} onClick={() => handleAddPointSeuil()}>Ajouter une dimension</Button>
                        </div>


                        {pointsSeuils.map((dim, i) => {
                            return (
                                <div className={"pricing-rules-developed-rules-modal-fields-wrapper-dimension"}>
                                    <TextField
                                        className={"pricing-rules-developed-rules-modal-fields-wrapper-dimension-point"}
                                        variant="outlined"
                                        onChange={(e) => handlePointSeuils('point', i, e.target.value)}
                                        label={"Point de contrôle"}
                                        value={pointsSeuils[i].point}
                                    />
                                    <TextField
                                        className={"pricing-rules-developed-rules-modal-fields-wrapper-dimension-pourcentage"}
                                        variant="outlined"
                                        onChange={(e) => handlePointSeuils('pourcentage', i, e.target.value)}
                                        label={"Pourcentage de pondération"}
                                        value={pointsSeuils[i].pourcentage}
                                    />
                                    <IconButton
                                        onClick={() => handleFilterPointsSeuils(i)}
                                    >
                                        <Icon color={"primary"}>{"delete"}</Icon>
                                    </IconButton>
                                </div>

                            )
                        })}
                    </div>
                </div>
                <div className={"pricing-rules-developed-rules-modal-graph"}>
                    {labels && dataSet && <Line data={data} options={options}/>}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(null)}>Annuler</Button>
                <Button onClick={() => handleUpdateAddRule()}>
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PricingRulesNewEditThicknessLengthRuleModal