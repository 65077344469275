import React, {useState} from "react"
import {
    Icon,
    IconButton,
    Button,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import dayjs from 'dayjs'
import Loader from "../../../shared/loader/Loader";
import {numberWithCommas} from "../../../shared/utils/Utils";
import {getOperationDetail} from "../api/OperationsAPI";
import {downloadOperationPDF} from "../api/OperationsAPI";
import OperationDetailsModal from "../OperationDetailsModal"
import {changeOperationStatus} from "../api/OperationsAPI";
import {withRouter, Link} from "react-router-dom";

const OperationsProductionList = ({
                                      sortColumn,
                                      handleSortCatChange,
                                      categories,
                                      handleSortOptionChange,
                                      sortOption,
                                      handleFilterInputValueChange,
                                      filterInputValue,
                                      data,
                                      popperAnchorEl,
                                      toggleModal,
                                      orderIdHovered,
                                      triggerRefresh,
                                      history
                                  }) => {

    const [pageNumber, setPageNumber] = useState(1)

    const groupArticles = (orderData) => {
        orderData.operation = orderData.operation[0]
        orderData.articlesToDisplay = [...orderData.articleCommandes, ...orderData.articleUniqueCommandes, ...orderData.ecritures, ...orderData.notes].sort((a, b) => a.ordre - b.ordre)
        return orderData
    }

    const handleClickOnProforma = async (operation) => {
        const orderData = await getOperationDetail(operation.id)
        if (orderData && orderData.operation && orderData.operation[0]) {
            const groupedOperationData = groupArticles(orderData)
            downloadOperationPDF(groupedOperationData, "proforma")
        }
    }

    const startRange = (pageNumber - 1) * 10
    const endRange = pageNumber * 10

    const handleClickOnDeliver = async (order) => {
        await changeOperationStatus({statut: "livraison"}, order.id)
        const orderData = await getOperationDetail(order.id)
        if (orderData && orderData.operation && orderData.operation[0]) {
            const groupedOperationData = groupArticles(orderData)
            downloadOperationPDF(groupedOperationData, "deliveryReceipt")
        }
        triggerRefresh()
    }

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    return (
        <Paper elevation={8} className={"operations-production-wrapper"}>
            {data ?
                (
                    <>
                        <div className={"operations-production-wrapper-header"}>
                            <div className={"operations-production-wrapper-header-counts"}>
                                <span><strong style={{
                                    color: "#1079FC",
                                    borderRadius: 4,
                                    backgroundColor: "#D9EDF7",
                                    display: "inline-block",
                                    paddingLeft: 5,
                                    paddingRight: 5
                                }}>{data.length}</strong> {data.length > 1 ? "commandes en production" : "commande en production"}</span>
                                <span>Montant : <strong style={{
                                    color: "#1079FC",
                                    borderRadius: 4,
                                    backgroundColor: "#D9EDF7",
                                    display: "inline-block",
                                    paddingLeft: 5,
                                    paddingRight: 5
                                }}>{data && data.length > 0 ? numberWithCommas(data.map((order) => parseInt(order.prixHTPlusDepollution)).reduce((acc, prev) => acc + prev)) : 0} €</strong></span>
                            </div>
                            <div className={"operations-production-wrapper-header-filter-sort"}>
                                <Select
                                    className={"operations-production-wrapper-header-sort-input"}
                                    size={"small"}
                                    value={sortColumn}
                                    onChange={handleSortCatChange}
                                >
                                    {categories.filter((category) => category.key !== 'actions' && category.key !== "finish").map((category) => {
                                        return (
                                            <MenuItem key={category.key} value={category.key}>{category.title}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <TextField className={"operations-production-wrapper-header-filter-input"} size="small"
                                           label={"Rechercher une operation"}
                                           variant="outlined" onChange={handleFilterInputValueChange}
                                           value={filterInputValue}/>
                                <IconButton onClick={() => handleSortOptionChange()}>
                                    <Icon>{sortOption === "increasing" ? "arrow_downward" : "arrow_upward"}</Icon>
                                </IconButton>
                            </div>
                        </div>
                        <div className={"operations-production-wrapper-content"}>
                            <TableContainer component={Paper} className={"operations-production-wrapper-content-table"}>
                                <Table stickyHeader={true} style={{border: "1px solid #EFEFEF"}}>
                                    <TableHead>
                                        <TableRow>
                                            {categories.map((category) => {
                                                return (
                                                    <TableCell style={{backgroundColor: "#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice(startRange, endRange).map((order) => (
                                            <TableRow
                                                key={order.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>
                                                    <div
                                                        className={'operations-production-wrapper-content-table-operation-ref-wrapper'}>
                                                        <div onClick={() => history.push(`/operations/operation/detail/${order.id}`)}
                                                             onMouseEnter={(e) => toggleModal(e, order.id)}
                                                             onMouseLeave={() => toggleModal()}>
                                                            {order.id}
                                                        </div>

                                                    </div>

                                                </TableCell>
                                                <TableCell>
                                                    <div
                                                        className={"operations-production-wrapper-content-table-actions-buttons"}>
                                                        <Button variant={'outlined'} size={"small"} style={{color: "#222222", borderColor: "#555555"}}
                                                                onClick={() => handleClickOnDeliver(order)}>Livrer +
                                                            BL</Button>
                                                        <Button onClick={() => handleClickOnProforma(order)} style={{color: "#222222", borderColor: "#555555"}}
                                                                variant={'outlined'} size={"small"}>Proforma</Button>
                                                    </div>

                                                </TableCell>
                                                <TableCell>{order.numeroCommandeClient}</TableCell>
                                                <TableCell>
                                                    <Link className={"link-without-decoration"} to={`/clients/list/${order.client_id}`}>
                                                        {order.nom}
                                                    </Link>
                                                </TableCell>
                                                <TableCell style={{width: "300px"}}>
                                                    <div>
                                                        {order.matieres.map((fin) => {
                                                            return (
                                                                <div style={{display: "flex", alignItems: "center"}}>
                                                                    <Icon style={{color: "#797979", fontSize: 14, marginRight: 4}}>
                                                                        format_paint
                                                                    </Icon>
                                                                    <span>{fin}</span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>


                                                </TableCell>
                                                <TableCell>{order.dateProduction ? dayjs(order.dateProduction).format("DD/MM/YYYY") : ""}</TableCell>
                                                <TableCell>{order.dateRendu ? dayjs(order.dateRendu).format("DD/MM/YYYY") : ""}</TableCell>
                                                <TableCell>{parseFloat(order.prixHTPlusDepollution).toFixed(2)} €</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={"operations-production-wrapper-content-pagination"}>
                                <Pagination count={Math.ceil(data.length / 10)} onChange={handlePageChange} page={pageNumber}/>
                            </div>
                        </div>
                    </>
                )
                :
                <div className={"operations-production-wrapper-content-loader-wrapper"}>
                    <Loader/>
                </div>
            }
            {popperAnchorEl &&
            <OperationDetailsModal popperAnchorEl={popperAnchorEl} toggleModal={toggleModal} orderId={orderIdHovered}/>}
        </Paper>
    )
}

export default withRouter(OperationsProductionList)
