import {APIget, APIpost, APIdelete, APIpatch} from "../../../services/API";
import {baseURL} from "../../../shared/utils/Utils";


export const getAllProviders = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/fournisseur/get/all`)
            .then((providers) => {
                resolve(providers)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getAllOrders = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/bon/de/commande/fetch/all`)
            .then((providers) => {
                resolve(providers)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getProviderDetails = (providerId) => {
    return new Promise((resolve, reject) => {
        APIget(`/api/fournisseur/${providerId}/fetch`)
            .then((provider) => {
                resolve(provider)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createProviderContact = (payload, providerId) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/fournisseur/${providerId}/add/contact`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteProvider = (providerId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/fournisseur/${providerId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createBonDeCommande = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/bon/de/commande/fournisseur/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editBCStatus = (bcId, payload) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/bon/de/commande/${bcId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editBC = (bcId, payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/entre/bon/de/commande/patch/for/bon/de/commande/${bcId}`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editProvider = (providerId, payload) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/fournisseur/${providerId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createProvider = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/fournisseur/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteOrder = (bcId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/bon/de/commande/${bcId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getSpreadsheetOrder = (orderId, jwt) => {
    return new Promise((resolve, reject) => {
        let myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer " + jwt);
        myHeaders.append("content-type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(baseURL() + `api/export/bon/de/commande/${orderId}/spreadsheet`, requestOptions)
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
