import React, {createContext, useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent} from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export const ConfModalContext = createContext();

export default function ConfModalProvider({children}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const renderModal = (actionFunction, arg1) => {
        return (
            <Dialog onClose={() => setIsModalOpen(false)} open={isModalOpen}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                   Etes-vous sur de vouloir réaliser cette opération ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseModal()}>Annuler</Button>
                    <Button onClick={() => actionFunction(arg1)}>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <ConfModalContext.Provider value={{isModalOpen, renderModal, handleOpenModal}}>
            {children}
        </ConfModalContext.Provider>
    );
}