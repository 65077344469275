import React, {useState, useEffect} from "react"
import {Paper, Icon, IconButton} from "@mui/material"
import "../style/Notes.scss"
import {getNotes} from "../api/HomeAPI";
import Loader from "../../../shared/loader/Loader";
import undraw_empty from '../../../shared/undraw/undraw_empty.svg'
import dayjs from "dayjs";
import {deleteNote} from "../../clients/api/ClientAPI";

const Notes = () => {

    const [notes, setNotes] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        handleGetNotes()
    }, [])

    const handleGetNotes = async () => {
        const notes = await getNotes()
        setNotes(notes)
        setIsLoading(false)
    }

    const handleDeleteNote = async (noteId) => {
        await deleteNote(noteId)
        handleGetNotes()
    }


    const renderContent = () => {
        if((!isLoading && !notes) || (!isLoading && notes && !notes.length)){
            return(
                <div className={'home-notes-wrapper-content-no-note-loader-wrapper'}>
                   <img src={undraw_empty} alt={"no data available"}/>
                    <span>Aucune note à afficher</span>
                </div>
            )
        }
        if(notes && !isLoading){
            return(
                notes.map((note) => {
                    return (
                        <div key={note.id} className={'home-notes-wrapper-content-note'}>
                            <div className={'home-notes-wrapper-content-note-header'}>

                                    <span>{dayjs(note.date).format("DD/MM/YYYY HH:mm")} - {note.auteur}</span>
                                    <span>&nbsp;</span>
                                    <span>- {note.client_nom}</span>
                                <div style={{flex:1}}/>
                                <IconButton
                                    size={'small'}
                                    onClick={() => handleDeleteNote(note.id)}
                                    style={{}}>
                                    <Icon color={"primary"}>{"delete"}</Icon>
                                </IconButton>
                            </div>
                            <div className={'home-notes-wrapper-content-note-content'}>
                                <Icon>comment</Icon>
                                {note.contenu}
                            </div>
                        </div>
                    )
                })
            )
        }
        if(isLoading){
           return (
               <div className={'home-notes-wrapper-content-no-note-loader-wrapper'}>
                   <Loader/>
               </div>
           )
        }
    }

    return (
        <Paper elevation={8} className={'home-notes-wrapper'}>
            <div className={'home-notes-wrapper-header'}>
                <Icon>create</Icon>
                <div>Notes</div>
            </div>
            <div className={'home-notes-wrapper-content noscrollbar'}>
                {renderContent()}
            </div>
        </Paper>
    )

}

export default Notes