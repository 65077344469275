import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Checkbox, DialogContent, FormControlLabel, TextField, Paper, IconButton, Icon} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createAvoir} from "../operations/api/OperationsAPI";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import dayjs from "dayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import toast from "react-hot-toast"
import {Add} from "@mui/icons-material";

const ClientsAvoirModal = ({isOpen, clientId, operationsIdsToInvoice, handleClose, handleSetTriggerRefetch}) => {


    const [creationDate, setCreationDate] = useState(null)
    const [avoirNumber, setAvoirNumber] = useState("")
    const [depollution, setDepollution] = useState(false)
    const [tva, setTva] = useState(false)
    const [ecritures, setEcritures] = useState([
        {
            operationId: null,
            quantite: 1,
            ordre: 0,
            avenantId: null,
            libelle: "",
            pourcentage: 0,
            prixHT: ""
        }
    ])


    useEffect(() => {

    }, [])

    const handleSetCreationDate = (value) => {
        setCreationDate(value)
    }

    const handleSetAvoirNumber = (value) => {
        setAvoirNumber(value)
    }

    const handleSetDepollution = () => {
        if (depollution) {
            setDepollution(false)
        } else if (!depollution) {
            setDepollution(true)
        }
    }

    const handleSetTva = () => {
        if (tva) {
            setTva(false)
        } else if (!tva) {
            setTva(true)
        }
    }

    const handleCreateAvoir = async () => {
        if(ecritures.length === 0){
            toast.error("Merci de saisir au moins une écriture avant de valider")
            return
        }
        const prixHTTotal = ecritures.map((ecriture) => ecriture.prixHT).reduce((acc, prev) => acc+prev)
        const prixTTC = prixHTTotal * 1.2
        const payload = {
            numero:avoirNumber,
            clientId: clientId,
            tva:tva,
            date:creationDate,
            depollution:depollution,
            prixHT: prixHTTotal,
            prixTTC: prixTTC,
            ecritures: ecritures
        }
        try {
            await createAvoir(payload)
            handleClose()
            handleSetTriggerRefetch()
            toast.success("L'avoir a été créé avec succès")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la création de l'avoir")
        }
    }

    const handleEditEcritures = (value, index, type) => {
        const ecrituresCopy = ecritures.slice()
        if (type === "montant") {
            ecrituresCopy[index].prixHT = -value
        } else if (type === "libelle") {
            ecrituresCopy[index].libelle = value
        }
        setEcritures(ecrituresCopy)
    }

    const handleAddEcriture = () => {
        const ecrituresCopy = ecritures.slice()
        ecrituresCopy.push( {
                operationId: null,
                quantite: 1,
                ordre: 0,
                avenantId: null,
                libelle: "",
                pourcentage: 0,
                prixHT: ""
            }
        )
        setEcritures(ecrituresCopy)
    }

    const handleFilterEcritures = (index) => {
        const ecrituresCopy = ecritures.slice()
        ecrituresCopy.splice(index, 1)
        setEcritures(ecrituresCopy)
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Créer un avoir</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={avoirNumber}
                        onChange={(e) => handleSetAvoirNumber(e.target.value)}
                        variant="outlined"
                        label={"Numéro de l'avoir"}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date d'enregistrement"
                            inputFormat="dd/MM/yyyy"
                            value={creationDate}
                            onChange={(newValue) => handleSetCreationDate(dayjs(newValue).format("YYYY-MM-DD"))}
                            renderInput={(params) => <TextField {...params} style={{marginBottom: "12px"}}/>}
                        />
                    </LocalizationProvider>
                    <div style={{border: "solid 1px #CCCCCC", borderRadius: 4, height: 56, display: "flex", alignItems: "center", marginBottom: 12}}>
                        <FormControlLabel control={<Checkbox onChange={() => handleSetDepollution()} checked={depollution} style={{marginLeft: 12}}/>}
                                          label="Taxe de dépollution ?"/>
                    </div>
                    <div style={{border: "solid 1px #CCCCCC", borderRadius: 4, height: 56, display: "flex", alignItems: "center", marginBottom: 12}}>
                        <FormControlLabel control={<Checkbox onChange={() => handleSetTva()} checked={tva} style={{marginLeft: 12}}/>} label="TVA ?"/>
                    </div>
                    <div style={{border:"solid 1px #CCCCCC", borderRadius:4, padding:10}}>
                        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", marginBottom:16}}>
                            <div style={{fontSize:16}}>Ecritures</div>
                            <Button variant={"contained"} size="small" style={{marginLeft: 10}} onClick={() => handleAddEcriture()} startIcon={<Add/>}>
                                Ajouter une écriture
                            </Button>
                        </div>
                    {ecritures.map((ecriture, i) => {
                        return (
                            <div className={"ecriture-wrapper"}>
                                <TextField
                                    style={{width:"70%"}}
                                    value={ecriture.libelle}
                                    onChange={(e) => handleEditEcritures(e.target.value, i, "libelle")}
                                    variant="outlined"
                                    label={"Libellé"}
                                />
                                <TextField
                                    style={{width:"20%"}}
                                    value={-ecriture.prixHT}
                                    onChange={(e) => handleEditEcritures(e.target.value, i, "montant")}
                                    variant="outlined"
                                    label={"Montant HT"}
                                />
                                <IconButton>
                                    <Icon color={"primary"} onClick={() => handleFilterEcritures(i)}>{"delete"}</Icon>
                                </IconButton>
                            </div>
                        )
                    })}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Annuler</Button>
                <Button
                    onClick={() => handleCreateAvoir()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ClientsAvoirModal