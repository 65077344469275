import {APIget, APIput, APIdelete, APIpatch, APIpost} from '../../../services/API'

export const deleteContact = (contactId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/client/delete/contact/${contactId}`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createContact = (payload, clientId) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/client/${clientId}/add/contact`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createNote = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/note/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createClient = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/client/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editClient = (payload, clientId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/client/patch/${clientId}`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export const editContact = (payload, contactId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/client/patch/contact/${contactId}`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getClientOperations = (clientId) => {
    return new Promise((resolve, reject) => {
        APIget(`/api/operation/get/all/by/client/${clientId}`)
            .then((operations) => {
                resolve(operations)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getClientHistory = (clientId) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/historique/fetch/by/client/${clientId}`)
            .then((history) => {
                resolve(history)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getClientNotes = (clientId) => {
    return new Promise((resolve, reject) => {
        APIget(`/api/client/${clientId}/fetch/notes`)
            .then((notes) => {
                resolve(notes)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteNote = (noteId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/note/${noteId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}