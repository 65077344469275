import React, {useState, useEffect} from "react"
import {withRouter} from "react-router-dom";
import "./UniqueArticleSearch.scss"
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Icon, TextField} from "@mui/material"
import {searchUniqueIsolatedArticle} from "../api/ProductsAPI";
import UniqueArticleSearchResultsList from "./UniqueArticleSearchResultsList"
import {filterData} from "../../../shared/utils/Utils";

const UniqueArticleSearch = (props) => {
    const [dvpmin, setDvpmin] = useState("")
    const [dvpmax, setDvpmax] = useState("")
    const [epmin, setEpmin] = useState("")
    const [epmax, setEpmax] = useState("")
    const [lmin, setLmin] = useState("")
    const [lmax, setLmax] = useState("")
    const [qtemin, setQtemin] = useState("")
    const [qtemax, setQtemax] = useState("")
    const [results, setResults] = useState("")
    const [initialResults, setInitialResults] = useState(null)
    // libelle = finish name (created like that to fit the back end key)
    const [libelle, setLibelle] = useState("")
    const [filterInputValue, setFilterInputValue] = useState("")

    useEffect(() => {
        if (dvpmin.length !== 0 || dvpmax.length !== 0 || epmin.length !== 0 || epmax.length !== 0 || lmin.length !== 0 || lmax.length !== 0 || qtemin.length !== 0 || qtemax.length !== 0)
            handleSearchArticle()
    }, [dvpmin, dvpmax, epmin, epmax, lmin, lmax, qtemin, qtemax])

    const handleFilterInputValueChange = (e) => {
        setFilterInputValue(e.target.value)
    }

    const handleSearchArticle = async () => {
        const payload = {
            dvpmin: dvpmin,
            dvpmax: dvpmax,
            epmin: epmin,
            epmax: epmax,
            lmin: lmin,
            lmax: lmax,
            qtemin: qtemin,
            qtemax: qtemax,
            libelle: libelle
        }
        const res = await searchUniqueIsolatedArticle(payload)
        setInitialResults(res)
        setResults(res)
    }

    useEffect(() => {
        if (initialResults) {
            const filteredData = filterData(initialResults, filterInputValue)
            setResults(filteredData)
        }
    }, [filterInputValue])

    return (
        <div className={"unique-article-search-wrapper"}>
            <Paper className={'unique-article-search-wrapper-menu'}>
                <div className={'unique-article-search-wrapper-menu-title'}>
                    <Icon>search</Icon>
                    <div>Valeurs liées à la pièce recherchée</div>
                </div>
                <div className={'unique-article-search-wrapper-menu-text-fields'}>
                    <div className={'unique-article-search-wrapper-menu-text-fields-text-field'}>
                    <span>
                            Développé entre&nbsp;
                        </span>
                        <div style={{flex: 1}}/>
                        <TextField
                            size={"small"}
                            style={{width: "100px"}}
                            value={dvpmin}
                            onChange={(e) => setDvpmin(e.target.value ? e.target.value : 0)}
                            variant="outlined"
                            label={"Minimum"}
                        />
                        <span>
                             &nbsp;&nbsp;et&nbsp;&nbsp;
                        </span>

                        <TextField
                            size={"small"}
                            style={{width: "100px"}}
                            value={dvpmax}
                            onChange={(e) => setDvpmax(e.target.value ? e.target.value : 0)}
                            variant="outlined"
                            label={"Maximum"}
                        />
                    </div>
                    <div className={'unique-article-search-wrapper-menu-text-fields-text-field'}>
                    <span>
                            Epaisseur entre&nbsp;
                        </span>
                        <div style={{flex: 1}}/>
                        <TextField
                            size={"small"}
                            style={{width: "100px"}}
                            value={epmin}
                            onChange={(e) => setEpmin(e.target.value ? parseFloat(e.target.value) : 0)}
                            variant="outlined"
                            label={"Minimum"}
                        />
                        <span>
                 &nbsp;&nbsp;et&nbsp;&nbsp;
                        </span>
                        <TextField
                            size={"small"}
                            style={{width: "100px"}}
                            value={epmax}
                            onChange={(e) => setEpmax(e.target.value ? parseFloat(e.target.value) : 0)}
                            variant="outlined"
                            label={"Maximum"}
                        />
                    </div>
                    <div className={'unique-article-search-wrapper-menu-text-fields-text-field'}>
                    <span>
                            Longueur entre&nbsp;
                        </span>
                        <div style={{flex: 1}}/>
                        <TextField
                            size={"small"}
                            style={{width: "100px"}}
                            value={lmin}
                            onChange={(e) => setLmin(e.target.value ? parseFloat(e.target.value) : 0)}
                            variant="outlined"
                            label={"Minimum"}
                        />
                        <span>
                            &nbsp;&nbsp;et&nbsp;&nbsp;
                        </span>
                        <TextField
                            size={"small"}
                            style={{width: "100px"}}
                            value={lmax}
                            onChange={(e) => setLmax(e.target.value ? parseFloat(e.target.value) : 0)}
                            variant="outlined"
                            label={"Maximum"}
                        />
                    </div>
                    <div className={'unique-article-search-wrapper-menu-text-fields-text-field'}>
                    <span>
                            Quantité entre&nbsp;
                        </span>
                        <div style={{flex: 1}}/>
                        <TextField
                            size={"small"}
                            style={{width: "100px"}}
                            value={qtemin}
                            onChange={(e) => setQtemin(e.target.value ? parseInt(e.target.value) : 0)}
                            variant="outlined"
                            label={"Minimum"}
                        />
                        <span>
                            &nbsp;&nbsp;et&nbsp;&nbsp;
                        </span>
                        <TextField
                            size={"small"}
                            style={{width: "100px"}}
                            value={qtemax}
                            onChange={(e) => setQtemax(e.target.value ? parseInt(e.target.value) : 0)}
                            variant="outlined"
                            label={"Maximum"}
                        />
                    </div>
                </div>
            </Paper>
            <Paper className={'unique-article-search-wrapper-results'}>
                <div className={'unique-article-search-wrapper-results-title'}>
                    <Icon>find_in_page</Icon>
                    <div>Resultats</div>
                </div>
                <div className={'unique-article-search-wrapper-menu-body'}>
                    <UniqueArticleSearchResultsList results={results} handleFilterInputValueChange={handleFilterInputValueChange} filterInputValue={filterInputValue}/>
                </div>
            </Paper>
        </div>
    )
}

export default withRouter(UniqueArticleSearch)